import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useParams, withRouter,useLocation } from 'react-router-dom'
import { ListView } from '../components/secondary/ListView';
import { FORMULARY, LIST_VIEW_LIMIT, FORMULARY_IMPORT_METHOD, CANCEL_BTN,
    CANCEL_ID,
    SAVE_BTN,
    SUBMIT_ID, } from '../constants';
import {
    Button,
    Form,
    SelectBox,
    TextField,
    MultiSelect,
    RadioButton,
    CheckBox,
    } from "../components/primary";
    import { Modal } from "../components/secondary";
import {
    deleteFormulariesAsync,
    formularyOptionsAsync,
    getFormularyListAsync,
    optionTypeAsync,
    setFormularies,
    setNewPayload,
    setSearchKey,
   // setSortType,
} from '../app/formularySlice'
import CreateFormulary from '../components/core/Formulary/createFormulary';
import { useForm } from "react-hook-form";
import { setError, setLoading } from "../app/appSlice";
import * as XLSX from "xlsx";
import style from "../components/core/Formulary/createFormulary.module.scss";
import {
    getMateriallist,
    importProductToFormulary,
  } from "../app/customFormSlice";
  import {
    asec,
    dateOfBirthDate,
    desc,
    onlyFirstName,
    getUniqueListBy,
  } from "../utils";
  import DocumentIcon from "../static/images/products/document_icon.svg";
import CloseIcon from "../static/images/products/close_icon.svg";

export let Formulary = ({ dispatch, history }) => {

    const {
        register,
        errors,
        handleSubmit,
        reset,
        getValues,
        setValue,
      } = useForm();
      const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        setError: setErrorExcel,
        clearErrors: clearErrorsExcel,
      } = useForm({ mode: "onBlur" });

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const [createFormulary, setCreateFormulary] = useState(false)
    const { formularyId } = useParams();
   
    const formularylocation = useLocation();
    const [checkFormularyList, setCheckFormularyList] = useState('')
    const [offSet, setOffSet] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.offSet?formularylocation.state.dyList.offSet:0)
    const [activePage, setActivePage] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.activePage?formularylocation.state.dyList.activePage:1);
    const [sortType, setSortType] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.sortType?formularylocation.state.dyList.sortType:'') // For mixing sorting with pagination

    const searchKey = useSelector(state => state.formulary.searchKey)
    //const sortType = useSelector(state => state.formulary.sortType)
    const accessError = useSelector(state => state.app.accessError)
    // Below is for clearing create formulary data from redux state
    useEffect(() => {
        // We are sending '71' as default value
        const payload = {
            division: "71",
            productCategoryDesc: null,
            productCategoryCode: null,
            productFamilyDesc: null,
            productFamilyCode: null
        }
        const queryParam = { limit: 80000 }
        // We are setting above payload as initial default data.
        dispatch(setNewPayload(payload))
        // We are getting materials and dropdowns based on above payload initially
        dispatch(formularyOptionsAsync(payload, queryParam))
    }, [dispatch])

    // Get division dropdown
    useEffect(() => {
        dispatch(optionTypeAsync('materialdivision'))
    }, [dispatch])

    // List of formularies
    const formularyList = useSelector(state => state.formulary.formularies)

    const getFormularyList = () => {
        const listPayLoad = {};
        listPayLoad['url'] = FORMULARY
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet, search: (searchKey) ? searchKey : '',sort:sortType }
        listPayLoad['sortType'] = sortType
        listPayLoad['setMethod'] = setFormularies
        dispatch(getFormularyListAsync(listPayLoad,history))
    }

    // useEffect(() => {      
    //     dispatch(setSearchKey(''))
    // }, [])

    
    useEffect(() => {
        getFormularyList();
    }, [dispatch])

    // search list
    const getSearchValue = (event) => {
        if (event.keyCode === 13) {
            const searchPayLoad = {};
            searchPayLoad['url'] = FORMULARY
            searchPayLoad['method'] = 'Get'
            searchPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: 0, search: event.target.value,sort:sortType }
            searchPayLoad['sortType'] = sortType
            searchPayLoad['setMethod'] = setFormularies
            dispatch(setSearchKey(event.target.value))
            dispatch(getFormularyListAsync(searchPayLoad,history));
        }
    }


    // sorting
    const sortingPayLoad = {};
    sortingPayLoad['url'] = FORMULARY
    sortingPayLoad['method'] = 'Get'
    sortingPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet, search: (searchKey) ? searchKey : '',sort:sortType }
    sortingPayLoad['setMethod'] = setFormularies
    sortingPayLoad['setSortType'] = setSortType
    sortingPayLoad['asyncMethod'] = getFormularyListAsync

    // pagination
   
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        setActivePage(data);
        const paginationPayLoad = {};
        paginationPayLoad['url'] = FORMULARY
        paginationPayLoad['method'] = 'Get'
        paginationPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT,sort:sortType }
        paginationPayLoad['setMethod'] = setFormularies
        paginationPayLoad['sortType'] = sortType
        paginationPayLoad['search'] = searchKey
        dispatch(getFormularyListAsync(paginationPayLoad,history));
    }

    // delete
    const deleteList = (data) => {     
        dispatch(deleteFormulariesAsync(data, getFormularyList))
    }

    // Table fixed colum setting
    const showFixedColumn = !!formularyList?.formulary && !!formularyList?.formulary?.length
    const columns = [
        {
            Header: 'Formulary ID',
            accessor: 'id',
            fixedColumn: showFixedColumn,
        },
        {
            Header: 'Formulary name',
            accessor: 'formularyName',
            restWidth: '300px'
        },
        {
            Header: 'Customer #',
            accessor: 'customerNumber',
            restWidth: '300px'
        },
        {
            Header: 'Customer name',
            accessor: 'customerName',
            restWidth: '300px'
        },
        {                                 //added for Add columns for create/update date
            Header: 'Created on',
            accessor: 'dateCreated',
            restWidth: "250px"
        },
        {
            Header: 'Updated on',
            accessor: 'dateUpdated',
            restWidth: "250px"
        }
    ]

    // Handel cancel (in create formulary page)
    const handleCancel = () => {
        setCreateFormulary(false)
    }

    // Get selected row details 

    const getSelectedRowData = (data) => {
        history.push('/admin/formulary/' + data.id,{'dyList':{sortType:sortType,activePage:activePage,offSet: isNaN(offSet)===true?0:offSet},'refPath':'admin/formulary/'})
    }
    const [formularyNamePopup, setFormularyNamePopup] = useState('')

   const [modalShowImport, setmodalShowImport] = useState(false);
   const [addedItems, setAddedItems] = useState([]);
//    const clickImportExcel = () => {
//     // modalOpenImport()
//     setmodalShowImport(true)
//     // clearMatExcel()    
//    }

   /*import actions*/
     const [matColOptions, setMatColOptions] = useState([]);
     const [isMatColOptions, setIsMatColOptions] = useState(false);
     const [isImportMethod, setIsImportMethod] = useState(false);
     const [matFileData, setMatFileData] = useState([]);
     const [isMatValidCol, setIsMatValidCol] = useState(false);
     const [importedMaterials, setImportedMaterials] = useState({});
     const clickImportExcel = () => {
       setmodalShowImport(true);
       clearMatExcel();
     };
     const modalCloseImport = () => {
       setmodalShowImport(false);
       clearMatExcel();
     };
   
     //handleMatExcel
     const handleMatExcel = (e) => {
       const file = e.target?.files[0];
       const fileName = file.name.split(".");
       if (
         fileName[fileName.length - 1] === "csv" ||
         fileName[fileName.length - 1] === "xlsx" ||
         fileName[fileName.length - 1] === "xls"
       ) {
         if (parseInt(file.size / 1024) > 51200) {
           setErrorExcel("importFileMat", {
             type: "manual",
             message: "Please select a file less than 50mb",
           });
         } else {
           clearErrorsExcel("importFileMat");
           readExcel(file);
         }
       } else {
         setErrorExcel("importFileMat", {
           type: "manual",
           message: "Please select only excel file",
         });
       }
     };
     //handleMatCol
     const handleMatCol = (e) => {
       setMatFileData({ ...matFileData, finalExcelMat: [] });
       let file = document?.getElementById("importFileMat")?.files[0];
       let getCol = e?.target?.selectedIndex - 1;
       let getMatkey = e?.target?.value;
       let inValidMat = [];
       let validMat = [];
       Array.isArray(matFileData?.firstFiveRow) &&
         matFileData?.firstFiveRow?.map((item, index) => {
           //NOSONAR
           if (item[getMatkey]) {
             let chckMatVal = item[getMatkey];
             if (!isNaN(chckMatVal.toString().charAt(0))) {
               //If is a first number
               inValidMat = [...inValidMat, item[getMatkey]];
             } else if (!chckMatVal.match(/^[0-9A-Z]+$/)) {
               //If is a alphanumeric Capital only & number only
               inValidMat = [...inValidMat, item[getMatkey]];
             } else if (chckMatVal.indexOf(" ") >= 0) {
               //If is a WhiteSpace
               inValidMat = [...inValidMat, item[getMatkey]];
             } else if (chckMatVal.length < 4) {
               //If is a length min 4
               inValidMat = [...inValidMat, item[getMatkey]];
             } else if (chckMatVal.length > 13) {
               //If is a length max 13
               inValidMat = [...inValidMat, item[getMatkey]];
             } else {
               validMat = [...validMat, item[getMatkey]];
             }
           }
         }); //NOSONAR
       if (
         inValidMat.length === matFileData?.firstFiveRow.length ||
         inValidMat.length > 3
       ) {
         setErrorExcel("selMat", {
           type: "manual",
           message: "Please select the correct material # column",
         });
         setIsMatValidCol(true);
       } else if (validMat.length > 2) {
         clearErrorsExcel("selMat");
         readExcelCol(file, getCol);
         setIsMatValidCol(false);
       } else {
         setErrorExcel("selMat", {
           type: "manual",
           message: "Please select the correct material # column",
         });
         setIsMatValidCol(true);
       }
     };
     const readExcel = async (file) => {
       dispatch(setLoading(true));
   
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
       fileReader.onload = (e) => {
         const bufferArray = e?.target.result;
         const wb = XLSX.read(bufferArray, { type: "buffer" });
         const wsname = wb.SheetNames[0];
         const ws = wb.Sheets[wsname];
         let getRowRange = XLSX.utils.decode_range(ws["!ref"]);
         getRowRange.s.r = 0;
         getRowRange.e.r = 5;
         const newRowRange = XLSX.utils.encode_range(getRowRange);
         let data = XLSX.utils.sheet_to_json(ws, { range: newRowRange });
         const fileName = file?.name?.split(".")[0];
         let headingCol = Object.keys(data[0]);
         headingCol = headingCol.filter(item => !!item.trim());
         headingCol = headingCol.map((item) => ({ key: item, value: item }));
         setMatColOptions(headingCol);
         setIsMatColOptions(true);
         if (addedItems && Array.isArray(addedItems) && addedItems.length > 0) {
           setIsImportMethod(true);
         } else {
           setIsImportMethod(false);
         }
         setMatFileData({ fileName: fileName, firstFiveRow: data });
         dispatch(setLoading(false));
       };
     };
     const readExcelCol = async (file, getCol) => {
       dispatch(setLoading(true));
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
       fileReader.onload = (e) => {
         const bufferArray = e?.target.result;
         const wb = XLSX.read(bufferArray, { type: "buffer" });
         const wsname = wb.SheetNames[0];
         const ws = wb.Sheets[wsname];
         let getColRange = XLSX.utils.decode_range(ws["!ref"]);
         getColRange.s.c = getCol;
         getColRange.e.c = getCol;
         const newColRange = XLSX.utils.encode_range(getColRange);
         const colData = XLSX.utils.sheet_to_json(ws, { range: newColRange });
         setMatFileData({ ...matFileData, finalExcelMat: colData });
         dispatch(setLoading(false));
       };
     };
     const doSubmitImport = (data) => {
       if (isMatValidCol) {
         setErrorExcel("selMat", {
           type: "manual",
           message: "Please select the correct material # column",
         });
       } else if (
         Array.isArray(matFileData?.finalExcelMat) &&
         matFileData?.finalExcelMat.length > 0
       ) {
         clearErrorsExcel("selMat");
         let finalPayloadMat = [data?.selMat];
         Array.isArray(matFileData?.finalExcelMat) &&
           matFileData?.finalExcelMat?.map((item, index) => {
             //NOSONAR
             if (item[data?.selMat]) {
               finalPayloadMat = [...finalPayloadMat, item[data?.selMat]];
             }
           }); //NOSONAR
         let payloadImport = {
           formularyId: formularyId ? formularyId : 0,
           importMethod: data?.importMatMethod && data?.importMatMethod===FORMULARY_IMPORT_METHOD[0]? "Append" :data?.importMatMethod? "Replace":'',
           materials: finalPayloadMat,
         };
         dispatch(importProductToFormulary(payloadImport, setImportedMaterials));
       }
     };
   
     const removeMatExcel = (e) => {
       clearMatExcel();
     };
     const clearMatExcel = () => {
       reset2();
       setMatFileData([]);
       setMatColOptions([]);
       setIsMatColOptions(false);
       setIsImportMethod(false);
       setIsMatValidCol(false);
       setImportedMaterials({});
     };
     useEffect(() => {
       if (
         importedMaterials?.data &&
         Array.isArray(importedMaterials?.data?.materials)
       ) {
         const uniqueList = getUniqueListBy(
           importedMaterials?.data?.materials,
           "materialNumber"
         );
         setAddedItems(uniqueList);
        /* if (addedItems.length > 0) {
           const uniqueList = getUniqueListBy(
             [...addedItems, importedMaterials?.data?.materials],
             "materialNumber"
           );
           setAddedItems(uniqueList);
         } */
       }
     }, [importedMaterials]);
   
    return (
        <>
            {!formularyId && !createFormulary && <ListView
                columns={columns}
                data={formularyList?.formulary}
                metaData={formularyList?.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Formulary ID, Formulary Name, Customer #, Customer Name"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!formularyId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={"Formulary"}
                subHeading={'Formulary'}
                showFixedColumn={showFixedColumn}
                deleteListView={deleteList}
                searchKey={(searchKey) ? searchKey : ''}
                fixedFullWidthSize={15}
                setCreateFormulary={setCreateFormulary}                
                accessError={accessError}
                sortType={sortType?sortType:''}
                setFormularyNamePopup={setFormularyNamePopup}
                clickImportExcel={clickImportExcel}
            />}

            {createFormulary && <CreateFormulary handleCancel={handleCancel} dispatch={dispatch} history={history} setCreateFormulary={setCreateFormulary} formularyNamePopup={formularyNamePopup}/>}
        
            {modalShowImport && (
                <Modal
                modalOpen={modalShowImport}
                modalClose={modalCloseImport}
                heading={
                    importedMaterials?.status
                    ? "File Import"
                    : "Import formulary"
                }
                custom_heading={style.custom_modal_size_import_heading}
                className={"modal_box"}
                modalSize={`${importedMaterials?.status === "success" && importedMaterials?.data?.failedRecords.length===0?style.custom_modal_suc:style.custom_modal_size}`}
                >
                <div className={`pl-2`}>
                    {importedMaterials?.status &&
                    importedMaterials?.status === "success" ? (
                    <>
                        <p className="ml-3  mb-4">
                        {importedMaterials?.data &&
                            Array.isArray(importedMaterials?.data?.failedRecords) &&            
                            importedMaterials?.data?.failedRecords.map((item, key) => {
                            let failmat = item;
                            if(key ===0){
                                failmat='Some materials failed to import:'+ item;
                            }
                            else {
                                failmat = "," + item;
                            }
                            return failmat;
                            })
                            
                            }
                        </p>
                        {importedMaterials?.data &&
                        Array.isArray(importedMaterials?.data?.failedRecords) &&
                        importedMaterials?.data?.failedRecords.length >0 ? (
                        <>
                        <p className="ml-3">
                            Note: Admin has been notified, no further action is needed!
                        </p>
                        <p className="ml-3 mt-n2">
                            Reminder: Please scroll down and click the 'Save' button on the Formulary page. 
                        </p>
                        </>
                        
                        ) : (
                        
                        <>
                        <p className={`${style.confirm_suc} text-center mt-2`}>
                                <h2>Success!</h2>
                        </p>
                        <p className="text-center  mb-2">
                            Reminder: Please scroll down and click the 'Save' button on the Formulary page. 
                        </p>
                        </>
                        )}
                    </>
                    ) : importedMaterials?.status &&
                    importedMaterials?.status === "error" ? (
                    <>
                    <p className="ml-3">
                        <span>{importedMaterials?.message}</span>  
                        </p>
                    </>
                    ) : (
                    <>
                        <Form onSubmit={handleSubmit2(doSubmitImport)}>
                          <div className={`${style.importFormularyPopup}`}>
                        {/* <div className={`d-flex`}> */}
                            <div className={`${matFileData && matFileData?.fileName ? "pb-5 pt-2 text-center" : style.importFormularyPopupBrowser} text-center mr-2`}>
                            {/* <div className={`col-4`}>
                                <label
                                className={`col-form-label ${style.label_spec}`}
                                >
                                Select File:
                                </label>
                            </div> */}
                            {/* <div className={`text-center`}> */}
                                {matFileData && matFileData?.fileName ? (
                                <div className={`${style.documentView} ml-n3`}>
                                    <img src={DocumentIcon} alt="image_icon" />
                                    <a className="blue" rel="noopener noreferrer">
                                    {matFileData?.fileName}
                                    </a>
                                    <span>
                                    <img
                                        src={CloseIcon}
                                        alt="close"
                                        onClick={(e) => removeMatExcel(e)}
                                    />
                                    </span>
                                </div>
                                ) : (
                                <div className="pt-4">
                                  <label
                                      htmlFor={"importFileMat"}
                                      className={`${style.file_upload} ml-n3`}
                                  >
                                      Browse
                                  </label>
                                  <div className="pt-5">
                                  <p>Supported  formats .xlsx, .csv</p>
                                  </div>
                                </div>
                                )}

                                {errors2 &&
                                errors2["importFileMat"] &&
                                errors2["importFileMat"].message && (
                                    <>
                                    <span className={`${style.error_message} ml-n3`}>
                                        {errors2["importFileMat"].message}
                                    </span>
                                    </>
                                )}
                                <input
                                className={"d-none"}
                                type="file"
                                id={"importFileMat"}
                                name="importFileMat"
                                accept=".xls,.xlsx"
                                onChange={(e) => handleMatExcel(e)}
                                ref={register2({
                                    required: "Please select a file",
                                })}
                                />
                            {/* </div> */}
                            </div>
                        {/* </div> */}
                        {isMatColOptions && (
                            <div className={`d-flex`}>
                            <div className={`col-12`}>
                                <SelectBox
                                id={"selMat"}
                                name={"selMat"}
                                label={"Material # column:"}
                                classes={{
                                    column: `row ${style.col_spec} ml-n1`,
                                    inputClassName: `${style.input_field}`,
                                    labelClassName: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                                    inputColumn: `col-7 p-0 `,
                                }}
                                placeholder="Select"
                                optionValue={matColOptions}
                                validator={register2({
                                    required: "Please select material # column",
                                })}
                                errors={errors2}
                                onChange={(e) => handleMatCol(e)}
                                //required={true}
                                //defaultValue={layoutRowData && layoutRowData.salesOffice}
                                />
                            </div>
                            </div>
                        )}

                        {isImportMethod && (
                            <div className={`d-flex`}>
                            <div className={`row col-12 ml-n1 p-3`}>
                                <div className={`col-4`}>
                                <label
                                    className={`col-form-label ${style.label_spec}`}
                                >
                                    Import Method:
                                </label>
                                </div>
                                <RadioButton
                                id={"importMatMethod"}
                                name={"importMatMethod"}
                                classes={{
                                    column: `col-8 p-0 mt-n3 ml-n1`,
                                    labelClass: `custom-control-label  ${style.custom_control_label}`,
                                    inputClassName: `custom-control-input ${style.custom_control_input}`,
                                    inputColumn: ` custom-control custom-radio custom-control-inline ${style.custom_control_inline}`
                                }}
                                validator={register2({
                                    required: "Please select a import method",
                                })}
                                //radioOption={["Replace", "Append"]}
                                radioOption={FORMULARY_IMPORT_METHOD}
                                errors={errors2}
                                // defaultValue={customerData && WOUND_EDGE_AUTO_TRACING_STATUS[customerData?.settings?.woundAssessmentScore]}
                                />
                            </div>
                            </div>
                        )}
                        <div className={`d-flex justify-content-center align-items-center text-center pt-5 pb-3`}>                         
                            <Button
                            classes={{
                                className: `btn mr-4 ${style.btn_custom} ${style.btn_small_cancel}`,
                            }}
                            type="button"
                            onClick={() => modalCloseImport()}
                            >
                            {CANCEL_BTN}
                            </Button>
                            <Button
                            classes={{
                                className: `btn ${style.btn_custom} ${style.btn_small}  ml-4`,
                            }}
                            type="submit"
                            >
                            {"Upload"}
                            </Button>
                        </div>
                        </div>
                        </Form>
                    </>
                    )}
                </div>
                </Modal>
            )}
                
        </>
    )
}

Formulary.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Formulary = connect()(withRouter(Formulary));
