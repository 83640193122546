import React, { useEffect, useState } from 'react';
import ReactImageAnnotate from "react-wound-image-annotate";
import style from './measurement.module.scss';
import { Button, TextField, Form, AccessErrorModal } from '../../primary';
import { Modal } from '../../secondary/Modal';
import './measurement.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { checkAutoMeasurementStatusAsync, setAutoMeasurementStatus, setWoundMeasurementData, woundEvalAsync, woundMeasurementUpdateAsync,detailSnakeAlgorithmAsync,ne1updateassessmentmeasurementAsync } from '../../../app/patientSlice';
import { withRouter, useParams } from 'react-router-dom';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import Loader from '../../../static/images/spinner.svg';
import measurements_loader from '../../../static/images/measurements_loader.gif';
import { measurementNumber, fullMonthFormatDate, prefixO, signedLockedAccess } from '../../../utils';
import iconDanger from '../../../static/images/icon-check-danger.png'
import {
  EDIT,
  CANCEL_BTN,
  SAVE_BTN
} from '../../../constants';
import {
  btn_custom,
  btn_small,
  btn_small_cancel
} from '../../../styles/common.module.scss';
import { clearError } from '../../../app/appSlice';
import infoIcon from '../../../static/images/info.svg';
import { Confirmation } from '../../secondary/Confirmation/confirmation'   
import warning from '../../../static/images/icon-check-danger.png';
export let Measurement = ({ detailAssessment, hideIt }) => {
  
  const { patientId } = useParams()
  const { assessmentId } = useParams()
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const [assessmentDetail, setAssessmentDetail] = useState();

  const [measurementModal, setMeasurementModal] = useState(false);
  const [annotationView, setAnnotationView] = useState(false);
  const [measurementForm, setMeasurementForm] = useState(false);
  const [systemMeasured, setSystemMeasured] = useState(false);
  const [confirm, setConfirm] = useState(false)

  // measured annotate value function
  const measurementData = useSelector(state => state.patient.measurementData);
  const autoMeasurementStatus = useSelector(state => state.patient.autoMeasurementStatus);
  const loading = useSelector(state => state.app.loading);
  const [assessedPicture, setAssessedPicture] = useState();
  const [surfaceArea, setSurfaceArea] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  /* For set interval timer */
  const [intervalId, setIntervalId] = useState([])

  const errorMessage = useSelector(state => state.app.errorMessage)
  const isSnakeAlgorithms = useSelector(state => state.patient.isSnakeAlgorithms);
  //check SnakeAlgorith
  useEffect(() => {
    //detailAssessment?.measurement?.isSnakeAlgorithmsuccessful
    if(isSnakeAlgorithms===2&&detailAssessment?.isWoundWithoutPhoto === 0&&detailAssessment?.measurement?.isStickerAvailable === 1){
      setTimeout(() => {
        dispatch(detailSnakeAlgorithmAsync({ patientId, assessmentId }))
      }, 10000)
    }
  }, [isSnakeAlgorithms,detailAssessment]);

  /* auto measurement modal */
  const showMeasurementModal = (type) => {
    setMeasurementModal(true);
    if (type === 'Dimensions') {
      setAnnotationView(false);
      setMeasurementForm(true);
    } else {
      setAnnotationView(true);
      setMeasurementForm(false);
    }
    setAssessedPicture(undefined)
  }

  // confirmation modal
  const confirmation = () => {
    if(document?.querySelector('.fullscreen')&&document?.querySelector('.fullscreen svg > polygon')){
      setConfirm(true);
    }else{
      assessModalClose();
    }
   
  }

  const assessModalClose = () => {
    setMeasurementModal(false);
    setAnnotationView(false);
    setConfirm(false);
    setMeasurementForm(false);
    dispatch(clearError())
  }

  const confirmationClose = () => {
    setConfirm(false);
  }

  useEffect(() => {
    setAssessmentDetail(null);
    setAssessedPicture(null);
    setSurfaceArea('');
    setLength('');
    setWidth('');
    dispatch(setAutoMeasurementStatus(''));
    dispatch(setWoundMeasurementData(null));
    dispatch(clearError());
  }, [dispatch, patientId, assessmentId]);

  useEffect(() => {
    if (measurementData?.assessedPicture) {
      setAssessedPicture(`data:image/jpeg;base64,${measurementData.assessedPicture}`);
      setAnnotationView(false);
      if (measurementData.isStickerAvailable === 0 || isNaN(measurementData.surfaceArea) || isNaN(measurementData.length) || isNaN(measurementData.width)) {
        setSystemMeasured(false);
      } else {
        setMeasurementForm(true);
        setSurfaceArea(measurementData.surfaceArea);
        setLength(measurementData.length);
        setWidth(measurementData.width);
        setSystemMeasured(true);
      }
    } else {
      setAssessedPicture(null);
      setSurfaceArea('');
      setLength('');
      setWidth('');
    }
  }, [measurementData]);

  const [saveClicked, setSaveClicked] = useState(false)


  useEffect(() => {
    setAssessmentDetail(detailAssessment);
    if (measurementData?.assessedPicture && saveClicked) {
      let _assessment = { ...detailAssessment };
      _assessment.measurement = { ..._assessment.measurement, assessedPicture, surfaceArea, length, width };
      setAssessmentDetail(_assessment)
    }
    if (detailAssessment?.measurement) {
      setSystemMeasured(detailAssessment?.measurement?.systemMeasured === 1 && assessmentDetail?.measurement?.isStickerAvailable===1 ? true : false);
      if (autoMeasurementStatus === 'inprogress' && detailAssessment?.measurement?.isStickerAvailable === 1 && !detailAssessment?.measurement?.surfaceArea > 0) {
        if (intervalId.length === 0) {
          let _intervalId = setInterval(() => {
            dispatch(checkAutoMeasurementStatusAsync(patientId, assessmentId))
          }, process.env.REACT_APP_UPLOADING_CHECK_INTERVAL)
          setIntervalId([...intervalId, _intervalId]);
        }
      } else {
        intervalId.forEach(clearInterval);
      }
    }
  }, [dispatch, patientId, assessmentId, detailAssessment, saveClicked, autoMeasurementStatus, intervalId, measurementData, assessedPicture, surfaceArea, length, width]);

  const addMeasurementForm = () => {
    setAnnotationView(false);
    setMeasurementForm(true);
  }

  let evalWound = (annotation) => {
    setAnnotationView(false);
    const { regions } = annotation.images[0];
    /*let woundSvgElement = document.getElementById('wound').querySelector('canvas').parentElement.querySelector('svg');
    const { pixelSize, regions } = annotation.images[0];
    let canvas = document.createElement('canvas');
    canvas.width = pixelSize.w;
    canvas.height = pixelSize.h;
    let context = canvas.getContext('2d');
    let woundImage = new Image();
    woundImage.crossOrigin = "anonymous";
    let picture = "";
    woundImage.onload = function () {
      try {
        context.drawImage(woundImage, 0, 0, pixelSize.w, pixelSize.h);
        picture = canvas.toDataURL("image/jpeg");
        const payload = { 
          "id": assessmentId, 
          "picture": picture.replace('data:image/jpeg;base64,', ''), 
          "tracing": "Manual", 
          "coordinates": regions[0]?.points,
          "channel": "web",
          "stickerArea" : assessmentDetail?.measurement?.stickerArea,
          "stickerLength" : assessmentDetail?.measurement?.stickerLength
        }
        dispatch(woundEvalAsync(payload));
        let woundSvgXml = new XMLSerializer().serializeToString(woundSvgElement).replaceAll('rgba(255,0,0,0.75)', 'rgb(0,255,0)').replaceAll('255,0,0,0.25', '0,255,0,0');
        const URL = window.URL || window.webkitURL || window;
        const woundSvgUrl = URL.createObjectURL(new Blob([woundSvgXml], { type: 'image/svg+xml;charset=utf-8' }));
        let woundLocationSVG = new Image();
        woundLocationSVG.crossOrigin = "anonymous";
        woundLocationSVG.onload = function () {
          context.drawImage(woundLocationSVG, 0, 0, pixelSize.w, pixelSize.h);
          const annotatedPicture = canvas.toDataURL("image/png");
          const payload = { 
            "id": assessmentId, 
            "picture": picture.replace('data:image/jpeg;base64,', ''), 
            "annotatedPicture": annotatedPicture.replace('data:image/png;base64,', ''), 
            "tracing": assessmentType, 
            "coordinates": autoMeasure ? {x: regions[0]?.x, y: regions[0]?.y, w: regions[0]?.w, h: regions[0]?.h} : regions[0]?.points,
            "channel": "web" 
          }
          dispatch(woundEvalAsync(payload))
          URL.revokeObjectURL(woundSvgUrl)
        };
        woundLocationSVG.src = woundSvgUrl; 
      } catch (e) {
        console.log(e);
      }
    };
    woundImage.src = assessmentDetail?.picture;*/

    const payload = {
      "id": assessmentId,
      //"picture": picture.replace('data:image/jpeg;base64,', ''), 
      "picture": assessmentDetail&&assessmentDetail?.picture,//NOSONAR
      "tracing": "Manual",
      "coordinates": regions[0]?.points,
      "channel": "web",
      "stickerArea": assessmentDetail&&assessmentDetail?.measurement?.stickerArea,//NOSONAR
      "stickerLength":assessmentDetail&& assessmentDetail?.measurement?.stickerLength //NOSONAR
    }
    dispatch(woundEvalAsync(payload,patientId,assessmentId));
    //if(patientId&&assessmentId)
      //dispatch(detailSnakeAlgorithmAsync({ patientId, assessmentId }))
  }


  const doSubmit = (data) => {
    let surFaceFinal=data?.surfaceArea?prefixO(data.surfaceArea):assessmentDetail?.measurement?.surfaceArea?assessmentDetail?.measurement?.surfaceArea: 0;
      // if((assessmentDetail?.woundStage!=='resolve'||surFaceFinal===0)&&(assessmentDetail?.measurement?.isStickerAvailable===0||assessmentDetail?.isWoundWithoutPhoto===1)&&(data?.length&&data?.width)){
      //   surFaceFinal=prefixO(data.length*data.width)
      // }
      if((assessmentDetail?.isWoundWithoutPhoto === 0 && assessmentDetail?.woundStage==='resolve'&&assessmentDetail?.measurement?.isStickerAvailable===1&&assessmentDetail?.measurement?.isMeasurementResolved===1) ){
        surFaceFinal=data?.surfaceArea?prefixO(data.surfaceArea):assessmentDetail?.measurement?.surfaceArea?assessmentDetail?.measurement?.surfaceArea: 0;
      }
      else if((assessmentDetail?.measurement?.isStickerAvailable===0||assessmentDetail?.isWoundWithoutPhoto===1)&&(data?.length&&data?.width)){
        surFaceFinal=prefixO(data.length*data.width)
      }
    const payload = Object.assign({}, detailAssessment, {
     measurement: {
        assessedPicture: measurementData?.assessedPicture || assessmentDetail && assessmentDetail?.measurement?.assessedPicture || assessmentDetail&&assessmentDetail?.picture,
        isStickerAvailable: assessmentDetail && assessmentDetail?.measurement?.isStickerAvailable,
        stickerArea: assessmentDetail && assessmentDetail?.measurement?.stickerArea,
        stickerLength: assessmentDetail && assessmentDetail?.measurement?.stickerLength,       
        surfaceArea:surFaceFinal,
        //surfaceArea: data?.surfaceArea?prefixO(data.surfaceArea):assessmentDetail?.measurement?.surfaceArea?assessmentDetail?.measurement?.surfaceArea: 0,
        length: data?.length?prefixO(data.length):assessmentDetail?.measurement?.length?assessmentDetail?.measurement?.length: 0,
        width: data?.width?prefixO(data.width):assessmentDetail?.measurement?.width?assessmentDetail?.measurement?.width: 0,
        tunneling: prefixO(data?.tunneling),
        depth: prefixO(data?.depth),
        undermining: prefixO(data?.undermining),
        systemMeasured: systemMeasured ? 1 : 0,
        isMeasurementResolved:assessmentDetail?.measurement?.isMeasurementResolved
      }
    });
    //dispatch(woundMeasurementUpdateAsync(patientId, payload))
    dispatch(ne1updateassessmentmeasurementAsync(patientId, payload))
    //ne1updateassessmentmeasurementAsync
    setMeasurementForm(false);
    setMeasurementModal(false);
    if (editable) {
      setEditable(false);
      setEditBtn(true)
    }
  }


  /* State to check editable forms */
    // Edit state
    const [editable, setEditable] = useState(false)
    const [formChangesConfirm, setFormChangesConfirm] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [clickCancel, setClickCancel] = useState(false)
    const confirmationCloseOver = () => {
        setConfirmationModal(false);
        setClickCancel(false)
        setEditBtn(false)
    }
      const yesConfirmationModalClick = () => {
        setConfirmationModal(false)
        setEditable(false)
        setFormChangesConfirm(false)
        setClickCancel(false)
        setEditBtn(true)
      }
      useEffect(() => {
          if(editable){
              if(formChangesConfirm&&clickCancel){
                  setConfirmationModal(true);
                }else if(clickCancel&&formChangesConfirm===false){
                  setClickCancel(false)
                  setConfirmationModal(false);
                  setEditable(false)
                  setEditBtn(true)
                }
          }
      }, [formChangesConfirm,clickCancel,editable])

  const [editBtn, setEditBtn] = useState(true)
  const handleEdit = (edit,cancel) => {
    if (edit === 'editable') {
        setEditable(true)
        setEditBtn(false)
    }
    else if(cancel==='cancel'){
      setClickCancel(true)
    } else {
      setEditable(false);
      setEditBtn(true)
    }
  }

  let btnLabel = 'Dimensions';
  let insrTxt=''
  
  if (assessmentDetail?.measurement?.isStickerAvailable === 1) {
    if (autoMeasurementStatus === 'inprogress') {
      btnLabel = '';
      insrTxt = 'We are still processing the wound photo. Once completed, the AI will auto-detect the wound edges and calculate the surface area, length, and width';
     
    } else if (autoMeasurementStatus&&autoMeasurementStatus !== 'inprogress') {
      btnLabel = 'Start Measurement';
      insrTxt = 'No measurement data found. Click \'Start Measurement\' button to calculate dimensions';
    }
  }else if (assessmentDetail?.measurement&&assessmentDetail?.measurement?.isStickerAvailable !== 1) {
    insrTxt = 'No measurement data found. Wound photo was taken without a sticker. Click \'Dimensions\' button to enter dimensions manually';
    
  }
  const [tinyDelay, setTinyDelay] = useState(true)

  useEffect(() => {
      setTimeout(() => {
        setTinyDelay(false)
      }, 500)
  }, [dispatch,autoMeasurementStatus])

  const nextDrawCall = () => {
    if(document?.querySelector('.next_draw')){
        document.querySelector('.next_draw').click()
    }
}
const clearDrawCall = () => {
    if(document?.querySelector('.clear_draw')){
        document.querySelector('.clear_draw').click()
    }
}

 /* useEffect(() => {
 if(assessmentDetail?.isWoundWithoutPhoto===1){
if(assessmentDetail?.length||assessmentDetail?.surfaceArea||assessmentDetail?.width){
   setEditable(false);
    setEditBtn(true)
  }else{
    setEditable(true);
    setEditBtn(false)
  }

 }
}, [assessmentDetail])*/


// <-----ZoomIn/Out OPtion----->
const [initalState, setInitalState] = useState(0);
const [zoomClick, setZoomClick] = useState(false);
    
    const zoomInAction = (direction) => {
        if(initalState <= 8){
            setZoomClick(true)
            setInitalState(initalState + 1) 
        }
        else{
            setZoomClick(true)
            setInitalState(9)
        }

        // const delta = 10 * direction;
        // const element = document.getElementById('patient_wound');
        // const positionInfo = element.getBoundingClientRect();
        // element.style.width = positionInfo.width+delta+'px';
        // element.style.height = positionInfo.height+(delta-1)+'px';
    }


    const zoomOutAction = (direction) => { 
        if(initalState >= 1 && initalState <= 9) {
            setZoomClick(true)
            setInitalState(initalState - 1)
        }
        
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
        if (initalState < 0){
            setZoomClick(true)
            setInitalState(0)
        }     
    }    

    const reSizeAction = () => {
        setZoomClick(true)
        setInitalState(0) 
    }

    useEffect(()=>{
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
    },[initalState])
// <-----ZoomIn/Out OPtion----->


// <----Measurement Gif Loader when tracing without form populate----->
const [retrace, setRetrace] = useState(false)
useEffect(()=>{
  if(retrace===true && measurementData!==null){
    setSaveClicked(true)
    const payload = Object.assign({}, detailAssessment, {
      measurement: {
        assessedPicture: measurementData?.assessedPicture || assessmentDetail && assessmentDetail?.measurement?.assessedPicture || assessmentDetail&&assessmentDetail?.picture,
        isStickerAvailable: assessmentDetail && assessmentDetail?.measurement?.isStickerAvailable,
        stickerArea: assessmentDetail && assessmentDetail?.measurement?.stickerArea,
        stickerLength: assessmentDetail && assessmentDetail?.measurement?.stickerLength, 
        
        surfaceArea: measurementData?.surfaceArea? measurementData?.surfaceArea : 0,
        length: measurementData?.length? measurementData?.length : 0,
        width: measurementData?.width? measurementData?.width: 0,
        tunneling: measurementData?.tunneling,
        depth: measurementData?.depth,
        undermining: measurementData?.undermining,

        // surfaceArea: measurementData?.surfaceArea?prefixO(measurementData.surfaceArea):assessmentDetail?.measurement?.surfaceArea?assessmentDetail?.measurement?.surfaceArea: 0,
        // length: measurementData?.length?prefixO(measurementData.length):assessmentDetail?.measurement?.length?assessmentDetail?.measurement?.length: 0,
        // width: measurementData?.width?prefixO(measurementData.width):assessmentDetail?.measurement?.width?assessmentDetail?.measurement?.width: 0,
        // tunneling: prefixO(measurementData?.tunneling),
        // depth: prefixO(measurementData?.depth),
        // undermining: prefixO(measurementData?.undermining),

        systemMeasured: systemMeasured ? 1 : 0
      }
    });
   // dispatch(woundMeasurementUpdateAsync(patientId, payload))
    setMeasurementForm(false);
    setMeasurementModal(false);
    if (editable) {
      setEditable(false);
      setEditBtn(true)
    }
  }
},[retrace, measurementData])
// <----Measurement Gif Loader----->

const [tinyDelayLoad, setTinyDelayLoad] = useState(false)

useEffect(() => {
  setTimeout(() => {
    setTinyDelayLoad(true)
  }, 2500)
}, [])

  return(<>   
   {!tinyDelayLoad ?<div className={`${'loader'} col-12`}>
  <img src={Loader} alt="loading" />
  </div>:
  <><div>
 {
      confirmationModal && <Confirmation
        onClose={() => confirmationCloseOver()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">All progress in this section will be lost. Are you sure want to cancel?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${style.yes_btn}` }}
              onClick={() => yesConfirmationModalClick()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${style.no_btn}` }}
              onClick={() => confirmationCloseOver()}
            />
          </div>
        </div>
      </Confirmation>
    }
    <AccessErrorModal />

    {`${assessmentDetail?.id}` === assessmentId &&
      <div className="d-flex row no-gutters">
        <div className='col-4'>
           { 
             (detailAssessment?.isWoundWithoutPhoto === 0)&&(isSnakeAlgorithms===2||isSnakeAlgorithms === '')&&assessmentDetail?.measurement?.isStickerAvailable === 1 ? <div className={`${style.loader_position} ${style.loader_text} text-center`}>
                <h3 className={`pt-5 pb-2`}>Measuring the wound</h3>
                <p>This won't take long</p>
                <img src={measurements_loader} alt="loading" className={style.loader_gif} />
              </div>:
                    
          <div  className= {style.retrace_posr}>
            <div  className={`${style.patient_wound}`}>
            {/* <img src={assessmentDetail?.measurement?.assessedPicture || assessedPicture || assessmentDetail?.picture} alt="wound" className={style.image_siz} /> */}
              <img 
                src={assessmentDetail?.measurement?.assessedPicture || assessedPicture || assessmentDetail?.picture} 
                alt="wound" 
                className={style.image_siz}
                id="patient_wound" 
                style={Object.assign({},
                    zoomClick === true && {transform : `scale(1.${initalState}, 1.${initalState})`})}
              />
            </div>
            {assessmentDetail?.isWoundWithoutPhoto === 0 &&
              <div className={`${style.iconListEdit} d-flex align-items-center justify-content-around`}>
                  <div className='pl-4'>
                      <i class={`${style.iconHover} fa fa-search-plus`} style={{color : '#fff', fontSize:'18px'}} onClick={zoomInAction} aria-hidden="true"></i>
                  </div>
                  <div className=''>
                      <i class={`${style.iconHover} fa fa-search-minus`} style={{color : '#fff', fontSize:'18px'}} onClick={zoomOutAction} aria-hidden="true"></i>
                  </div>
                  <div className='pr-5'>
                      <i class={`${style.iconHover} fa fa-arrows-alt`} style={{transform :`rotate(45deg) scale(1.1)`,color : '#fff', fontSize:'18px'}} onClick={reSizeAction} aria-hidden="true"></i>
                  </div>
              </div>
            }
          </div>}
          {
            signedLockedAccess(assessmentDetail?.assessmentStage) && hideIt && assessmentDetail?.isWoundWithoutPhoto===0&&isSnakeAlgorithms!==2&& isSnakeAlgorithms!==''&&<>
              {(autoMeasurementStatus !== 'inprogress' && (assessmentDetail?.measurement?.isStickerAvailable === 1 || !assessmentDetail?.measurement?.surfaceArea > 0)) &&
               <>
                  {/*{(assessmentDetail?.measurement?.assessedPicture || assessedPicture) && assessmentDetail?.measurement?.isStickerAvailable === 1 ?<>*/}
                  {assessmentDetail?.measurement?.isStickerAvailable === 1 &&assessmentDetail?.measurement?.surfaceArea!==''?<>
                   <div className={style.retrace_blk}>  
                   Click  <Button
                    type={"submit"}
                    value="Manual Trace"
                    id={"autoMeasurement"}
                    onClick={() => {showMeasurementModal('Retrace Wound Edges'); setRetrace(true)}}
                  /> to adjust wound edges
                  </div>
                  </> :''
                  }
                </>}
            </>
          }
        </div>
        {/* <div className='col-4'>
                   
          <div  className= {style.retrace_posr}>
            <img src={assessmentDetail?.measurement?.assessedPicture || assessedPicture || assessmentDetail?.picture} alt="wound" className={style.image_siz} />
          </div>
          {
            signedLockedAccess(assessmentDetail?.assessmentStage) && hideIt && assessmentDetail?.isWoundWithoutPhoto!==1 && <>
              {(autoMeasurementStatus !== 'inprogress' && (assessmentDetail?.measurement?.isStickerAvailable === 1 || !assessmentDetail?.measurement?.surfaceArea > 0)) &&
                <div className={style.retrace_blk}>
                  {(assessmentDetail?.measurement?.assessedPicture || assessedPicture) && assessmentDetail?.measurement?.isStickerAvailable === 1 ? <Button
                    type={"submit"}
                    value="Retrace Wound Edges"
                    id={"autoMeasurement"}
                    onClick={() => showMeasurementModal('Retrace Wound Edges')}
                  /> : <Button
                    type={"submit"}
                    value={btnLabel}
                    id={"autoMeasurement"}
                    onClick={() => showMeasurementModal(btnLabel)} />
                  }
                </div>}
            </>
          }
        </div> */}
        
        {
          signedLockedAccess(assessmentDetail?.assessmentStage) && hideIt && !assessmentDetail?.measurement?.assessedPicture && !assessedPicture && !assessmentDetail?.measurement?.surfaceArea > 0 && assessmentDetail?.isWoundWithoutPhoto!==1 ?
            <div className="col w-100 pl-4 pr-4 text-center">
              <div className={style.btns_top_space}>
                <div className="mb-3">
                  {btnLabel==='Dimensions'&&assessmentDetail?.measurement?.isStickerAvailable !==1 ?
                  <>
                  We couldn't auto measure this wound because wound photo was taken without the sticker. <br/>
<p className={`${style.dim_man_p}`}>Click  <Button
                    type={"submit"}
                    value={btnLabel}
                    id={"autoMeasurement"}
                    classes={{ className: `btn pt-2 ${style.dim_man_btn}` }}
                   
                    onClick={() => showMeasurementModal(btnLabel)} />  to manually enter the measurements.</p>
                  </>:assessmentDetail?.measurement?.isStickerAvailable === 1&&assessmentDetail?.measurement?.isSnakeAlgorithmsuccessful === 0?
                  <>
                 Opps, our app is feeling a bit camera-shy today! <br/>
                 <p className={`${style.dim_man_p}`}>Don't worry; let's try a  <Button
                      type={"submit"}
                      value="Manual Trace"
                   classes={{ className: `btn pt-2 ${style.dim_man_btn}` }}
             
                      id={"autoMeasurement"}
                      onClick={() => {showMeasurementModal('Retrace Wound Edges'); setRetrace(true)}} /></p>
                  </>:''}
                </div>
              </div>
            </div> : 
            <div className="col-8 pt-4 pb-4">
              <div className="w-100 pl-4 pr-4">
                <div className="d-flex justify-content-between w-100 mb-4">
                  <div className={style.assess_heading}>{assessmentDetail?.measurement?.measurementDate?fullMonthFormatDate(assessmentDetail?.measurement?.measurementDate):fullMonthFormatDate(assessmentDetail?.identifiedDate)}</div>
                  <div>
                    {
                      signedLockedAccess(assessmentDetail?.assessmentStage) && hideIt && editBtn &&(isSnakeAlgorithms!==2&& isSnakeAlgorithms!==''||assessmentDetail?.isWoundWithoutPhoto === 1)&& <div className={style.edit_btn} onClick={() => handleEdit('editable')}>
                        <img src={editIcon} alt="edit icon" /> {EDIT}
                      </div>
                    }
                  </div>
               
                </div>
               
                {/* editable form */}
                {!editable &&(isSnakeAlgorithms!==2&& isSnakeAlgorithms!==''||assessmentDetail?.isWoundWithoutPhoto === 1||signedLockedAccess(assessmentDetail?.assessmentStage)===false)&& <div className={style.details_container}>
                  <div className={style.measure_surface}>
                    Surface Area:
                                        
                    <span className={`${style.cm_font} pl-1`}>
                      {/* {assessmentDetail?.measurement?.surfaceArea && <span>{`${assessmentDetail?.measurement?.surfaceArea} cm`}<sup><b>2</b></sup></span>} */}
                      {assessmentDetail?.measurement?.surfaceArea && assessmentDetail?.measurement?.surfaceArea !== "Unknown" ? <span>{`${assessmentDetail?.measurement?.surfaceArea} cm`}{assessmentDetail?.measurement?.isStickerAvailable ===1 &&assessmentDetail?.isWoundWithoutPhoto === 0&&<sup><b>2</b></sup>} 
                        {(assessmentDetail?.measurement?.isStickerAvailable === 0||assessmentDetail?.isWoundWithoutPhoto === 1) && <span className={`${style.tooltip}`}>                   
                          <img src={infoIcon} alt="info" style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc", visibility: "visible"}}/>
                        {/* <i class="fa-solid fa-exclamation"style={{color : "#ff0000"}} aria-hidden="true"> */}
                        <span class={`${style.tooltiptext}`}>Surface area is auto-calculated using length <span style={{fontWeight:"bold"}}>x</span> width formula</span>
                        {/* </i> */}
                      </span>} 
                    </span>:<span>{`${assessmentDetail?.measurement?.surfaceArea}`}</span> }
                    </span>
                  </div>
                  <div className={style.measure_length}>Length: <span className={style.cm_font}>
                    {assessmentDetail?.measurement?.length && `${assessmentDetail?.measurement?.length} cm`}
                  </span>
                  </div>
                  <div className={style.measure_width}>Width: <span className={style.cm_font}>
                    {assessmentDetail?.measurement?.width && `${assessmentDetail?.measurement?.width} cm`}
                  </span>
                  </div>
                  <div className={style.measure_items}>Depth: <span className={style.cm_font}>
                    {assessmentDetail?.measurement?.depth && `${assessmentDetail?.measurement?.depth} cm`}
                  </span>
                  </div>                  
                  <div className={style.measure_items}>Undermining: <span className={style.cm_font}>
                    {assessmentDetail?.measurement?.undermining && `${assessmentDetail?.measurement?.undermining} cm`}
                  </span>
                  </div>
                  <div className={style.measure_items}>Tunneling: <span className={style.cm_font}>
                    {assessmentDetail?.measurement?.tunneling && `${assessmentDetail?.measurement?.tunneling} cm`}
                  </span>
                  </div>
                </div>}
                {editable &&(isSnakeAlgorithms!==2&& isSnakeAlgorithms!==''||assessmentDetail?.isWoundWithoutPhoto === 1)&& 
                  <Form setFormChangesConfirm={setFormChangesConfirm} formChanges={true} onSubmit={handleSubmit(doSubmit)}>
                    <div>
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label={<div className={`${style.label_spec}`}>Surface Area (cm{assessmentDetail?.measurement?.isStickerAvailable ===1 &&assessmentDetail?.isWoundWithoutPhoto === 0&&<sup><b>2</b></sup>}): 
                          {(assessmentDetail?.measurement?.isStickerAvailable === 0||assessmentDetail?.isWoundWithoutPhoto === 1) &&  <span className={`${style.tooltip_Edit}`}>                   
                           <img src={infoIcon} alt="info" style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"6px", marginTop: "-1px", color:"#0052cc", visibility: "visible"}}/>
                          {/* <i class="fa-solid fa-exclamation"style={{color : "#ff0000"}} aria-hidden="true"> */}
                          <span class={`${style.tooltiptext}`} style={{color: "#000"}}>Surface area is auto-calculated using length <span style={{color: "#000",fontWeight:"bold"}}>x</span> width formula</span>
                          {/* </i> */}
                        </span>}                        
                      </div>}
                        type='number'
                        name='surfaceArea'
                        defaultValue={assessmentDetail?.measurement?.surfaceArea}
                        validator={register(measurementNumber)}
                        errors={errors}
                       // disabled={true}
                        disabled = {(assessmentDetail?.isWoundWithoutPhoto === 0 && assessmentDetail?.woundStage==='resolve'&&assessmentDetail?.measurement?.isStickerAvailable===1&&assessmentDetail?.measurement?.isMeasurementResolved===1) ? false : true}
                        // disabled = {assessmentDetail?.woundStage==='resolve' ? false : (assessmentDetail?.woundStage !=='resolve'? true : systemMeasured)}
                        // disabled={(assessmentDetail?.isWoundWithoutPhoto === 0 && assessmentDetail?.woundStage==='resolve') || (assessmentDetail?.isWoundWithoutPhoto !== 1 && assessmentDetail?.woundStage === "resolve")? false: systemMeasured}
                      />
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label='Length (cm):'
                        type='number'
                        name='length'
                        defaultValue={assessmentDetail?.measurement?.length}
                        validator={register(measurementNumber)}
                        errors={errors}
                        disabled={assessmentDetail?.woundStage==='resolve'?false:systemMeasured}
                      />
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label='Width (cm):'
                        type='number'
                        name='width'
                        defaultValue={assessmentDetail?.measurement?.width}
                        validator={register(measurementNumber)}
                        errors={errors}
                        disabled={assessmentDetail?.woundStage==='resolve'?false:systemMeasured}
                      />
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label='Depth (cm):'
                        type='number'
                        name='depth'
                        defaultValue={assessmentDetail?.measurement?.depth}
                        validator={register(measurementNumber)}
                        errors={errors}
                      />
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label='Undermining (cm):'
                        type='number'
                        name='undermining'
                        defaultValue={assessmentDetail?.measurement?.undermining}
                        validator={register(measurementNumber)}
                        errors={errors}
                      />
                      <TextField
                        classes={{ column: `row mb-3 align-items-center`, labelClass: `col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field}` }}
                        label='Tunneling (cm):'
                        type='number'
                        name='tunneling'
                        defaultValue={assessmentDetail?.measurement?.tunneling}
                        validator={register(measurementNumber)}
                        errors={errors}
                      />
                      
                    </div>
                    <div className="pt-4 pb-3 text-center">
                      <Button
                        classes={{ className: `btn ${style.save_custom}` }}
                        type="submit"
                      >
                        {SAVE_BTN}
                      </Button>
                      <Button
                        type={"button"}
                        value={CANCEL_BTN}
                        classes={{ className: `btn ${style.cancel_btn}` }}
                        onClick={() => {handleEdit('nonEditable','cancel');}}
                      />
                    </div>
                  </Form>
                }
              </div>
            </div>
        }
      </div>}
    {/* measurement modal */}
    {
      measurementModal && <Modal
        modalOpen={true}
        modalClose={() => confirmation()}
        heading={measurementForm ? "Dimensions" : "Measurements"}
        className={"modal_box"}
        modalSize={style.custom_modal_size}
        confirmation={confirm}
        overlay={" "}
        overlay_class={confirm ? style.overlay_class : ""}
      >
        <div className={style.parent}>
          {
            !loading &&
            <>
            
            {/* <div  className={`col-12 justify-content-center mb-3 main_next_clear`} >
                <Button
                value={"Next"}
                classes={{ className: `btn mr-4 ${style.btn_add}` }}
                onClick={() => nextDrawCall()}
                />
                <Button
                value="Clear"
                classes={{ className: `btn  ml-4 pb-2 pt-2 ${style.no_btn_clr}` }}
                onClick={() => clearDrawCall()}
                />
            </div> */}

            {/* added for measurement git animation loader */}
            <div  className={`${style.button_Right} col-3 align-items-center  main_next_clear`} >
                <div  className={`${style.button_Right_Block}`} >
                <Button
                value={"Next"}
                classes={{ className: `btn ml-4 mb-3 ${style.btn_add}` }}
                onClick={() => nextDrawCall()}
                />
                <Button
                value="Clear"
                classes={{ className: `btn  ml-4 pt-2 ${style.no_btn_clr}` }}
                onClick={() => clearDrawCall()}
                />
                </div>
            </div>
            {/* added for measurement git animation loader */}

            <div className={`col-12 pl-4 pr-4 mb-4 sub_next_clear ${style.img_alert} text-center`}>
              {annotationView && 'Manually trace the wound edges to calculate the dimensions'}
            </div>

            </>
          }
          <div id="wound" className={`row d-flex mb-4 ${style.annotate_container}`}>          

            {
              !loading && annotationView && <ReactImageAnnotate
                selectedTool="create-polygon"
                onExit={(annotation) => evalWound(annotation)}
                hideHeaderText
                hideNext
                hidePrev
                showTags={false}
                images={[
                  {
                    src: assessmentDetail?.picture,
                    regions: []
                  }
                ]}
              />
            }

            {/* added for measurement git animation loader */}
            {    
              loading && <div className={`${style.loader_position} ${style.loader_text} text-center`}>
                <h3 className={`pt-5 pb-2`}>Measuring the wound</h3>
                <p>This won't take long</p>
                <img src={measurements_loader} alt="loading" className={style.loader_gif} />
              </div>
            }
            {/* added for measurement git animation loader */}

            {/* added for measurement git animation loader */}
            {/* { !loading && 
              <div  className={`${style.button_Right} col-12 align-items-center  main_next_clear`} >
                  <div  className={`${style.button_Right_Block} col-12`} >
                  
                  <Button
                  value="Clear"
                  classes={{ className: `btn  ml-3 pt-2 ${style.no_btn_clr}` }}
                  onClick={() => clearDrawCall()}
                  />
                  <Button
                  value={"Next"}
                  classes={{ className: `btn ml-3 mb-3 ${style.btn_add}` }}
                  onClick={() => nextDrawCall()}
                  />

                  </div>
              </div>
            } */}
            {/* added for measurement git animation loader */}
                 

            {/* {
              loading && <div className={style.loader_position}>
                <img src={Loader} alt="loading" className={style.loader_img} />
              </div>
            } */}

            

            {!loading && !annotationView && !measurementForm && !assessedPicture && errorMessage &&
              <div className="w-100 pl-4 pr-4 text-center">
                <p>
                  <img src={iconDanger} alt="Error" />
                </p>
                <p className="pb-3"> {errorMessage} </p>

                <div className="col-sm-12">

                  <div className="text-center pb-4">
                  <Button
                      type={"reset"}
                      value={"Ok"}
                      classes={{ className: `btn mr-4 ${btn_custom} ${btn_small}` }}
                      onClick={() => { assessModalClose(); }}
                    />
                    {/* <Button
                      type={"reset"}
                      value={"Next"}
                      classes={{ className: `btn mr-4 ${btn_custom} ${btn_small}` }}
                      onClick={() => { setSystemMeasured(false); addMeasurementForm(); }}
                    />
                    <Button
                      type={"reset"}
                      value={"Back"}
                      classes={{ className: `btn ${btn_custom} ${btn_small_cancel}` }}
                      onClick={() => { showMeasurementModal(btnLabel) }}
                    /> */}

                  </div>
                </div>
              </div>
            }
            {
              /*               !loading && !measurementForm && assessedPicture && <div>
                              <div className="mt-5">
                                <img src={assessedPicture} alt="measured" className={style.measured_img} />
                              </div>
                              <Button
                                type={"button"}
                                value={"Next"}
                                classes={{ className: `btn ${style.next_btn_sapce} ${style.btn_custom} ${style.btn_place}` }}
                                id="measurement"
                                onClick={() => addMeasurementForm()}
                              />
                            </div> */
            }
            {
              !loading && measurementForm && <div className="w-100 pl-4 pr-4 mt-2">
                <Form  onSubmit={handleSubmit(doSubmit)}>                     
                  <div>
                    <TextField
                      classes={{ column: `row mb-3`, labelClass: `col-form-label col-5`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} ` }}
                      label={<div>Surface Area (cm{assessmentDetail?.measurement?.isStickerAvailable ===1 &&assessmentDetail?.isWoundWithoutPhoto === 0&&<sup><b>2</b></sup>}): 
                      {(assessmentDetail?.measurement?.isStickerAvailable === 0||assessmentDetail?.isWoundWithoutPhoto === 1) &&  <span className={`${style.tooltip_Edit}`}>                   
                       <img src={infoIcon} alt="info" style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"6px", marginTop: "-1px", color:"#0052cc", visibility: "visible"}}/>
                      {/* <i class="fa-solid fa-exclamation"style={{color : "#ff0000"}} aria-hidden="true"> */}
                      <span class={`${style.tooltiptext}`} style={{color: "#000"}}>Surface area is auto-calculated using length <span style={{color: "#000",fontWeight:"bold"}}>x</span> width formula</span>
                      {/* </i> */}
                    </span>}                        
                  </div>}
                      type='number'
                      name='surfaceArea'
                      defaultValue={surfaceArea!=='empty'?surfaceArea:''}
                      validator={register}
                      //disabled={systemMeasured}
                      disabled = {(assessmentDetail?.isWoundWithoutPhoto === 0 && assessmentDetail?.woundStage==='resolve'&&assessmentDetail?.measurement?.isStickerAvailable===1&&assessmentDetail?.measurement?.isMeasurementResolved===1) ? false : true}  
                     // disabled = {(assessmentDetail?.isWoundWithoutPhoto === 0 && assessmentDetail?.woundStage==='resolve') || (assessmentDetail?.isWoundWithoutPhoto === 1 && assessmentDetail?.woundStage==='resolve') ? false : (assessmentDetail?.woundStage !=='resolve'? true : systemMeasured)}
                    />
                  </div>
                  <div>
                    <TextField
                      classes={{ column: `row mb-3`, labelClass: `col-form-label col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} ` }}
                      label='Length (cm):'
                      type='number'
                      name='length'
                      defaultValue={length}
                      validator={register}
                      disabled={systemMeasured}
                    />
                  </div>
                  <div>
                    <TextField
                      classes={{ column: `row mb-3`, labelClass: `col-form-label col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} ` }}
                      label='Width (cm):'
                      type='number'
                      name='width'
                      defaultValue={width}
                      validator={register}
                      disabled={systemMeasured}
                    />
                  </div>
                  <div>
                    <TextField
                      classes={{ column: `row mb-3`, labelClass: `col-form-label col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} ` }}
                      label='Depth (cm):'
                      type='number'
                      name='depth'
                      defaultValue={assessmentDetail?.measurement?.depth}
                      validator={register}
                    />
                  </div>
                  <div>
                    <TextField
                      classes={{ column: `row`, labelClass: `col-form-label col-5 ${style.label_spec}`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} ` }}
                      label='Undermining (cm):'
                      type='number'
                      name='undermining'
                      defaultValue={assessmentDetail?.measurement?.undermining}
                      validator={register}
                    />
                  </div>
                  <div>
                    <TextField
                      classes={{ column: `row mb-3`, labelClass: `col-form-label col-5 ${style.label_spec} mt-2`, inputColumn: 'col-5 p-0', inputClassName: `${style.input_field} mt-3` }}
                      label='Tunneling (cm):'
                      type='number'
                      name='tunneling'
                      defaultValue={assessmentDetail?.measurement?.tunneling}
                      validator={register}
                    />
                  </div>
                  
                  <div className="text-center">
                    <Button
                      type={"submit"}
                      value={"Save"}
                      onClick={()=>setSaveClicked(true)}
                      classes={{ className: `btn ${style.next_btn_sapce} ${style.btn_custom}` }}
                      id="measurement"
                    />
                  </div>   
                </Form>
              </div>
            }
          </div>
          {
            confirm && <div className={style.confirmation_container}>
              <div className={`${style.confirmation} d-flex align-items-center`}>
                <div>All unsaved changes will be lost. Do you want to leave?</div>
                <div>
                  <Button
                    type="button"
                    value="Yes"
                    classes={{ className: `btn ${style.yes_btn}` }}
                    ariaLabel="yes"
                    onClick={() => assessModalClose()}
                  />
                  <Button
                    type="button"
                    value="No"
                    classes={{ className: `btn ${style.no_btn}` }}
                    ariaLabel="no"
                    onClick={() => confirmationClose()}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    }
  </div>
</>}
  </>)
}

Measurement.propTypes = {
  dispatch: PropTypes.func,
  aboutProps: PropTypes.object
};
Measurement = connect()(withRouter(Measurement));