import React, { useEffect, useState } from 'react'
import { useSelector, connect } from 'react-redux'
import { getHealingTrajectoryAsync, optionTypeAsync } from '../../../app/patientSlice'
import { withRouter } from 'react-router-dom'
import { format } from 'date-fns';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './healingTrajectory.css'
import styles from './healingTrajectory.module.scss'
import { XYPlot, FlexibleWidthXYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LineMarkSeries, Hint } from 'react-vis';//NOSONAR
import 'react-vis/dist/style.css'
import 'react-vis'
import { BWAT, PUSH } from '../../../constants';
import { allNullCheck,groupByList } from '../../../utils';
import { useParams } from 'react-router-dom';
import Loader from '../../../static/images/spinner.svg';	
export function HealingTrajectory({ dispatch, location, fetchedHealingData, detailAssessment }) {


    const { patientId } = useParams();
    const { assessmentId } = useParams();
    // Get woundProgress for getting 'values' for status using 'keys'
    useEffect(() => {
        if (assessmentId) {
            // Dispatching list options
            dispatch(optionTypeAsync('woundprogress'));
        }
    }, [assessmentId, dispatch])

    // Get wound progress options
    const woundProgressOptions = useSelector(state => state.patient.woundProgress);

    // State for storing selected assessment from the array.
    // As we scroll we update this state. Value from this state will get displayed in healing trajectory page.
    const [currentAssess, setcurrentAssess] = useState(0)

    // Set max (for input range field)
    const [max, setMax] = useState()
    // State for default value
    const [defaultId, setDefaultId] = useState(0)

    const dispatchGetCall = () => {
        const payload = {
            patientId,
            assessmentId
        }
        // GET call healing trajectory data 
        dispatch(getHealingTrajectoryAsync(payload))
    }

    // Calling dispatch during location change and initial load

    useEffect(() => {
        dispatchGetCall();
    }, [location])

    useEffect(() => {
        dispatchGetCall();
    }, [])

    // Get healing trajectory data from Redux    
    const [healTraj, setHealTraj] = useState([])


    useEffect(() => {

        if (fetchedHealingData && fetchedHealingData.length > 0) {

            let tempArray = []
            // We are removing the item which don't have 'surface area'
            tempArray = fetchedHealingData && fetchedHealingData.length > 0 && fetchedHealingData.filter((data, i) => {
                if (data.dimensions.surfaceArea.value) {
                    return data;
                }
            })

            // We are reversing the array since we need to display improved wound image first 
            const healTrajTemp = [...tempArray]
           // healTrajTemp.reverse();
            setHealTraj(healTrajTemp)

        }

    }, [fetchedHealingData])


    // Find the length of array and set the length to 'input range's 'max' field
    useEffect(() => {
        healTraj && healTraj.length > 0 && setMax(healTraj.length - 1)
    }, [healTraj])

    // Setting the default value (which is the current assessment id)
    useEffect(() => {
        // healTraj && healTraj.length > 0 && healTraj.map((data, i) => {
        //     if (data.id == assessmentId) {
        //         setDefaultId(i)
        //         setcurrentAssess(i)
        //     }
        // })
        if(healTraj && healTraj.length > 0){
            setDefaultId(healTraj.length-1)
            setcurrentAssess(healTraj.length-1)
        }
    }, [healTraj])

    // Handle on change of slider, we are changing the 'default value' as well as state to update data
    const handleSlider = (e) => {
        setDefaultId(e)
        setcurrentAssess(e)
    }

    // Chart settings

    /********************************************** */
    // HERE I CHANGED to 'healTraj' from 'healingData'
    /********************************************** */
    // Below we are storing surface areas in an array and feeding that to the chart
    const [surfaceArray, setSurfaceArray] = useState([]) // State for storing surface area
    useEffect(() => {
        let tempArray = []
        healTraj && healTraj.length > 0 && healTraj.map((data) => {//NOSONAR
            if(!isNaN(data.dimensions.surfaceArea.value)){
               /* if(healTraj.length ===1){
                    tempArray.push({ x: format(new Date(data?.identifiedDate), 'LL/dd'), y: data?.dimensions?.surfaceArea?.value,date:format(new Date(data?.identifiedDate), 'LL/dd') })
               
                }else{
                    tempArray.push({ x: format(new Date(data?.identifiedDate), 'LL/dd')+' '+format(new Date(data?.identifiedDate), 'h:mm a'), y: data?.dimensions?.surfaceArea?.value,date:format(new Date(data?.identifiedDate), 'LL/dd') })
               
                }*/
                return tempArray.push({ x: format(new Date(data?.measurementDate), 'LL/dd')+' '+format(new Date(data?.measurementDate), 'h:mm a'), y: data?.dimensions?.surfaceArea?.value,date:format(new Date(data?.measurementDate), 'LL/dd') })
               
            }
        })//NOSONAR
        setSurfaceArray(tempArray)
        /*const groupByDate = groupByList(tempArray, "date");
        let finalMakeArray = []
        groupByDate && groupByDate.length > 0 && groupByDate.map((data) => {//NOSONAR
            data.value && data.value.length !== 1 && data.value.map((val) => {//NOSONAR
                finalMakeArray.push({x:val.x,y:val.y})
            })//NOSONAR
            if(data.value && data.value.length===1){
                finalMakeArray.push({x:data.value[0].date,y:data.value[0].y})
            }
        })//NOSONAR
        if(healTraj.length ===1){
           setSurfaceArray(tempArray)
        }else{
            setSurfaceArray(finalMakeArray)
        }*/

    }, [healTraj])


    // Current assessmentd data for chart. The point which we are highlighting. When we 'scrub', this point will move. 
    const [currPoint, setCurrPoint] = useState([])
    useEffect(() => {
        let tempArray = []
       // healTraj && healTraj.length === 1 && !isNaN(healTraj[currentAssess]?.dimensions?.surfaceArea?.value) && tempArray.push({ x: format(new Date(healTraj[currentAssess]?.identifiedDate), 'LL/dd'), y: healTraj[currentAssess]?.dimensions?.surfaceArea?.value,date:format(new Date(healTraj[currentAssess]?.identifiedDate), 'LL/dd') })
        healTraj && healTraj.length > 1 && !isNaN(healTraj[currentAssess]?.dimensions?.surfaceArea?.value) && tempArray.push({ x: format(new Date(healTraj[currentAssess]?.measurementDate), 'LL/dd')+' '+format(new Date(healTraj[currentAssess]?.measurementDate), 'h:mm a'), y: healTraj[currentAssess]?.dimensions?.surfaceArea?.value,date:format(new Date(healTraj[currentAssess]?.measurementDate), 'LL/dd') })
        setCurrPoint(tempArray)
    }, [currentAssess, healTraj])

    // State for hint (tooltip on hover in chart). The below two functions are for handling tooltip
    const [hint, setHint] = useState(null)

    const handleMouseOver = (data) => {
        setHint({ x: data.x, y: data.y })
    }

    const handleMouseOut = () => {
        setHint(null)
    }


    // Finding BWAT or PUSH
    /* BWAT or PUSH score state */
    const [scoreType, setScoreType] = useState('')

    // Get customer number
    const userDetailsAlt = useSelector(state => state.user.userDetails);


    // Set BWAT. If BWAT is YES, it will display some extra fields.
    useEffect(() => {
        if (userDetailsAlt) {
            setScoreType(userDetailsAlt?.customerSettings?.woundAssessmentScore)
        }
    }, [userDetailsAlt])
    // Set maximum value for y axis 
    const [yAxis, setYAxis] = useState(25)
    // We are maping through the surface area array and finding maximum surface area and adding '3' to that.
    useEffect(() => {
        let values = []
        surfaceArray && surfaceArray.length > 0 && surfaceArray.forEach((data) => {
            values.push(data.y)
        })
        setYAxis(Math.max(...values) + 3)
    }, [surfaceArray])

    const woundHealingPictures = healTraj && healTraj.length > 0 && healTraj.map((data) =>
        <img src={data.woundPicture} alt="dd" className="d-none" />
    ) //NOSONAR

    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    return (<>
        {!tinyDelay ?<div className={`${'loader'} col-12`}>
        <img src={Loader} alt="loading" />
      </div>:<>
        <div>
            {woundHealingPictures}
            {healTraj && healTraj.length === 0 ?
                <div className="p-3">
                    <h5>No Data Available.</h5>
                </div>
                :
                <div className="row no-gutters">
                    <div className="col-4">
                        <div style={{ position: "relative" }} className={styles.image_block}>
                            <img src={healTraj[currentAssess]?.woundPicture} alt="" className="img-fluid" loading="lazy" />
                            {/* HTML range slider for sliding image and data in right 
                            When we slide we update the state 'currentAssess'. 
                            This will change current assessment.
                        */}
                            {healTraj && healTraj.length !== 1 && <div className={styles.rc_outer}>
                                <Slider min={0} max={max} onChange={handleSlider} value={defaultId} className={styles.rc_style_fix} />
                            </div>}


                        </div>
                    </div>
                    <div className={`col ${styles.mesurement_blk}`}>

                        <div className="col-12 mb-1">
                            <div className="row no-gutters">
                                <div className="col-4 p-0">
                                    <label className="d-block mb-2">{healTraj[currentAssess]?.photoTakenon && format(new Date(healTraj[currentAssess]?.photoTakenon), 'MMMM dd, yyyy hh:mm aa')}</label>
                                    <span className={styles.surfaceText}>{healTraj[currentAssess]?.dimensions?.surfaceArea?.value !== 'Unknown' ? <><span>{healTraj[currentAssess]?.dimensions?.surfaceArea?.value}</span> <span>cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}</span></> : 'Unknown'}</span>

                                    <div className="mt-2">
                                        <label>Status:</label>
                                        <span>{healTraj[currentAssess]?.woundStage ?
                                            woundProgressOptions && woundProgressOptions.length > 0 && woundProgressOptions?.map((data) => {
                                                if (data.key === healTraj[currentAssess]?.woundStage) {
                                                    return data.value
                                                }
                                            })
                                            :
                                            'Unknown'
                                        }</span>
                                    </div>

                                </div>
                                <div className="col-8 p-0 d-flex justify-content-end">
                                    <div className={styles.traj_bwat}>
                                        <span className="d-block">{healTraj[currentAssess]?.score ? healTraj[currentAssess]?.score : 0}</span>
                                        <label>{(scoreType&&scoreType === BWAT) ? 'BWAT':(scoreType&&scoreType === 'push')? 'PUSH' : '-'}</label>
                                    </div>

                                    <div className={`${styles.traj_bwat_clouser}`}>
                                        <div className={`text-center`}>% Change <br/>
                                            {healTraj[currentAssess]?.changeInCm ? 
                                                (healTraj[currentAssess]?.changeInCm !== "Unknown" ? 
                                                <span className={healTraj[currentAssess]?.changeInCm !== "0" ? (healTraj[currentAssess]?.changeInCm < "0" ? `${styles.closureColor} ${styles.closureColor_green} mt-2` : `${styles.closureColor} ${styles.closureColor_red} mt-2`) : `${styles.closureColor} ${styles.closureColor_grey} mt-2`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healTraj[currentAssess]?.changeInCm !== "0" && (healTraj[currentAssess]?.changeInCm < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healTraj[currentAssess]?.changeInCm.replace( /^\D+/g, ''))} cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}
                                                    {/* {assessment?.changeInCm} cm<sup>2</sup> */}
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey} mt-2`}>0 cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}</span>                                                
                                                ) : "-"
                                            }
                                            {healTraj[currentAssess]?.closurePercentage ? 
                                                (healTraj[currentAssess]?.closurePercentage !== "Unknown" ? 
                                                <span className={healTraj[currentAssess]?.closurePercentage !== "0" ? (healTraj[currentAssess]?.closurePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`: `${styles.closureColor} ${styles.closureColor_red} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`) : `${styles.closureColor} ${styles.closureColor_grey} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`}>
                                                {/* <span className={healTraj[currentAssess]?.closurePercentage <= "0" ? `${styles.closureColor} ${styles.closureColor_green} pl-2`: `${styles.closureColor} ${styles.closureColor_red} pl-2`}> */}
                                                    {`(`}{healTraj[currentAssess]?.closurePercentage.replace( /^\D+/g, '')} %{`)`}
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey}`}>Unknown</span>
                                                ) : "-"  
                                            }                                       
                                        </div>  
                                    </div>                                  
                                    

                                    {/* Closure */}
                                    {/* <div className={styles.traj_bwat}>                                        
                                        <label>% Change</label>                                        
                                        <span className="d-block">{healTraj[currentAssess]?.closurePercentage ? healTraj[currentAssess]?.closurePercentage : 0}</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 mt-4 mb-4">
                            <div className="row no-gutters">
                                {/* // Chart */}
                                {/* <Line data={data} options={options} height={100}/> */}
                                {surfaceArray && surfaceArray.length > 0 ?
                                <FlexibleWidthXYPlot height={250+yAxis*2}  xType='ordinal' yDomain={[0, yAxis]}>
                                    <VerticalGridLines />
                                    <HorizontalGridLines />
                                    <XAxis title="Date" />
                                    <YAxis title="Surface Area" />
                                   
                                    <LineMarkSeries
                                        data={surfaceArray}
                                        size={3}
                                        stroke={'#387ac4'}
                                        fill={'#387ac4'}
                                        onValueMouseOver={handleMouseOver}
                                        onValueMouseOut={handleMouseOut}
                                    />
                                   
                                     {/* <LineMarkSeries
                                        data={[{"x":"01/27 ","y":"76.51"}]}
                                        size={3}
                                        stroke={'#387ac4'}
                                        fill={'#387ac4'}
                                        onValueMouseOver={handleMouseOver}
                                        onValueMouseOut={handleMouseOut}
                                    /> */}
                                    
                                    <LineMarkSeries
                                        data={currPoint}
                                        size={7}
                                        fill={'#387ac4'}
                                        onValueMouseOver={handleMouseOver}
                                        onValueMouseOut={handleMouseOut}
                                    />

                                </FlexibleWidthXYPlot> : 'No Chart Avaliable.'}
                            </div>
                        </div>


                        <div className="col-12 mb-4">
                            <div className="row no-gutters">
                                <div className="col-6">
                                    <p className={styles.traj_heading}>Dimensions</p>
                                    <div className="row no-gutters">
                                        <div className={`col-12 pl-0  ${styles.measure_surface}`}>
                                            <label>Surface Area: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.surfaceArea?.value) ? <><span>{healTraj[currentAssess]?.dimensions?.surfaceArea?.value}</span><span> cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}</span></> : 'Unknown'}</span>
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.measure_length}`}>
                                            <label>Length: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.length?.value) ? healTraj[currentAssess]?.dimensions?.length?.value + ' cm' : 'Unknown'}</span>
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.measure_width}`}>
                                            <label>Width: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.width?.value) ? healTraj[currentAssess]?.dimensions?.width?.value + ' cm' : 'Unknown'}</span>
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.measure_items}`}>
                                            <label>Depth: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.depth?.value) ? healTraj[currentAssess]?.dimensions?.depth?.value + ' cm' : 'Unknown'}</span>
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.measure_items}`}>
                                            <label>Tunneling: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.tunneling?.value) ? healTraj[currentAssess]?.dimensions?.tunneling?.value + ' cm' : 'Unknown'}</span>
                                        </div>
                                        <div className={`col-12 pl-0   ${styles.measure_items}`}>
                                            <label>Undermining: </label>
                                            <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.undermining?.value) ? healTraj[currentAssess]?.dimensions?.undermining?.value + ' cm' : 'Unknown'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className={styles.traj_heading}>% Change</p>
                                    <div className={`row no-gutters`}>
                                        <div className={`col-12 pl-0  ${styles.change_surface}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage) ? 
                                                    <span className={healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        :
                                                    <span className={`${styles.closureColor}`}>Unknown</span>   
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.surfaceArea?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.change_length}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.length?.differencePercentage) ? 
                                                    <span className={healTraj[currentAssess]?.dimensions?.length?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.length?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.length?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.length?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.length?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        :
                                                    <span className={`${styles.closureColor}`}>Unknown</span>  
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.length?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.length?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                        <div className={`col-12 pl-0   ${styles.change_width}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.width?.differencePercentage) ? 
                                                    <span className={healTraj[currentAssess]?.dimensions?.width?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.width?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.width?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.width?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.width?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        :
                                                    <span className={`${styles.closureColor}`}>Unknown</span>  
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.width?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.width?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                        <div className={`col-12 pl-0 ${styles.change_items}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.depth?.differencePercentage) ? 
                                                    <span className={healTraj[currentAssess]?.dimensions?.depth?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.depth?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.depth?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.depth?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.depth?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        :
                                                    <span className={`${styles.closureColor}`}>Unknown</span>  
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.depth?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.depth?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.change_items}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage) ? 
                                                    <span className={healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        :
                                                    <span className={`${styles.closureColor}`}>Unknown</span>   
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.tunneling?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                        <div className={`col-12 pl-0  ${styles.change_items}`}>
                                            <span className={` ${styles.ch_bold}`}>
                                                {allNullCheck(healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage) ?                                                     
                                                    <span className={healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage !== "0" ? (healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                        <span style={{paddingRight: "4px"}}>                                             
                                                            <i className={healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage !== "0" && (healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                        </span>
                                                        {Number(healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                    </span>
                                                        : 
                                                    <span className={`${styles.closureColor}`}>Unknown</span>                                                      
                                                }
                                            </span>
                                            {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage) ? healTraj[currentAssess]?.dimensions?.undermining?.differencePercentage + '%' : 'Unknown'}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        <div className="col-12 mb-4">
                            <div className="row no-gutters">
                                <div className="col-6">
                                    <div className="col-12 pl-0">
                                        <p className={styles.traj_heading}>Healing Progression Rate (HPR)</p>
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <label>Worst Tissue Type (WTT): </label>
                                        <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.value) ? 
                                            healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.value !== 'N/A' ? healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.value : 'N/A' 
                                            : 
                                            'Unknown'}</span>
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <label>Surface Area Value (SAV): </label>
                                        <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.value) ? healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.value : 'Unknown'}</span>
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <label>Intact Value (IV): </label>
                                        <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.intactValue?.value) ? healTraj[currentAssess]?.healingProgressionRate?.intactValue?.value : 'Unknown'}</span>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="col-12 pl-0">
                                        <p className={styles.traj_heading}>% Change</p>
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <span className={` ${styles.ch_bold}`}>
                                            {allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage) ? 
                                                <span className={healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage !== "0" ? (healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage !== "0" && (healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                </span>
                                                    :
                                                <span className={`${styles.closureColor}`}>Unknown</span>   
                                            }
                                        </span>
                                        
                                        {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage) ? healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage !== 'N/A' 
                                        ? healTraj[currentAssess]?.healingProgressionRate?.worstTissueType?.differencePercentage + '%' : 'N/A' 
                                        : 'Unknown'}
                                        </span> */}
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <span className={` ${styles.ch_bold}`}>
                                            {allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage) ? 
                                                <span className={healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== "0" ? (healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`) : `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== "0" && (healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                </span>
                                                    :
                                                <span className={`${styles.closureColor}`}>Unknown</span>   
                                            }
                                        </span>
                                        {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage) ? healTraj[currentAssess]?.healingProgressionRate?.surfaceAreaValue?.differencePercentage + '%' : 'Unknown'}</span> */}
                                    </div>
                                    <div className="col-12 pl-0 mb-2 mt-2">
                                        <span className={` ${styles.ch_bold}`}>
                                            {allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage) ? 
                                                <span className={healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage !== "0" ? (healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage < "0" ? `${styles.closureColor} ${styles.closureColor_green}` : `${styles.closureColor} ${styles.closureColor_red}`): `${styles.closureColor} ${styles.closureColor_grey}`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage !== "0" && (healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage.replace( /^\D+/g, ''))} %                                                            
                                                </span>
                                                    :
                                                <span className={`${styles.closureColor}`}>Unknown</span>   
                                            }
                                        </span>
                                        {/* <span className={` ${styles.ch_bold}`}>{allNullCheck(healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage) ? healTraj[currentAssess]?.healingProgressionRate?.intactValue?.differencePercentage + '%' : 'Unknown'}</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            }
        </div>
        </>}
        </>
    )
}

function mapStateToProps(state) {
    return {
        fetchedHealingData: state.patient.healingTrajectoryData
    }
}

HealingTrajectory = connect(mapStateToProps)(withRouter(HealingTrajectory));