import React, { useState, useEffect } from 'react'
import { PatientBasicInfo, PatientMoreInfo, WoundAssessment, RiskAssessment, Status as PatientStatus } from '../components/core/Patients';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { DetailView } from '../components';
import { ListView } from '../components/secondary/ListView';
import {
  patientDetailsAsync,
  setDetailViewData,
  getCallBackListAsync,
  setPatients,
  optionTypeAsync,
  checkCustomerAsync,
  resetFacilitiesData,
  uploadFile,
  patientAssessmentAsync,
  patientAssessmentHistoryAsync,
  setUploadStatus,
  uploadingCheckAsync,
  patientUpdateAsync,
  deletePatientListAsync,
  patientRiskAssessmentAsync,
  getFacilityDataAsyncForPatients,
  setFacilityAndCustomerNumLastVal,
  setWoundAssessment,
  setAssessmentHistory,
  setPatientListLastVal,
  setResMsgState,
  setMergeNavigateSuccess,
  setPatientLoad
} from '../app/patientSlice'
import style from './patients.module.scss'
import { LIST_VIEW_LIMIT, PATIENT, DELETE_ASSESSMENT, DELETE_PARENT_ASSESSMENT, DELETE_CHILD_ASSESSMENT, DELETE_HISTORY_ASSESSMENT, SUCCESS_STATUS } from '../constants';
import { deleteAsync, deleteParentChildAsync } from '../app/appSlice';
import { facilityListViewData, getActivePageData, getSearchValueData,getFacilityLabel } from '../utils';
import { ActivityLog } from '../components/core/Patients/activityLog';
export let Patients = ({ dispatch, history, location }) => {

  /* Check url for patient id */
  const { patientId } = useParams()
  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);

  //Add Patient button Disable/Enable based on Customer's API
  const customerData = useSelector(state => state.customer.detailViewData)
  const [manualPatientEnable, setManualPatientEnable] = useState(false)
  useEffect(()=>{
    if(customerData?.integretiontab?.manualPatientCreation === 1){
      setManualPatientEnable(true)
    }
    else{
      setManualPatientEnable(false)
    }
  },[customerData])
  
  const userDetails = useSelector(state => state.user);
 
  const patientListLastVal = useSelector(state => state.patient.patientListLastVal);
  
  useEffect(() => {
    if (userDetails?.role !== "MedlineAdministrator"&&userDetails?.customerSettings?.ne1Wms === 0) {  
      history.push('/learnMore'); 
    }
  if(userDetails && userDetails.customerSettings &&userDetails?.customerSettings?.ne1Wms === 0){
    if(userDetails?.role==="ExternalStandardUser" || userDetails?.role==="ExternalAdministrator" || userDetails.role === "ExternalNE1WoundExpert" || userDetails.role === "ExternalNE1Review" || userDetails.role === "ExternalNE1Assesment"){
      history.push('/learnMore')
    }  
  }
  }, [userDetails]) 

  const [selectedFacility, setSelectedFacility] = useState(patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'')
  const patientsList = useSelector(state => state.patient.patients);
  const accessError = useSelector(state => state.app.accessError)

  const [customerNumber, setCustomerNumber] = useState(patientListLastVal?.customerNumber?patientListLastVal?.customerNumber:userDetails?.customerNumber);

  useEffect(() => {
    dispatch(setResMsgState({}));
    const defaultFacilityNumber = {}
    defaultFacilityNumber['customerNumber'] = customerNumber;
    dispatch(getFacilityDataAsyncForPatients(defaultFacilityNumber));
    if (!isAdminView) {
      dispatch(checkCustomerAsync(defaultFacilityNumber, history))
    } else {
      setSelectedFacility(patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'')
    }
    if (document.getElementById('facility')) {
      document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
    }
    if (document.getElementById('search')) {
      document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
    }
  }, [dispatch])
  /* Get state with the Individual patient data */
  const patientData = useSelector(state => state.patient.detailViewData)  
  const getFacilities = useSelector(state => state.patient.newFacilitiesData);
  const [facilityNumberOption, setFacilityNumber] = useState('');
  
  const getAssessments = (patientId) => dispatch(patientAssessmentAsync(patientId));

  const getAssessmentHistory = (assessmentId) =>
    dispatch(patientAssessmentHistoryAsync(patientId, assessmentId));

  //Parent Level Delete Assessment
  const deleteAssessment = (assessmentId) =>{ 
    const payload = {      
      'patientId': patientId,
      'assessmentId': `${assessmentId}`
    }
    const deleteURL = DELETE_PARENT_ASSESSMENT;
    dispatch(setWoundAssessment({}));
    dispatch(deleteParentChildAsync(payload, deleteURL, patientAssessmentAsync))
  }

  const assessmentHistoryData = useSelector(state => state.patient.assessmentHistory);
  const [childLevelDelete, setChildLevelDelete] = useState(false)

  //Child Level Delete Assessment
  const deleteAssessmentChildLevel= (assessmentId) => {   
    const finalArrValue = parseInt(assessmentHistoryData?.followuphistory?.map(data=>data?.assessments?.filter(item=>item.id!==assessmentId))?.map(item=>item[0]?.id))
    const payload = {      
      'patientId': patientId,
      'assessmentId': assessmentId
    }
    const deleteURL = DELETE_CHILD_ASSESSMENT;   
    dispatch(setAssessmentHistory({}));  
    dispatch(deleteParentChildAsync(payload, deleteURL, patientAssessmentAsync, patientAssessmentHistoryAsync, finalArrValue))
    setChildLevelDelete(true)
  }
 
  // const deleteSuccessAPI = (getAssessmentId) =>{
  //   const finalArrValue = parseInt(assessmentHistoryData?.followuphistory?.map(data=>data?.assessments?.filter(item=>item.id!==getAssessmentId))?.map(item=>item[0]?.id))
  //   getAssessmentHistory(finalArrValue) 
  // }

  const deleteHistoryAssessment = (assessmentId, historyAssessmentId) =>
    dispatch(deleteAsync(DELETE_HISTORY_ASSESSMENT, patientId, assessmentId, historyAssessmentId));

  /* Dispatch update patient data */

  const updatePatientData = (data) => dispatch(patientUpdateAsync(data))
  const getRiskAssessments = () => dispatch(patientRiskAssessmentAsync(patientId));
  const mergeWoundProcessSuccess = useSelector(state => state.patient.mergeNavigateSuccess)

  /* Tabs object */
  const adminTabList = [
    {
      name: 'Basic Info',
      content: <PatientBasicInfo patientData={patientData} updatePatientData={updatePatientData} />
    },
    {
      name: 'More Info',
      content: <PatientMoreInfo patientData={patientData} updatePatientData={updatePatientData} />
    },
    {
      name: 'Wound Assessment',
      content: <WoundAssessment heading={patientData} patientData={patientData} getAssessments={getAssessments}
        getAssessmentHistory={getAssessmentHistory}
        deleteAssessment={deleteAssessment} deleteHistoryAssessment={deleteHistoryAssessment} dispatch={dispatch} history={history} deleteAssessmentChildLevel={deleteAssessmentChildLevel} childLevelDelete={childLevelDelete} setChildLevelDelete={setChildLevelDelete}/>,
      className: `${style.tab_bgremover}`
    },
    {
      name: 'Risk Assessment',
      content: <RiskAssessment heading={patientData} patientData={patientData} getRiskAssessments={getRiskAssessments} />
    },
    {
      name: 'ADT',
      content: <PatientStatus patientData={patientData} />
    },
  ];

  const tabList = [
    {
      name: 'Wound Assessment',
      content: <WoundAssessment patientData={patientData} getAssessments={getAssessments}
        getAssessmentHistory={getAssessmentHistory}
        deleteAssessment={deleteAssessment} deleteHistoryAssessment={deleteHistoryAssessment} dispatch={dispatch} />,
      className: `${style.tab_bgremover}`
    },
    {
      name: 'Risk Assessment',
      content: <RiskAssessment patientData={patientData} getRiskAssessments={getRiskAssessments} />
    },
    {
      name: 'Activity Log',
      content: <ActivityLog patientData={patientData}/>
    },
  ]; 

  const [detailsRow, SelectedRow] = useState(false)
  const [searchKey, setSearchKey] = useState(patientListLastVal?.searchKey?patientListLastVal?.searchKey:'')
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0)
  let [sortType, setSortType] = useState('')
  /* Get Patients Details */

  useEffect(() => {
    setCustomerNumber(patientListLastVal?.customerNumber?patientListLastVal?.customerNumber:userDetails?.customerNumber)
  }, [location])

  useEffect(() => {
    let optionData = [];
  (getFacilities?.data) && getFacilities?.data?.facilities.map((value, index) => {//NOSONAR
    optionData.push({ value: getFacilityLabel(value), key: value.facilityNumber })
    })//NOSONAR
    setFacilityNumber(optionData)
     if (document.getElementById('facility')) {
       document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
     }
     if (document.getElementById('search')) {
       document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
     }
    }, [getFacilities])
  const getPatientList = (facilityNumber,searchKeys) => {

    const listPayLoad = {};
    listPayLoad['url'] = PATIENT
    listPayLoad['method'] = 'Get'
    listPayLoad['queryParam'] = {search:searchKeys===''?'': patientListLastVal?.searchKey?patientListLastVal?.searchKey:'', facilityNumber:facilityNumber===''?'':facilityNumber?facilityNumber:patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:selectedFacility,customerNumber: patientListLastVal?.customerNumber?patientListLastVal?.customerNumber:userDetails?.customerNumber, offSet: offSet, limit: LIST_VIEW_LIMIT, pagetype: isAdminView ? "patientadmin" : "patient",sort: (sortType)?sortType:''}
    listPayLoad['setMethod'] = setPatients
    dispatch(getCallBackListAsync(listPayLoad))
    dispatch(setPatientLoad(true))
     if (document.getElementById('facility')) {
       document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
     }
     if (document.getElementById('search')) {
       document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
     }
  }


  useEffect(() => {
    setSortType('')
    setSearchKey('')
    setSelectedFacility(patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'')
    setOffSet(0)
    setActivePage(1)
    if (document.getElementById('facility')) {
      document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
    }
    if (document.getElementById('search')) {
      document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
    }
    if(customerNumber){
      const setLast={...patientListLastVal,searchKey:patientListLastVal?.searchKey,customerNumber:customerNumber }
      dispatch(setPatientListLastVal(setLast))
    }
  }, [dispatch, isAdminView, customerNumber, getFacilities]);

  const showFixedColumn = !!patientsList?.data && !!patientsList?.data?.patients?.length
  let columns = [];
  (isAdminView) ?
    columns = [
      {
        Header: 'MRN # ',
        accessor: 'mrn',
        disableSortBy: false,
        fixedColumn: showFixedColumn,
        fixedWidth: '200px'
      },
      {
        Header: 'First name',
        accessor: 'firstName',
        fixedColumn: showFixedColumn,
        fixedWidth: '250px'
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
        fixedColumn: showFixedColumn,
        fixedWidth: '255px'
      },
      {
        Header: 'Gender',
        accessor: 'gender'
      },
      {
        Header: 'DOB',
        accessor: 'dob',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Primary group',
        accessor: 'customerNumber',
      },
      {
        Header: 'Facility/Location',
        accessor: 'facilityName'
      },
      {
        Header: 'Admission date',
        accessor: 'admissionDate'
      },
      {
        Header: 'Discharge date',
        accessor: 'dischargeDate'
      },
      {
        Header: 'Phone',
        accessor: 'phone'
      }
      ,
      {
        Header: 'Address',
        accessor: 'addressLines'
      },
      {
        Header: 'City',
        accessor: 'city'
      },
      {
        Header: 'State',
        accessor: 'state'
      },
      {
        Header: 'Zip',
        accessor: 'zipCode'
      }
    ]
    :
    columns = [
     /* {
        Header: 'NE1 ID',
        accessor: 'ne1Id',
        disableSortBy: false,
        fixedColumn: showFixedColumn
      },*/
      {
        Header: 'MRN # ',
        accessor: 'mrn',
        disableSortBy: false,
        fixedColumn: showFixedColumn,
        fixedWidth: '200px'
      },
      {
        Header: 'First name',
        accessor: 'firstName',
        fixedColumn: showFixedColumn,
        fixedWidth: '200px'
      },
      {
        Header: 'Last name',
        accessor: 'lastName',
        fixedColumn: showFixedColumn,
        fixedWidth: '205px'
      },
      {
        Header: 'Gender',
        accessor: 'gender'
      },
      {
        Header: 'DOB',
        accessor: 'dob',
      },
      {
        Header: 'Age',
        accessor: 'age'
      },
      {
        Header: 'Primary group',
        accessor: 'customerNumber',
      },
      {
        Header: 'Facility/Location',
        accessor: 'facilityName'
      },
      {
        Header: 'Admission date',
        accessor: 'admissionDate'
      },
      {
        Header: 'Discharge date',
        accessor: 'dischargeDate'
      }
    ]


  /* If url changes, hide the detail view */
  useEffect(() => {
    SelectedRow(false)
    return () => {
      SelectedRow(false)
    };
  }, [location])

  /* If no patient id in url, dispatch empty data */
  useEffect(() => {
    if (!patientId) {
      dispatch(setDetailViewData({}))
    }
  }, [patientId, dispatch])


  /* If patient id exists display detail view */
  useEffect(() => {

    if (patientId) {
      //dispatch(optionTypeAsync('reasonforvisit'));
     // dispatch(optionTypeAsync('insurancepayer'));
      //dispatch(optionTypeAsync('comorbidcondition'));
      /* Fetch data using parameter from url */
      dispatch(patientDetailsAsync(patientId, history))
      /* Flag to display detail view page */
      SelectedRow(true)
      dispatch(optionTypeAsync('historycause'));
      /*dispatch(optionTypeAsync('humananatomy'));
      
      dispatch(optionTypeAsync('pihistory'));
      dispatch(optionTypeAsync('woundage'));
      dispatch(optionTypeAsync('phototype'));
      dispatch(optionTypeAsync('documentationtype'));*/
    }    
  }, [patientId, dispatch])

  const getSelectedRowData = (data) => {
    /* If user clicks the row redirect to detail view page by attaching MRN to the url */
    if (data) {
      isAdminView ? history.push('/admin/patients/' + data.ne1Id) : history.push('/patients/' + data.ne1Id);
    }
  }
  const handleBack = () => {
    SelectedRow(false)
  }
  // search list
  const getSearchValue = (event) => {
    event.preventDefault();
    if (event && event.keyCode === 13) {
      if (event && event.target.value !== '') {
        setOffSet(0)
        setActivePage(1)
        setSearchKey(event.target.value)
        const setLast={...patientListLastVal,searchKey: event?.target?.value }
        dispatch(setPatientListLastVal(setLast))
        if (document.getElementById('facility')) {
          dispatch(setPatientLoad(true))
          getSearchValueData(event, PATIENT, customerNumber, offSet, LIST_VIEW_LIMIT, setPatients, setSearchKey, dispatch, getCallBackListAsync, isAdminView, selectedFacility,sortType)
        }
      } else {
        const setLast={...patientListLastVal,searchKey:'' }
        dispatch(setPatientListLastVal(setLast))
        setOffSet(0)
        setActivePage(1)
        setSearchKey('')
        setSortType('')
        getPatientList('','');
      }
    }
  }
  const getSearchValueCust = (event) => {
    event.preventDefault();
    if (event && event.keyCode === 13) {
      if (event &&event?.target&& event?.target?.value !== '') {
        const setLast={...patientListLastVal,customerNumber: event?.target?.value }
        dispatch(setPatientListLastVal(setLast))
        /*const defaultFacilityNumber = {}
        defaultFacilityNumber['customerNumber'] = event?.target?.value;
        dispatch(getFacilityDataAsyncForPatients(defaultFacilityNumber));*/
        dispatch(getFacilityDataAsyncForPatients({ customerNumber: event?.target?.value},setCustomerNumber));
      } else {
        /*const defaultFacilityNumber = {}
        defaultFacilityNumber['customerNumber'] =userDetails?.customerNumber;
        dispatch(getFacilityDataAsyncForPatients(defaultFacilityNumber));*/
        const setLast={...patientListLastVal,searchKey:'',customerNumber:userDetails?.customerNumber,selectedFacility:'' }
        dispatch(setPatientListLastVal(setLast))
        dispatch(getFacilityDataAsyncForPatients({ customerNumber: userDetails?.customerNumber},setCustomerNumber));
        setOffSet(0)
        setActivePage(1)
        setSearchKey('')
        setSortType('')
        const listPayLoad = {};
    listPayLoad['url'] = PATIENT
    listPayLoad['method'] = 'Get'
    listPayLoad['queryParam'] = {search:'', facilityNumber:'',customerNumber: userDetails?.customerNumber, offSet: offSet, limit: LIST_VIEW_LIMIT, pagetype: isAdminView ? "patientadmin" : "patient",sort: (sortType)?sortType:''}
    listPayLoad['setMethod'] = setPatients
    dispatch(getCallBackListAsync(listPayLoad))
      }
    }
  }
  // pagination
  const getActivePage = (data) => {
    dispatch(setPatientLoad(true))
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    getActivePageData(data, setActivePage, PATIENT, customerNumber, LIST_VIEW_LIMIT, setPatients, dispatch, getCallBackListAsync, searchKey, isAdminView, selectedFacility,sortType);
  }
  
  // sorting
  const sortingPayLoad = {};
  sortingPayLoad['url'] = PATIENT
  sortingPayLoad['method'] = 'Get'
  sortingPayLoad['queryParam'] = { facilityNumber:patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:selectedFacility,customerNumber: customerNumber, offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, search: (searchKey) ? searchKey : '', pagetype: isAdminView ? "patientadmin" : "patient", sort: (sortType)?sortType:''}
  if (document.getElementById('facility')) {
    document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
  }
  if (document.getElementById('search')) {
    document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
  }
  sortingPayLoad['setMethod'] = setPatients
  sortingPayLoad['setSortType'] = setSortType
  sortingPayLoad['asyncMethod'] = getCallBackListAsync

  /* Get Patients Details Facility Number*/
  const facilityListView = (value) => {
    dispatch(setPatientLoad(true))
    setSortType('')
    setSearchKey('')
    setOffSet(0)
    setActivePage(1)
    if (document.getElementById('search')) {
      document.getElementById('search').value = '';
    }
    if (value || value !== true || value !== false) {
      const setLast={...patientListLastVal,selectedFacility: value,searchKey:'' }
      dispatch(setPatientListLastVal(setLast))
      setSelectedFacility(value)
      getPatientList(value,'');
     
    }
}
  const deleteList = (data) => {
    dispatch(deletePatientListAsync(data))
  }

  const deleteStatus = useSelector(state => state.app.deleteStatus)

  useEffect(() => {
    if (deleteStatus) {
      if (deleteStatus.status === SUCCESS_STATUS) {
        getPatientList();
        if(patientId){
          getAssessments(patientId);
        }
        
      }
    }
  }, [deleteStatus])

  useEffect(() => {
    if (document.getElementById('facility')) {
      document.getElementById('facility').value = patientListLastVal?.selectedFacility?patientListLastVal?.selectedFacility:'';
    }
    if (document.getElementById('search')) {
      document.getElementById('search').value = patientListLastVal?.searchKey?patientListLastVal?.searchKey:'';
    }
    getPatientList();

  }, [])  

  return (
    <div>
      {!detailsRow && <ListView
        columns={columns}
        data={patientsList.data && patientsList.data.patients}
        metaData={patientsList.data && patientsList.data.metadata}
        sorting={true}
        rowSelect={isAdminView}
        getSelectedRowData={getSelectedRowData}
        detailView={true}
        pageRangeDisplayed={5}
        searchPlaceholder={'MRN#, First Name, Last Name'}
        getSearchValue={(e) => getSearchValue(e)}
        detailsRow={detailsRow}
        activePage={activePage}
        getActivePage={getActivePage}
        listSorting={sortingPayLoad}
        listType={"Patients"}
        facilityListView={facilityListView}
        fileUplodad={uploadFile}
        showFixedColumn={showFixedColumn}
        setUploadStatus={setUploadStatus}
        uploadingCheckAsync={uploadingCheckAsync}
        searchKey={(searchKey) ? searchKey : ''}
        deleteListView={deleteList}
        selectedFacility={selectedFacility}
        getPatientList={getPatientList}
        offset={offSet}
        accessError={accessError}
        otherHeading={true}
        setCustomerNumber={setCustomerNumber}
        sortType={sortType?sortType:''}
        facilityNumberOption={facilityNumberOption}
        setFacilityNumber={setFacilityNumber}
        patientListLastVal={patientListLastVal}
        manualPatientEnable={manualPatientEnable}
        getSearchValueCust={getSearchValueCust}
      />}
      {detailsRow && <DetailView
        title="Patient Management"
        dispatch={dispatch}
        tabList={isAdminView ? adminTabList : tabList}
        subHeading={isAdminView ? 'Patient Management' : ''}
        defaultIndex={isAdminView && mergeWoundProcessSuccess ? 2 : 0}
        detailsRow={detailsRow}
        patientData={patientData}
        status={patientData?.statusInfo?.status}
        handleBack={handleBack}
        otherHeading={true}
        backLink='/admin/patients'
        customerSettings={(userDetails && userDetails.customerSettings) ? userDetails.customerSettings : ''}
        detailType={"Patients"}
        history={history}
      />}
    </div>
  )
}

Patients.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
}

Patients = connect()(withRouter(Patients));
