import React, { useState, useEffect } from 'react';
import { getDressingChangeFrequency, updateDressingChangeFrequency } from '../../../app/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './dressingChangeFrequency.module.scss';
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import { Button, Form } from '../../primary';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { signedLockedAccess } from '../../../utils';
import { assessmentRbacChecker } from '../../../utils/rbac';
import { Confirmation } from '../../secondary/Confirmation/confirmation'   
import warning from '../../../static/images/icon-check-danger.png'; 
import { toast } from 'react-toastify';
export const DressingChangeFrequency = ({ interventionId, assessmentDetails }) => {
	 // Edit state
   const [editable, setEditable] = useState(false)
   const [formChangesConfirm, setFormChangesConfirm] = useState(false)
   const [confirmationModal, setConfirmationModal] = useState(false)
   const [clickCancel, setClickCancel] = useState(false)
   const confirmationClose = () => {
       setConfirmationModal(false);
       setClickCancel(false)
   }
     const yesConfirmationModalClick = () => {
       setConfirmationModal(false)
       setEditable(false)
       setFormChangesConfirm(false)
       setClickCancel(false)
     }
     useEffect(() => {
         if(editable){
             if(formChangesConfirm&&clickCancel){
                 setConfirmationModal(true);
               }else if(clickCancel&&formChangesConfirm===false){
                 setClickCancel(false)
                 setConfirmationModal(false);
                 setEditable(false)
               }
         }
     }, [formChangesConfirm,clickCancel,editable])
  const { handleSubmit } = useForm();
  const dressChange = useSelector(state => state.patient.dressChangefrequency);
  const [newfrequence, setNewFrequence] = useState(dressChange);
  const [numValidator, setNumValidator] = useState(null);
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);
  const handleEdit = () => {
    setFormChangesConfirm(false)
    setClickCancel(false)
    setEditable(true);
  }
  const handleCancel = () => {
      toast.dismiss('success1')
    setClickCancel(true)
    //setEditable(false)
  }
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const payload = {
    patientId: patientId,
    assessmentId: assessmentId,
    interventionId: interventionId
  }
  useEffect(() => {
      toast.dismiss('success1')
    dispatch(getDressingChangeFrequency(payload))
  }, [dispatch])
  useEffect(() => {
    setNewFrequence(dressChange);
  }, [dressChange])
  const doSubmit = () => {
    setEditable(false);
    const payloadData = {
      patientId: patientId,
      assessmentId: assessmentId,
      interventionId: interventionId,
      updateData: newfrequence
    }
    dispatch(updateDressingChangeFrequency(payloadData));
  }
  const EditIcon = () => {
    return (
      !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && <div className={`${styles.edit_icon}`}>
        <span className={`float-sm-right ${styles.tab_edit} handleEdit`} onClick={handleEdit} >
          <img src={editIcon} alt="edit" />{EDIT}
        </span>
      </div>
    )
  }
  const getFrequency = (e, ind, type) => {
    if (isNaN(e.target.value)) {
      setNumValidator(ind)
      e.target.value = null
      setTimeout(() => {
        setNumValidator(null)
      }, 2000)
    }
    else {
      setNumValidator(null)
    }
    let data = { ...newfrequence.data[type] }
    let catType = [...data.productCategory]
    let cat = { ...catType[ind] }
    cat.frequence = e.target.value;
    if( cat&&cat.frequenceType===null){
      cat.frequenceType='Days'
    }
    catType.splice(ind, 1, cat)
    data.productCategory = catType
    let update = { ...newfrequence.data }
    update[type] = data;
    setNewFrequence({ data: update });
  }
  const getFrequencyType = (e, ind, type) => {
    let data = { ...newfrequence.data[type] }
    let catType = [...data.productCategory]
    let cat = { ...catType[ind] }
    cat.frequenceType = e.target.value;
    catType.splice(ind, 1, cat)
    data.productCategory = catType
    let update = { ...newfrequence.data }
    update[type] = data;
    setNewFrequence({ data: update });
  }

  return (<>
     {
      confirmationModal && <Confirmation
        onClose={() => confirmationClose()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">All progress in this section will be lost. Are you sure want to cancel?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${styles.yes_btn}` }}
              onClick={() => yesConfirmationModalClick()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${styles.no_btn}` }}
              onClick={() => confirmationClose()}
            />
          </div>
        </div>
      </Confirmation>
    }
    <div className={`col-md-12 ${styles.treatment_container}`}>
      {dressChange?.data?.primary?.productCategory?.length === 0 && dressChange?.data?.secondary?.productCategory?.length === 0 ? <div className={styles.nodata_container}>There are no Primary / Secondary Dressing selected for this assessment</div> :
        <Form setFormChangesConfirm={setFormChangesConfirm} formChanges={true} className="col-12 p-0" onSubmit={handleSubmit(doSubmit)}>
          <div className={`col-12 ${styles.col_spec} d-flex mb-2`} >
            {dressChange?.data?.primary?.productCategory?.length > 0 ? !editable && hideButtons ? <EditIcon /> : "" : ""}
            <label className={`col-4 p-0`}>Primary Dressing:</label>
            <div className="col-8 mb-4 pb-1 p-0">
              {
                dressChange?.data?.primary?.productCategory && dressChange?.data?.primary?.productCategory.length > 0 && dressChange?.data?.primary?.productCategory?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={styles.edit_wrapper}>
                        <div className={`${styles.cat}`}>{item.productFamilyDesc}</div>
                        <div>{`${item.materialDescription} ${!editable ? item.frequence != null && item.frequence != undefined && item.frequence !== '' ? ` - ${item.frequence}` : '' : ''} 
                        ${!editable ?
                            item?.frequence !== null && item.frequence !== undefined && item.frequence !== '' ?
                              item?.frequenceType === null ? 'Hrs' : item?.frequenceType : '' : ''
                          }`}</div>
                      </div>
                      {editable ?
                        <>
                          <div className={`${styles.recom_text}`}>Recommended : {item.recommended}</div>
                          <div className={`${styles.treatment_text}`}>Dressing Change Frequency</div>
                          <div className={`d-flex`}>
                            <div className={`${styles.treatment_input}`}>
                              <input type="text" className="getFrequency" onBlur={(e) => getFrequency(e, index, 'primary')} defaultValue={item.frequence ? item.frequence : ''} />
                            </div>
                            <div className={`${styles.treatment_select}`}>
                              <select className="getFrequencyType" onChange={(e) => getFrequencyType(e, index, 'primary')} defaultValue={item.frequenceType ? item.frequenceType : ''}>
                                <option>Days</option>
                                <option>Hrs</option>
                              </select>
                            </div>
                          </div>
                          <div className={`d-flex ${styles.error_message}`}>
                            {numValidator === index ? "please Select a number" : null}
                          </div>
                        </> : ''
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`col-12 ${styles.col_spec} d-flex mb-2`} >
            {dressChange?.data?.secondary?.productCategory?.length > 0 ? !editable && hideButtons ? <EditIcon /> : "" : ""} 
            <label className={`col-4 p-0`}>Secondary Dressing:</label>
            <div className="col-8 p-0">
              {dressChange?.data?.secondary?.productCategory && dressChange?.data?.secondary?.productCategory.length > 0 && dressChange?.data?.secondary?.productCategory?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={styles.edit_wrapper}>
                      <div className={`${styles.cat}`} key={index}>{item.productFamilyDesc} </div>
                      <div>{`${item.materialDescription} ${!editable ? item.frequence != null && item.frequence != undefined && item.frequence !== '' ? ` - ${item.frequence}` : '' : ''} 
                        ${!editable ?
                          item?.frequence !== null && item.frequence != undefined && item.frequence !== '' ?
                            item?.frequenceType === null ? 'Hrs' : item?.frequenceType : '' : ''
                        }`}</div>
                    </div>
                    {editable ? <>
                      <div className={`${styles.recom_text}`}>Recommended : {item.recommended}</div>
                      <div className={`${styles.treatment_text}`}>Dressing Change Frequency</div>
                      <div className={`d-flex`}>
                        <div className={`${styles.treatment_input}`}>
                          <input type="text" className="getFrequencySec" onBlur={(e) => getFrequency(e, index, 'secondary')} defaultValue={item.frequence ? item.frequence : ''} />
                        </div>
                        <div className={`${styles.treatment_select}`}>
                          <select className="getFrequencyTypeSec" onChange={(e) => getFrequencyType(e, index, 'secondary')} defaultValue={item.frequenceType ? item.frequenceType : ''}>
                            <option>Days</option>
                            <option>Hrs</option>
                          </select>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.error_message}`}>
                        {numValidator === index ? "please Select a number" : null}
                      </div>
                    </> : ''
                    }
                  </div>
                )
              })}
            </div>
          </div>
          {editable && hideButtons ?
            <div className="col-12 pt-2 mt-5 pl-1">
              <div className={`offset-4`}>
                <Button
                  classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                  type="submit"
                  id={SUBMIT_ID}
                >
                  {SAVE_BTN}
                </Button>
                <Button
                 type="button"
                  classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handlecancel` }}
                  id={CANCEL_ID}
                  onClick={handleCancel}
                >
                  {CANCEL_BTN}
                </Button>
              </div>
            </div> : ''
          }
        </Form>}
    </div>
    </>
  )
}
