import { Service } from "../service";
import { createSlice } from "@reduxjs/toolkit";
import {
  SUCCESS_STATUS,
  ERROR_STATUS,
  UPLOAD_CHECK,
  PATIENT,
  UPLOAD_PATIENT_FILE,
  GET_ASSESSMENTS,
  OPTION_TYPES,
  GET_ASSESSMENT_HISTORY,
  PATIENT_GENDER,
  DELETE_PATIENTS,
  WOUND_IMAGE_MEASUREMENT,
  LIST_VIEW_LIMIT,
  CUSTOM_FORM_VIEW,
  GET_RISK_ASSESSMENTS,
  GET_AUTO_MEASUREMENT_STATUS,
  SIGNIN_TOKEN,
  API_KEY,
  BASE_URL,
  PATIENTVIEWPASTWOUND,
  PATIENTVIEWDELETE,
  REGISTER_PATIENT_FOR_MOBILE,
  WOUND_WORKLIST_COUNT,
  GET_TOGGLESTATUS
} from "../constants";
import { setError, setLoading, deleteAsync, setAccessError } from "./appSlice";
import { constructEndpoint } from "../utils/commonHelper";
import { format } from "date-fns";
import { SimplePersistence } from "../utils";
import { updateUserDetailsAsync } from "./userSlice";
const storage = new SimplePersistence();
const { request } = Service;

export const patientSlice = createSlice({
  name: "patient",
  initialState: {
    detailViewData: {}, // individual patient details
    facilitiesData: {}, // Facility(s)
    newFacilitiesData: {},
    adminView: null,
    simpleNewFacilitiesData: {},
    uploading: false, // Someone else is uploading?
    uploadStatus: null, // Upload success or failed?
    reasonForVisit: [],
    comorbidConditions: [],
    insurancePayer: [],
    woundAssessments: [],
    assessmentHistory: {},
    temporaryPassword: "", // Setting temporary password - register for mobile app
    createAssessmentData: {
      // Setting create assessment data
      isSubmitted: false,
    },
    createAssessmentId: null, // State to store accessment ID after creating new one
    emrTypes: [],
    woundMeasure: {},
    editState: false,
    wristBandError: null,
    measurementData: {},
    charStatus: [], // State to handle "tick" and "needs review"
    patients: {},
    resMsgState: "test",
    humanAnatomy: [],
    historyCause: [],
    piHistory: {},
    woundAge: [],
    photoType: [],
    documentProvidedBy: {},
    detailAssessment: {},
    tissueTypesData: {}, // State to store tissue type data
    tissueOverrideStatus:{},// State to store tissue type override toggle status
    touchViewImages: {}, // State to store touch view images    
    assessmentNotes: {},
    tissueTemperature: {},
    tissueFirmness: {},
    painLevel: {},
    blanchTest: {},
    exudateType: [],
    exudateAmount: [],
    woundEdges: {},
    skinColorAroundWound: {},
    induration: {},
    edema: {},
    granulationTissue: {},
    epithelialization: {},
    historyCauseFormData: {},
    woundFrequencyType: [],
    woundClassificationOptions: [],
    woundProgress: [],
    newNotes: {},
    forms: {},
    signLockActions: [],
    checkLock: "",
    viewForms: {},
    reEvalAssessmentData: {},
    healingTrajectoryData: [],
    riskAssessments: [],
    bradenSensoryPerception: {},
    bradenMoisture: {},
    bradenActivity: {},
    bradenMobility: {},
    bradenNutrition: {},
    bradenFrictionShear: {},
    nortonMobility: {},
    nortonActivity: {},
    nortonMentalCondition: {},
    nortonPhysicalCondition: {},
    nortonIncontinence: {},
    riskAssessmentQuestions: {},
    riskAssessmentResponseScore: "",
    autoMeasurementStatus: "",
    interventionListOptions: {},
    treatmentListIntervention: {},
    dressingAppearance: {},
    dressingappearanceListoptions: [],
    modalities: {},
    additionalCare: {},
    patchAdditionalCare: [],
    additionalCareListoptions: [],
    woundCleanser: {},
    modalitiesListoptions: [],
    skinProtectantListOptions: [],
    primaryDressingGetData: [],
    secondaryDressingGetData: {},
    compressionGetData: {},
    primaryDressingListOptions: [],
    secondaryDressingListOptions: [],
    compressionListOptions: [],
    debridement: {},
    debridementListoptions: {},
    debridementperformedby: {},
    nonexcisionalapproach: {},
    surgicaldebridementprocedure: [],
    depthofdebridement: {},
    instrumentsused: {},
    necroticsubcutaneoustissue: {},
    necrotictissue: {},
    postdebridementbleedingamount: {},
    woundCleanserGetData: {},
    woundCleanserListOptions: [],
    skinProtectantGetData: {},
    techniqueusedOptions: {},
    notapplicableOptions: {},
    pastWoundPhotoData: {},
    activityLogGetData: [],
    clinician: [],
    woundType: [],
    clinicianandproductcategory: {},
    tissuetypeandClassification: {},
    biReportsList: {},
    pdfData: {},
    respMsg: {},
    dressChangefrequency: {},
    faclityOptionData: {},
    otherPageFacilityCheck: "",
    editStateTemPass: false,
    biReportSummaryCsv: {},
    biReportDetailCsv: {},
    interventionLoader: false,
    lastVal: {},
    facilityAndCustomerNumLastVal: {},
    periwoundCondition:[],
    workListRecordsCount:{},
    isMessageShowForSecondayDressing:{},
    isSnakeAlgorithms:'',
    bIReportPayload:{},
    isPdfGeneration:true,
    pdfFile:{},
    treatMentBiListOption:{},
    woundAssessmentsAnatomyList: [],
    anatomyDataByAssessID:{},
    causeDataByAssessID:{},
    historyDataByAssessID:{},
    tissueTypeDataByAssessID:{},
    touchViewDataByAssessID:{},
    woundClosureOptions:[],
    classificationDataByAssessID:{},
    woundprogressDataByAssessID:{},
    ne1getWounAssessmentDetail:{},
    ne1configsignwithoutmindataset:{},
    characteristicTabs:[],
    charTabsLoad:true,
    isHealingGraphLoad:true,
    mergeWoundSuccess: null,
    mergeNavigateSuccess: false,
    childAsItsOwnParent: false,
    patientLoad:false
  },
  reducers: {
    /* Data for detailed patient view */
    setDetailViewData: (state, action) => {
      state.detailViewData = action.payload;
    },

    setLastVal: (state, action) => {
      state.lastVal = action.payload;
    },

    setFacilityAndCustomerNumLastVal: (state, action) => {
      state.facilityAndCustomerNumLastVal = action.payload;
    },

    setAdminView: (state, action) => {
      state.adminView = action.payload;
    },
    /* Reducer for "someone else is uploading?" */
    setUploading: (state, action) => {
      state.uploading = action.payload;
    },
    /* CSV file upload success or failed */
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload;
    },
    /* Fetch facilities */
    setFacilitiesData: (state, action) => {
      state.facilitiesData = action.payload;
    },

    setNewFacilitiesData: (state, action) => {
      state.newFacilitiesData = action.payload;
    },
    setSimpleNewFacilitiesData: (state, action) => {
      state.simpleNewFacilitiesData = action.payload;
    },
    /* Reason for visit */
    setReasonForVisit: (state, action) => {
      state.reasonForVisit = action.payload;
    },
    setSortingPatients: (state, action) => {
      state.patients = action.payload;
    },
    resetSortingPatients: (state, action) => {
      state.patients = {};
    },
    setComorbidConditions: (state, action) => {
      state.comorbidConditions = action.payload;
    },
    setInsurancePayer: (state, action) => {
      state.insurancePayer = action.payload;
    },

    setWoundAssessment: (state, action) => {
      if (action.payload === "reset") {
        state.woundAssessments = [];
      } else {
        state.woundAssessments = action.payload;
      }
    },
    setAssessmentHistory: (state, action) => {
      state.assessmentHistory = action.payload;
    },
    setTemporaryPassword: (state, action) => {
      state.temporaryPassword = action.payload;
    },
    resetFacilitiesData: (state, action) => {
      state.facilitiesData = {};
    },
    setEmrTypes: (state, action) => {
      state.emrTypes = action.payload;
    },
    /* Setting wound assessment data. "createAssessment" page */
    setCreateAssessmentData: (state, action) => {
      /* If the payload is reset, remove all data */
      if (action.payload === "reset") {
        state.createAssessmentData = { isSubmitted: false };
      } else {
        state.createAssessmentData = {
          ...state.createAssessmentData,
          ...action.payload,
        };
      }
    },
    /* Set data id for new wound assessment */
    setCreateAssessmentId: (state, action) => {
      state.createAssessmentId = action.payload;
    },
    //otherPageFacilityCheck
    setOtherPageFacilityCheck: (state, action) => {
      state.otherPageFacilityCheck = action.payload;
    },

    setEditState: (state, action) => {
      state.editState = action.payload;
    },
    setWristBandError: (state, action) => {
      state.wristBandError = action.payload;
    },
    setMeasureId: (state, action) => {
      state.woundMeasure = action.payload;
    },
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    resetPatients: (state, action) => {
      state.patients = {};
    },
    setClinicianandproductcategory: (state, action) => {
      state.clinicianandproductcategory = action.payload;
    },
    setTissuetypeandClassification: (state, action) => {
      state.tissuetypeandClassification = action.payload;
    },
    setBiReortsList: (state, action) => {
      state.biReportsList = action.payload;
    },

    setBiReportSummaryCsv: (state, action) => {
      state.biReportSummaryCsv = action.payload;
    },

    setBiReportDetailCsv: (state, action) => {
      state.biReportDetailCsv = action.payload;
    },

    setWoundMeasurementData: (state, action) => {
      state.measurementData = action.payload;
    },
    setCharStatus: (state, action) => {
      state.charStatus = action.payload;
    },
    setResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    resetResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    setHumananatomy: (state, action) => {
      state.humanAnatomy = action.payload;
    },
    setHistoryCause: (state, action) => {
      state.historyCause = action.payload;
    },
    setPiHistory: (state, action) => {
      state.piHistory = action.payload;
    },
    setWoundAge: (state, action) => {
      state.woundAge = action.payload;
    },
    setPhotoType: (state, action) => {
      state.photoType = action.payload;
    },
    setDocumentProvidedBy: (state, action) => {
      state.documentProvidedBy = action.payload;
    },
    setDetailAssessment: (state, action) => {
      state.detailAssessment = action.payload;
    },
    setTissueTypesData: (state, action) => {
      // Reducer for tissue type data
      state.tissueTypesData = action.payload;
    },
    setTissueOverrideStatus: (state, action) => {
      state.tissueOverrideStatus = action.payload
    },
    setTouchViewImages: (state, action) => {
      // Reducer for tissue type images
      state.touchViewImages = action.payload;
    },
    setAssessmentNotes: (state, action) => {
      state.assessmentNotes = action.payload;
    },
    setTissueTemp: (state, action) => {
      state.tissueTemperature = action.payload;
    },
    setTissueFirmness: (state, action) => {
      state.tissueFirmness = action.payload;
    },
    setPainLevel: (state, action) => {
      state.painLevel = action.payload;
    },
    setBlanchTest: (state, action) => {
      state.blanchTest = action.payload;
    },
    setExudateType: (state, action) => {
      state.exudateType = action.payload;
    },
    setExudateAmount: (state, action) => {
      state.exudateAmount = action.payload;
    },
    setWoundEdges: (state, action) => {
      state.woundEdges = action.payload;
    },
    setSkinColorAroundWound: (state, action) => {
      state.skinColorAroundWound = action.payload;
    },
    setInduration: (state, action) => {
      state.induration = action.payload;
    },
    setEdema: (state, action) => {
      state.edema = action.payload;
    },
    setGranulationTissue: (state, action) => {
      state.granulationTissue = action.payload;
    },
    setEpithelialization: (state, action) => {
      state.epithelialization = action.payload;
    },
    setHistoryCauseFormData: (state, action) => {
      state.historyCauseFormData = action.payload;
    },
    setDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
    setWoundFrequencyType: (state, action) => {
      state.woundFrequencyType = action.payload;
    },
    setWoundClassificationOptions: (state, action) => {
      state.woundClassificationOptions = action.payload;
    },
    setWoundProgress: (state, action) => {
      state.woundProgress = action.payload;
    },
    setPeriWoundCondition: (state, action) => {
      state.periwoundCondition = action.payload;
    },
    setNewLayout: (state, action) => {
      state.newNotes = action.payload;
    },
    setSignLockActions: (state, action) => {
      state.signLockActions = action.payload;
    },
    setCheckLock: (state, action) => {
      state.checkLock = action.payload;
    },
    setviewForms: (state, action) => {
      state.viewForms = action.payload;
    },
    setReEvalAssessmentData: (state, action) => {
      state.reEvalAssessmentData = action.payload;
    },
    setHealingTrajectoryData: (state, action) => {
      state.healingTrajectoryData = [...action.payload];
    },
    setRiskAssessment: (state, action) => {
      state.riskAssessments = action.payload;
    },
    setBradenSensoryPerception: (state, action) => {
      state.bradenSensoryPerception = action.payload;
    },
    setBradenMoisture: (state, action) => {
      state.bradenMoisture = action.payload;
    },
    setBradenActivity: (state, action) => {
      state.bradenActivity = action.payload;
    },
    setBradenMobility: (state, action) => {
      state.bradenMobility = action.payload;
    },
    setBradenNutrition: (state, action) => {
      state.bradenNutrition = action.payload;
    },

    setBradenFrictionShear: (state, action) => {
      state.bradenFrictionShear = action.payload;
    },
    setNortonPhysicalCondition: (state, action) => {
      state.nortonPhysicalCondition = action.payload;
    },
    setNortonMentalCondition: (state, action) => {
      state.nortonMentalCondition = action.payload;
    },
    setNortonActivity: (state, action) => {
      state.nortonActivity = action.payload;
    },
    setNortonMobility: (state, action) => {
      state.nortonMobility = action.payload;
    },
    setNortonIncontinence: (state, action) => {
      state.nortonIncontinence = action.payload;
    },
    setRiskAssessmentQuestions: (state, action) => {
      state.riskAssessmentQuestions = action.payload;
    },
    setRiskAssessmentResponseScore: (state, action) => {
      state.riskAssessmentResponseScore = action.payload;
    },
    setAutoMeasurementStatus: (state, action) => {
      state.autoMeasurementStatus = action.payload;
    },
    setInterventionListOptions: (state, action) => {
      state.interventionListOptions = action.payload;
    },
    setTreatmentListIntervention: (state, action) => {
      state.treatmentListIntervention = action.payload;
    },
    setDressingAppearance: (state, action) => {
      state.dressingAppearance = action.payload;
    },
    setDressingappearanceListoptions: (state, action) => {
      state.dressingappearanceListoptions = action.payload;
    },
    setModalities: (state, action) => {
      state.modalities = action.payload;
    },
    setAdditionalCare: (state, action) => {
      state.additionalCare = action.payload;
    },
    setPatchAdditionalCare: (state, action) => {
      state.patchAdditionalCare = action.payload;
    },
    setAdditionalCareListoptions: (state, action) => {
      state.additionalCareListoptions = action.payload;
    },
    setWoundCleanser: (state, action) => {
      state.woundCleanser = action.payload;
    },
    setModalitiesListoptions: (state, action) => {
      state.modalitiesListoptions = action.payload;
    },
    setSkinProtectantListOptions: (state, action) => {
      state.skinProtectantListOptions = action.payload;
    },
    setPrimaryDressingGetData: (state, action) => {
      state.primaryDressingGetData = action.payload;
    },
    setSecondaryDressingGetData: (state, action) => {
      state.secondaryDressingGetData = action.payload;
    },
    setCompressionGetData: (state, action) => {
      state.compressionGetData = action.payload;
    },
    setPrimaryDressingListOptions: (state, action) => {
      state.primaryDressingListOptions = action.payload;
    },
    setSecondaryDressingListOptions: (state, action) => {
      state.secondaryDressingListOptions = action.payload;
    },
    setCompressionListOptions: (state, action) => {
      state.compressionListOptions = action.payload;
    },
    setDebridement: (state, action) => {
      state.debridement = action.payload;
    },
    setdebridementListoptions: (state, action) => {
      state.debridementListoptions = action.payload;
    },
    setDebridementperformedby: (state, action) => {
      state.debridementperformedby = action.payload;
    },
    setNonexcisionalapproach: (state, action) => {
      state.nonexcisionalapproach = action.payload;
    },
    setSurgicaldebridementprocedure: (state, action) => {
      state.surgicaldebridementprocedure = action.payload;
    },
    setDepthofdebridement: (state, action) => {
      state.depthofdebridement = action.payload;
    },
    setInstrumentsused: (state, action) => {
      state.instrumentsused = action.payload;
    },

    setnecroticsubcutaneoustissue: (state, action) => {
      state.necroticsubcutaneoustissue = action.payload;
    },
    setNecrotictissue: (state, action) => {
      state.necrotictissue = action.payload;
    },
    setPostdebridementbleedingamount: (state, action) => {
      state.postdebridementbleedingamount = action.payload;
    },

    setWoundCleanserGetData: (state, action) => {
      state.woundCleanserGetData = action.payload;
    },

    setWoundCleanserListOptions: (state, action) => {
      state.woundCleanserListOptions = action.payload;
    },

    setSkinProtectantGetData: (state, action) => {
      state.skinProtectantGetData = action.payload;
    },
    setTechniqueusedOptions: (state, action) => {
      state.techniqueusedOptions = action.payload;
    },
    setNotapplicableOptions: (state, action) => {
      state.notapplicableOptions = action.payload;
    },
    setWoundPhotoData: (state, action) => {
      state.pastWoundPhotoData = action.payload;
    },
    setActivityLogGetData: (state, action) => {
      state.activityLogGetData = action.payload;
    },
    setClinicianData: (state, action) => {
      state.clinician = action.payload;
    },
    setWoundType: (state, action) => {
      state.woundType = action.payload;
    },
    setPdfData: (state, action) => {
      state.pdfData = action.payload;
    },
    setRespMsg: (state, action) => {
      state.respMsg = action.payload;
    },
    setdressChangeFrequency: (state, action) => {
      state.dressChangefrequency = action.payload;
    },
    setAllFacilityOptions: (state, action) => {
      state.faclityOptionData = action.payload;
    },
    setEditStateTemPass: (state, action) => {
      state.editStateTemPass = action.payload;
    },
    setInterventionLoader: (state, action) => {
      state.interventionLoader = action.payload;
    },
    setWorkListRecordsCount: (state, action) => {
      state.workListRecordsCount = action.payload;
    },
    setIsMessageShowForSecondayDressing: (state, action) => {
      state.isMessageShowForSecondayDressing = action.payload;
    },
    setPatientListLastVal: (state, action) => {
      state.patientListLastVal = action.payload;
    },
    setIsSnakeAlgorithms: (state, action) => {
      state.isSnakeAlgorithms = action.payload;
    },
    setBIReportPayload: (state, action) => {
      state.bIReportPayload = action.payload;
    },
    setIsPdfGeneration: (state, action) => {
      state.isPdfGeneration = action.payload;
    },
    setPdfFile: (state, action) => {
      state.pdfFile = action.payload;
    },
  setTreatMentBiListOption: (state, action) => {
  state.treatMentBiListOption = action.payload;
},
setWoundAssessmentsAnatomyList: (state, action) => {
  if (action.payload === "reset") {
    state.woundAssessmentsAnatomyList = [];
  } else {
    state.woundAssessmentsAnatomyList = action.payload;
  }
},
setAnatomyDataByAssessID: (state, action) => {
  state.anatomyDataByAssessID = action.payload;
},

setCauseDataByAssessID: (state, action) => {
  state.causeDataByAssessID = action.payload;
},
setHistoryDataByAssessID: (state, action) => {
  state.historyDataByAssessID = action.payload;
},
setTissueTypeDataByAssessID: (state, action) => {
  state.tissueTypeDataByAssessID = action.payload;
},
setTouchViewDataByAssessID: (state, action) => {
  state.touchViewDataByAssessID = action.payload;
},
setWoundClosureOptions: (state, action) => {
  state.woundClosureOptions = action.payload;
},
setClassificationDataByAssessID: (state, action) => {
  state.classificationDataByAssessID = action.payload;
},
setWoundprogressDataByAssessID: (state, action) => {
  state.woundprogressDataByAssessID = action.payload;
},
setNe1getWounAssessmentDetail: (state, action) => {
  state.ne1getWounAssessmentDetail = action.payload;
},
setNe1configsignwithoutmindataset: (state, action) => {
  state.ne1configsignwithoutmindataset = action.payload;
},
setCharacteristicTabs: (state, action) => {
  state.characteristicTabs = action.payload;
},
setCharTabsLoad: (state, action) => {
  state.charTabsLoad = action.payload;
},
setIsHealingGraphLoad: (state, action) => {
  state.isHealingGraphLoad = action.payload;
},
setMergeWoundSuccess: (state, action) => {
  state.mergeWoundSuccess = action.payload;
},
setMergeNavigateSuccess: (state, action) => {
  state.mergeNavigateSuccess = action.payload;
},
setChildAsItsOwnParent: (state, action) => {
  state.childAsItsOwnParent = action.payload;
},
setPatientLoad: (state, action) => {
  state.patientLoad = action.payload;
}
  },
});

export const {
  setOtherPageFacilityCheck,
  setFacilityAndCustomerNumLastVal,
  setLastVal,
  setDetailViewData,
  setAdminView,
  setFileSelected,
  setUploading,
  setUploadStatus,
  setFacilitiesData,
  setNewFacilitiesData,
  setSimpleNewFacilitiesData,
  setReasonForVisit,
  setWoundAssessment,
  setInsurancePayer,
  setComorbidConditions,
  setAssessmentHistory,
  setPatients,
  resetPatients,
  setTemporaryPassword,
  resetFacilitiesData,
  setCreateAssessmentData,
  setCreateAssessmentId,
  setEmrTypes,
  setMeasureId,
  setSortingPatients,
  resetSortingPatients,
  setEditState,
  setWristBandError,
  setWoundMeasurementData,
  setCharStatus,
  setResMsgState,
  setHumananatomy,
  setHistoryCause,
  setPiHistory,
  setWoundAge,
  setPhotoType,
  setDocumentProvidedBy,
  setDetailAssessment,
  setTissueTypesData,
  setTissueOverrideStatus,
  setTouchViewImages,
  setAssessmentNotes,
  setTissueTemp,
  setTissueFirmness,
  setPainLevel,
  setBlanchTest,
  setExudateType,
  setExudateAmount,
  setHistoryCauseFormData,
  setDeviceType,
  setWoundFrequencyType,
  resetResMsgState,
  setWoundEdges,
  setSkinColorAroundWound,
  setInduration,
  setEdema,
  setGranulationTissue,
  setEpithelialization,
  setWoundClassificationOptions,
  setWoundProgress,
  setNewLayout,
  setSignLockActions,
  setCheckLock,
  setviewForms,
  setReEvalAssessmentData,
  setHealingTrajectoryData,
  setRiskAssessment,
  setBradenSensoryPerception,
  setBradenMoisture,
  setBradenActivity,
  setBradenMobility,
  setBradenNutrition,
  setBradenFrictionShear,
  setNortonPhysicalCondition,
  setNortonMentalCondition,
  setNortonActivity,
  setNortonMobility,
  setNortonIncontinence,
  setRiskAssessmentQuestions,
  setRiskAssessmentResponseScore,
  setAutoMeasurementStatus,
  setInterventionListOptions,
  setTreatmentListIntervention,
  setDressingAppearance,
  setDressingappearanceListoptions,
  setModalities,
  setAdditionalCare,
  setPatchAdditionalCare,
  setAdditionalCareListoptions,
  setWoundCleanser,
  setModalitiesListoptions,
  setPrimaryDressingGetData,
  setSecondaryDressingGetData,
  setCompressionGetData,
  setPrimaryDressingListOptions,
  setSecondaryDressingListOptions,
  setCompressionListOptions,
  setDebridement,
  setdebridementListoptions,
  setDebridementperformedby,
  setNonexcisionalapproach,
  setSurgicaldebridementprocedure,
  setDepthofdebridement,
  setInstrumentsused,
  setnecroticsubcutaneoustissue,
  setNecrotictissue,
  setPostdebridementbleedingamount,
  setWoundCleanserGetData,
  setWoundCleanserListOptions,
  setSkinProtectantGetData,
  setSkinProtectantListOptions,
  setTechniqueusedOptions,
  setNotapplicableOptions,
  setWoundPhotoData,
  setActivityLogGetData,
  setClinicianData,
  setWoundType,
  setClinicianandproductcategory,
  setTissuetypeandClassification,
  setBiReortsList,
  setPdfData,
  setRespMsg,
  setdressChangeFrequency,
  setAllFacilityOptions,
  setEditStateTemPass,
  setBiReportSummaryCsv,
  setBiReportDetailCsv,
  setInterventionLoader,
  setLastCustomerNumber,
  setLastFacilitySelected,
  setPeriWoundCondition,
  setWorkListRecordsCount,
  setIsMessageShowForSecondayDressing,
  setPatientListLastVal,
  setIsSnakeAlgorithms,
  setBIReportPayload,
  setIsPdfGeneration,
  setPdfFile,
  setTreatMentBiListOption,
  setWoundAssessmentsAnatomyList,
  setAnatomyDataByAssessID,
  setCauseDataByAssessID,
  setHistoryDataByAssessID,
  setTissueTypeDataByAssessID,
  setTouchViewDataByAssessID,
  setWoundClosureOptions,
  setClassificationDataByAssessID,
  setWoundprogressDataByAssessID,
  setNe1getWounAssessmentDetail,
  setNe1configsignwithoutmindataset,
  setCharacteristicTabs,
  setCharTabsLoad,
  setIsHealingGraphLoad,
  setMergeWoundSuccess,
  setMergeNavigateSuccess,
  setChildAsItsOwnParent,
  setPatientLoad,
} = patientSlice.actions;

// Patients details
export const patientDetailsAsync = (payload, history) => async (dispatch) => {
  dispatch(setDetailViewData({}));
  try {
    dispatch(setLoading(true));
    const data = await request(`${'/patientsv1'}/${payload}`, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (data && data.data && data.data.facilityNumber) {
        //dispatch(updateUserDetailsAsync(data.data.facilityNumber));
        dispatch(setDetailViewData(data.data));
      }
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj?.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404
    ) {
      window.location = "/unAuthorized";
    }
  } finally {
    dispatch(setLoading(false));
  }
};

/**************** */
/* PATIENT UPDATE */
/**************** */

export const patientUpdateAsync = (payload, chkActionTab) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PATIENT}/${payload.ne1Id}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(patientDetailsAsync(payload.ne1Id));
      dispatch(setEditState(data.status));
    }
  } catch (error) {
    /* If failued, set error */
    dispatch(setEditState("error"));

    const obj = { ...error };
    if (chkActionTab === "ADTSTATUS") {
      dispatch(setEditState(obj));
    }
    if (obj?.errorMessage?.match("Wristband")) {
      dispatch(setWristBandError(obj?.errorMessage));
    }
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// =============================== ccs
export const patientAssessmentAsync = (payload, history) => async (    
  dispatch
) => {
  try {
    dispatch(setDetailAssessment({}));
   //dispatch(setLoading(true));
    const resp = await request(constructEndpoint(GET_ASSESSMENTS, payload), {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setWoundAssessment(resp.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const patientAssessmentHistoryAsync = (
  patientId,
  assessmentId
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(
      constructEndpoint(GET_ASSESSMENT_HISTORY, patientId, assessmentId),
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setAssessmentHistory(resp.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// =========================

// Upload checker - If someone else is uploading right now?
export const uploadingCheckAsync = (payload) => async (dispatch) => {
  try {
    const resp = await request(UPLOAD_CHECK, {
      method: "GET",
    });
    /* If someone is uploading, set uploading state to true */
    if (resp && resp.status === ERROR_STATUS) {
      dispatch(setUploading(true));
    }
    /* Else set it as false */
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setUploading(false));
    }
  } catch (error) {
    const obj = { ...error };
    if (obj.errorMessage === "Import in progress") {
      dispatch(setUploading(true));
    }
  }
};

// Upload CSV file
export const uploadFile = ({ payload, signal, getPatientList }) => async (
  dispatch
) => {
  const signin_token = storage.getItem(SIGNIN_TOKEN);
  try {
    dispatch(setLoading(true));
    const resp = await fetch(`${BASE_URL}${UPLOAD_PATIENT_FILE}`, {
      method: "POST",
      headers: new Headers({
        Authorization: signin_token ? `Bearer ${signin_token}` : "",
        "medline-authorization": signin_token ? signin_token : "",
        "Ocp-Apim-Subscription-Key": API_KEY,
      }),
      body: payload,
      /* Signal for aborting */
      signal: signal,
    });
    const data = await resp.json();
    /* If success */
    if (data) {
      dispatch(setUploadStatus(data.data));
      getPatientList();
    }
    if (resp.status === 405) {
      const res = { status: "error", message: data.message, accessStatus: 405 };
      dispatch(setUploadStatus(res));
    }
    if(resp.status===409){
      const res = { status: 'error', message: data.message,statuscode:409}
      dispatch(setUploadStatus(res))
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    const res = { status: "error", message: obj.errorMessage };
    dispatch(setUploadStatus(res));
  } finally {
    dispatch(setLoading(false));
  }
};

// Raja
export const checkCustomerAsync = (payload, history) => async (dispatch) => {
  const customerNumber = payload?.customerNumber;
  if(customerNumber){
  try {
    dispatch(setLoading(true));
    const data = await request(
     // "/listoptions/facility?customerNumber=" + customerNumber,
     "/getpatientcustomerfacilities?customerNumber=" + customerNumber,
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setFacilitiesData(data));
      payload.setMethod && dispatch(payload.setMethod(data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
}
};

// Simple new facility data

export const getSimpleNewFacilityDataAsync = (payload) => async (dispatch) => {
  // const customerNumber = payload.customerNumber
  try {
    dispatch(setLoading(true));
    let queryStr = "/listoptions/facility";
    if (payload?.customerNumber) {
      queryStr =
       // "/listoptions/facility?customerNumber=" + payload.customerNumber;
        "/getpatientcustomerfacilities?customerNumber=" + payload.customerNumber;
    }

    const data = await request(`${queryStr}`, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setSimpleNewFacilitiesData(data));
      // payload.setMethod && dispatch(payload.setMethod(data))
    }
  } catch (error) {
    const obj = { ...error };
    // dispatch(setNewFacilitiesData([]));
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getNewFacilityDataAsync = (payload,setErrorMsg) => async (dispatch) => {
  // const customerNumber = payload.customerNumber
  try {
    dispatch(setLoading(true));
    let queryStr = "/listoptions/facility";
    if (payload?.customerNumber) {
      queryStr =
       // "/listoptions/facility?customerNumber=" + payload.customerNumber;
       "/getpatientcustomerfacilities?customerNumber=" + payload.customerNumber;
    }

    const data = await request(`${queryStr}`, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setNewFacilitiesData(data));
      dispatch(setError(''));
      // payload.setMethod && dispatch(payload.setMethod(data))
    }
  } catch (error) {
    const obj = { ...error };
     dispatch(setNewFacilitiesData([]));
     if(setErrorMsg){
      setErrorMsg(obj.errorMessage)
     }
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// =========================

// create patient
export const createPatientAsync = (payload, history, setMsgModal) => async (
  dispatch
) => {
  payload.facilityNumber = payload.facilityNumber.replace(/[\|\s]/g, "");
  try {
    dispatch(setLoading(true));
    const data = await request(PATIENT, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResMsgState(data.status));
      const listPayLoad = {};
      listPayLoad["url"] = PATIENT;
      listPayLoad["method"] = "Get";
      listPayLoad["queryParam"] = {
        customerNumber: payload.customerNumber,
        offSet: 0,
        limit: LIST_VIEW_LIMIT,
        pagetype: "patientadmin",
      };
      listPayLoad["setMethod"] = setPatients;
      dispatch(getCallBackListAsync(listPayLoad));
      if (setMsgModal) {
        setMsgModal(true);
      }
    }
  } catch (error) {
    dispatch(setResMsgState("error"));
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const simpleCreatePatientAsync = ({
  payload,
  history,
  setMsgModal,
  getPatientList,
}) => async (dispatch) => {
  payload.facilityNumber = payload.facilityNumber.replace(/[\|\s]/g, "");
  try {
    dispatch(setLoading(true));
    const data = await request(PATIENT, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResMsgState(data.status));
      const listPayLoad = {};
      listPayLoad["url"] = PATIENT;
      listPayLoad["method"] = "Get";
      listPayLoad["queryParam"] = {
        customerNumber: payload.facilityNumber,
        offSet: 0,
        limit: LIST_VIEW_LIMIT,
        pagetype: "patient",
      };
      listPayLoad["setMethod"] = setPatients;
      dispatch(getCallBackListAsync(listPayLoad));
      if (setMsgModal) {
        setMsgModal(true);
      }
      /*if (getPatientList) {
        setTimeout(() => {
          getPatientList(payload.facilityNumber);
        }, 1000);
      }*/
    }
  } catch (error) {
    dispatch(setResMsgState("error"));
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// getPatientsDetails
export const getCallBackListAsync = (payload, history,setTinyDelay) => async (dispatch) => {

  //dispatch(resetPatients())
  //const url = payload.url;
  const url = '/patientsv1';//patient list api  version change
  const queryParam = payload.queryParam && payload?.queryParam;
  const method = payload.method;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.patients.map((value) => {
        value["dob"] = value.dob
          ? format(new Date(value.dob), "MMMM dd, yyyy")
          : "";
        value["admissionDate"] = value.admissionDate
          ? format(new Date(value.admissionDate.replace('Z', '')), "MMMM dd, yyyy")
          : "";
        value["dischargeDate"] = value.dischargeDate
          ? format(new Date(value.dischargeDate), "MMMM dd, yyyy")
          : "";
        value["facilityLocation"] =
          value["address"].addressLine1 !== null &&
          value["address"].addressLine1 !==
            null + " " + (value["address"].addressLine2 !== null) &&
          value["address"].addressLine2;
        value["city"] = value["address"].city;
        value["state"] = value["address"].state;
        value["zipCode"] = value["address"].zipCode;
        value["gender"] = PATIENT_GENDER[value?.gender?.toLowerCase()];
        value["addressLines"] =
          value["address"]?.addressLine1 !== null ||
          value["address"]?.addressLine2 !== null
            ? value["address"]?.addressLine1 +
              " " +
              value["address"]?.addressLine2
            : "";
      });

      dispatch(payload.setMethod(data));
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
      dispatch(
        payload.setMethod({
          data: {
            patients: [],
            metadata: { total: 0, limit: 50, offset: 0, prev: "", next: "" },
          },
        })
      );
      window.location = "/unAuthorized";
    } else {
      dispatch(payload.setMethod(obj));
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
    dispatch(setPatientLoad(false))
  }
};

/* Setting options types */

export const optionTypeAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(OPTION_TYPES + "/" + payload, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (payload === "insurancepayer") {
        dispatch(setInsurancePayer(data.data.options));
      }
      if (payload === "reasonforvisit") {
        dispatch(setReasonForVisit(data.data.options));
      }
      if (payload === "comorbidcondition") {
        dispatch(setComorbidConditions(data.data.options));
      }
      if (payload === "emr") {
        dispatch(setEmrTypes(data.data.options));
      }
      if (payload === "humananatomy") {
        dispatch(setHumananatomy(data.data.options));
      }
      if (payload === "historycause") {
        dispatch(setHistoryCause(data.data.options));
      }
      if (payload === "pihistory") {
        dispatch(setPiHistory(data.data.options));
      }
      if (payload === "woundage") {
        dispatch(setWoundAge(data.data.options));
      }
      if (payload === "phototype") {
        dispatch(setPhotoType(data.data.options));
      }
      if (payload === "documentationtype") {
        dispatch(setDocumentProvidedBy(data.data.options));
      }
      if (payload === "tissuetemperature") {
        dispatch(setTissueTemp(data.data.options));
      }
      if (payload === "tissuefirmness") {
        dispatch(setTissueFirmness(data.data.options));
      }
      if (payload === "painlevel") {
        dispatch(setPainLevel(data.data.options));
      }
      if (payload === "blanchtest") {
        dispatch(setBlanchTest(data.data.options));
      }
      if (payload === "exudatetype") {
        dispatch(setExudateType(data.data.options));
      }
      if (payload === "exudateamount") {
        dispatch(setExudateAmount(data.data.options));
      }
      if (payload === "woundedges") {
        dispatch(setWoundEdges(data.data.options));
      }
      if (payload === "skincolorarounwound") {
        dispatch(setSkinColorAroundWound(data.data.options));
      }
      if (payload === "induration") {
        dispatch(setInduration(data.data.options));
      }
      if (payload === "edema") {
        dispatch(setEdema(data.data.options));
      }
      if (payload === "granulationtissue") {
        dispatch(setGranulationTissue(data.data.options));
      }
      if (payload === "epithelialization") {
        dispatch(setEpithelialization(data.data.options));
      }
      if (payload === "devicetype") {
        dispatch(setDeviceType(data.data.options));
      }
      if (payload === "woundevaluationfrequency") {
        dispatch(setWoundFrequencyType(data.data.options));
      }
      if (payload === "woundprogress") {
        dispatch(setWoundProgress(data.data.options));
      }
      if (payload === "periwoundcondition") {
        dispatch(setPeriWoundCondition(data.data.options));
      }
      if (payload === "dressingappearance") {
        dispatch(setDressingappearanceListoptions(data.data.options));
      }
      if (payload === "additionalcare") {
        dispatch(setAdditionalCareListoptions(data.data.options));
      }
      if (payload === "modalities") {
        dispatch(setModalitiesListoptions(data.data.options));
      }
      if (payload === "debridementperformedby") {
        dispatch(setDebridementperformedby(data.data.options));
      }
      if (payload === "nonexcisionalapproach") {
        dispatch(setNonexcisionalapproach(data.data.options));
      }
      if (payload === "surgicaldebridementprocedure") {
        dispatch(setSurgicaldebridementprocedure(data.data.options));
      }
      if (payload === "depthofdebridement") {
        dispatch(setDepthofdebridement(data.data.options));
      }
      if (payload === "instrumentsused") {
        dispatch(setInstrumentsused(data.data.options));
      }
      if (payload === "necroticsubcutaneoustissue") {
        dispatch(setnecroticsubcutaneoustissue(data.data.options));
      }
      if (payload === "necrotictissue") {
        dispatch(setNecrotictissue(data.data.options));
      }
      if (payload === "postdebridementbleedingamount") {
        dispatch(setPostdebridementbleedingamount(data.data.options));
      }
      if (payload === "techniqueused") {
        dispatch(setTechniqueusedOptions(data.data.options));
      }
      if (payload === "notapplicable") {
        dispatch(setNotapplicableOptions(data.data.options));
      }

      if (payload === "clinician") {
        dispatch(setClinicianData(data.data.options));
      }

      if (payload === "woundType") {
        dispatch(setWoundType(data.data.options));
      }
      if (payload === "facility") {
        dispatch(setAllFacilityOptions(data.data.options));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/* Temporaray password */

export const registerForMobileAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(REGISTER_PATIENT_FOR_MOBILE, {
      method: "POST",
      body: JSON.stringify({
        ne1Id: payload.ne1Id,
        email: payload.email,
      }),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setEditStateTemPass(SUCCESS_STATUS));
      dispatch(setTemporaryPassword(data.data.password));
      dispatch(patientDetailsAsync(payload.ne1Id));
    }
    // else if(data && data.status === ERROR_STATUS){
    //   dispatch(setRespMsg(data))
    // }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    dispatch(setRespMsg(obj));
    dispatch(setEditStateTemPass(obj));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFacilityDataAsyncForPatients = (payload,setCustomerNumber) => async (
  dispatch
) => {
  // const customerNumber = payload.customerNumber
 
  dispatch(setError(''));
  try {
    dispatch(setLoading(true));
    let queryStr = "/listoptions/facility";
    if (payload?.customerNumber) {
      queryStr =
        //"/listoptions/facility?customerNumber=" + payload.customerNumber;
        "/getpatientcustomerfacilities?customerNumber=" + payload.customerNumber;
        
    }
    const data = await request(`${queryStr}`, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setNewFacilitiesData(data));
      // payload.setMethod && dispatch(payload.setMethod(data))
      if(setCustomerNumber){
        setCustomerNumber(payload.customerNumber)
      }
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setNewFacilitiesData([]));
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk function for create new wound assessment

export const createAssessmentAsync = (
  payload,
  history,
  currentWoundPhoto
) => async (dispatch, getState) => {
  try {
    
    dispatch(setLoading(true));
    const patientId = payload;
    const state = getState();
    const chkPayload=state.patient.createAssessmentData
    const finalPayload={...chkPayload,"isWoundWithoutPhoto":0}
    const resp = await request(PATIENT + "/" + payload + "/assessments", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      payload = {
        id: resp.data.id,
      };
      if (currentWoundPhoto&&state.user?.role==='ExternalStandardUser') {
        dispatch(setLoading(true));
        await dispatch(patientAssessmentAsync(patientId));
        await dispatch(getPatientSubmittedWoundPhotoAsync(patientId));
        await dispatch(setEditState(resp.status));
        window.location = "/patients/" + patientId;
      } else {
        /* Set the returned data id in a state */
        dispatch(setCreateAssessmentData(payload));
        dispatch(setDetailAssessment({}));
        history.push("/patients/" + patientId + "/assessment/" + resp.data.id);
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// delete patient list
export const deletePatientListAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_PATIENTS, value.ne1Id));
    });
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Image annotation post
export const woundEvalAsync = (payload,patientId,assessmentId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(WOUND_IMAGE_MEASUREMENT, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      if(resp?.data?.surfaceArea&&resp?.data?.surfaceArea!==null)
      dispatch(setWoundMeasurementData(resp.data));
    else
    dispatch(setWoundMeasurementData({surfaceArea:'empty'}));
      //measurementData
      if(patientId&&assessmentId)
      dispatch(detailAssessmentAsync({ patientId, assessmentId }))
      //dispatch(detailSnakeAlgorithmAsync({ patientId, assessmentId }))
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// wound measurement update call
export const woundMeasurementUpdateAsync = (patientId, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT + "/" + patientId + "/assessments" + "/" + payload.id,
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      const payloadData = {
        patientId: patientId,
        assessmentId: payload.id,
      };
      dispatch(detailAssessmentAsync(payloadData));
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};




// Update customLabel wound update call
export const updatepatientassessmentcustomlabels = (payload, patientId, assessmentId) => async (dispatch) => {  
  try {
    dispatch(setLoading(true));
    const data = await request('/' +'updatewoundassessmentcustomlabels',
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      const newload = {
        patientId: payload.patientId,
        assessmentId: payload.assessmentId,
        injuryType : payload.injuryType,
        bodyType : payload.bodyType
      };
      dispatch(detailAssessmentAsync(newload));
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile assessment get call
export const detailAssessmentAsync = (payload) => async (dispatch) => {
  try {
    
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessmentsv1/" +  // Updated from assessments
        payload.assessmentId,
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
  
      if(resp.data?.measurement?.isSnakeAlgorithmsuccessful){
        dispatch(setIsSnakeAlgorithms(''));
        dispatch(setIsSnakeAlgorithms(resp.data?.measurement?.isSnakeAlgorithmsuccessful));
      }
      dispatch(setDetailAssessment(resp.data));
      // Calling signlockactions call everytime we make get call of assessments, so that we display signlock button automatically
      // without refreshing the page
    /* dispatch(setInterventionListOptions([]));
      dispatch(setTreatmentListIntervention({}));
      dispatch(setDressingAppearance({}));
      dispatch(setDebridement({}));
      dispatch(setWoundCleanserGetData({}));
      dispatch(setSkinProtectantGetData({}));
      dispatch(setPrimaryDressingGetData({}));
      dispatch(setSecondaryDressingGetData({}));
      dispatch(setCompressionGetData({}));
      dispatch(setModalities({}));
      dispatch(setAdditionalCare({}));
      dispatch(setdressChangeFrequency({}));*/
      const signLockPayload = {
        patientId: payload.patientId,
        assessmentId: payload.assessmentId,
        type: "signlockactions",
      };
       dispatch(signLockAsync(signLockPayload));
       dispatch(getInterventionList(payload));
      // dispatch(ne1getWounAssessmentDetailAsync(payload));
       //dispatch(ne1configsignwithoutmindatasetAsync(payload));
   
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
    } else {
      dispatch(setEditState("error"));
      dispatch(setError(obj.errorMessage));
    }
  } finally {


    dispatch(setLoading(false));
  }
};

// Link assessment update call
export const linkAssessmentUpdateAsync = (payload) => async (dispatch) => {
  const payloadData = {
    sameAs: payload.currentId,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/link",
      {
        method: "POST",
        body: JSON.stringify(payloadData),
      }
    );
    if (data && data.status !== SUCCESS_STATUS) {
      dispatch(setError(data.message));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

//Toogle Override Status
export const patientAssessmentAsyncOverride = (payload) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(
      GET_TOGGLESTATUS + 
      payload.assessmentId +
      "/tissuetypesoverrideinfo"
      , {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setTissueOverrideStatus(resp.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Get images for tissue types and touch and view  /* Override Toggle */
export const characteristicsImagesAsync = (payload) => async (dispatch) => { 
  
  try {
    dispatch(setLoading(true));
    let data;
    if (payload.type === "touchviewpictures") {
      data = await request(PATIENT + "/assessments/" + payload.type, {
        method: "GET",
      });
    }
    if (payload.type === "tissuetypes") {
      data = await request(
        PATIENT +
          "/" +
          payload.patientId +
          "/assessments/" +
          payload.assessmentId +
          "/v1/" +
          payload.type,
        {
          method: "GET",
        }
      );
    }
    if (data && data.status === SUCCESS_STATUS) {
      if (payload.type === "tissuetypes") {
        dispatch(setTissueTypesData(data.data));
      }
      if (payload.type === "touchviewpictures") {
        dispatch(setTouchViewImages(data.data));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/* Assessment Notes GET call */
export const notesAssessmentAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/notes",
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setAssessmentNotes(resp.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/* Assessment Notes POST call */
export const notesPostAsync = (payload) => async (dispatch) => {
  const payloadData = {
    notes: payload.notes,
  };
  try {
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/notes",
      {
        method: "POST",
        body: JSON.stringify(payloadData),
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setNewLayout(resp.status)); // status
      dispatch(notesAssessmentAsync(payload)); // callback Notes get call
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const woundAssessmentReEvalAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setTouchViewDataByAssessID({}));
    //   dispatch(setAccessError('Test'))
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId+"/reeval",
      {
        method: "POST",
        body: JSON.stringify({"isWoundWithoutPhoto":1,"uuId":"","woundPhotoId":0}),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      const patientId= payload.patientId
      const assessmentId= data?.data?.id
      dispatch(setDetailAssessment({}));
     /* const payloadUpdate = {
        patientId,
        assessmentId,
     }
     dispatch(detailAssessmentAsync(payloadUpdate));*/
     window.location = "/patients/" + patientId + "/assessment/" + assessmentId;
      
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const woundAssessmentUpdateAsync = (payload) => async (dispatch) => {
  let getOverride = payload.overrideenable === true ? '?overrideenable=true' : '?overrideenable=false'  
  try {
    //   dispatch(setAccessError('Test'))
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        getOverride,
      {
        method: "PUT",
        body: JSON.stringify(payload.assessment),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(detailAssessmentAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// Wound classification async
export const woundClassificationAsync = (payload) => async (dispatch) => {
  let getOverride = payload.overrideenable === true ? '?overrideenable=true' : '?overrideenable=false'
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessment" +
        "/" +
        payload.assessmentId + 
        "/v1" +
        "/woundclassifications",
      {
        method: "POST",
        body: JSON.stringify(payload.data),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setWoundClassificationOptions(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const signLockAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/" +
        payload.type,
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payload.type === "signlockactions") {
        dispatch(setSignLockActions(data.data.options));
      }
      if (payload.type === "checklock") {
        dispatch(setCheckLock(data.status));
        payload.lockSuccessAsync();
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (payload.type === "checklock") {
      dispatch(setCheckLock(obj));
      payload.lockFailAsync();
    }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// custom form
export const getFormViewAsyn = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      constructEndpoint(CUSTOM_FORM_VIEW, payload.id, payload.queryParam),
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setviewForms(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Re-eval patient assessment
export const reEvalAssessmentAsync = (payload, history) => async (dispatch) => {
  try {
    dispatch(setTouchViewDataByAssessID({}));
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/reeval",
      {
        method: "POST",
        body: JSON.stringify(payload.data),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      let response = {
        id: data.data.id,
      };
      /* Set the returned data id in a state */
      dispatch(setReEvalAssessmentData(response));
      history.push(
        PATIENT + "/" + payload.patientId + "/assessment/" + data.data.id
      );
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Get Healing Trajectory
export const getHealingTrajectoryAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setIsHealingGraphLoad(true));
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessmentsv1" +  //changed for Label from assessments
        "/" +
        payload.assessmentId +
        "/healingtrajectory",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setHealingTrajectoryData(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
    dispatch(setIsHealingGraphLoad(false));
  }
};

// =============================== Get Risk Assessment
export const patientRiskAssessmentAsync = (payload, history) => async (
  dispatch
) => {
if(payload){
  try {
    dispatch(setLoading(true));
    const resp = await request(
      constructEndpoint(GET_RISK_ASSESSMENTS, payload),
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setRiskAssessment(resp.data.riskAssessments));
    }
  } catch (error) {
    //dispatch(setRiskAssessment(riskAssessmentData));
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
}
};
// =============================== Get Risk Assessment Options
export const getRiskAssessmentOptionsAsync = (optionRequest) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(OPTION_TYPES + "/" + optionRequest, {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      if (optionRequest === "bradensensoryperception") {
        dispatch(setBradenSensoryPerception(resp.data.options));
      } else if (optionRequest === "bradenmoisture") {
        dispatch(setBradenMoisture(resp.data.options));
      } else if (optionRequest === "bradenactivity") {
        dispatch(setBradenActivity(resp.data.options));
      } else if (optionRequest === "bradenmobility") {
        dispatch(setBradenMobility(resp.data.options));
      } else if (optionRequest === "bradennutrition") {
        dispatch(setBradenNutrition(resp.data.options));
      } else if (optionRequest === "bradenfrictionshear") {
        dispatch(setBradenFrictionShear(resp.data.options));
      } else if (optionRequest === "nortonphysicalcondition") {
        dispatch(setNortonPhysicalCondition(resp.data.options));
      } else if (optionRequest === "nortonmentalcondition") {
        dispatch(setNortonMentalCondition(resp.data.options));
      } else if (optionRequest === "nortonactivity") {
        dispatch(setNortonActivity(resp.data.options));
      } else if (optionRequest === "nortonmobility") {
        dispatch(setNortonMobility(resp.data.options));
      } else if (optionRequest === "nortonincontinence") {
        dispatch(setNortonIncontinence(resp.data.options));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Risk Assesment Post call
export const postRiskAssessmentsAsync = (patientId, payload) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const data = await request(PATIENT + "/" + patientId + "/riskassessments", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setRiskAssessmentResponseScore(data.data.score));
      dispatch(patientRiskAssessmentAsync(patientId));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Get Rist Assessment by risk assessment id

export const getRiskAssessmentsByIdAsync = (
  patientId,
  riskAssessmentsid
) => async (dispatch) => {
  try {
    if(patientId&&riskAssessmentsid){
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT + "/" + patientId + "/riskassessments/" + riskAssessmentsid,
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setRiskAssessmentQuestions(resp.data));
    }
  }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Measurement status checker - If someone else is performing auto measurement right now?
export const checkAutoMeasurementStatusAsync = (
  patientId,
  assessmentId
) => async (dispatch) => {
  try {
    const resp = await request(
      constructEndpoint(GET_AUTO_MEASUREMENT_STATUS, patientId, assessmentId),
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setAutoMeasurementStatus(resp.data));
      if (resp.data === "completed") {
        dispatch(detailAssessmentAsync({ patientId, assessmentId }));
      }
    } else {
      dispatch(setAutoMeasurementStatus("failed"));
    }
  } catch (error) {
    dispatch(setAutoMeasurementStatus("failed"));
  }
};

/*Get intervention list*/
export const getInterventionList = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (Array.isArray(data.data) && data.data.length === 0) {
        dispatch(setInterventionListOptions([]));
      } else {
        dispatch(setInterventionListOptions(data.data));
      }
      dispatch(setLoading(true));
      dispatch(setInterventionLoader(true));
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setInterventionLoader(true));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    dispatch(setInterventionLoader(true));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Add intervention list*/
export const addInterventionList = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention",
      {
        method: "POST",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getInterventionList(payload));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment List by intervention id*/
export const getTreatmentListByIntervention = (payload) => async (dispatch) => {
  try {
    if (payload.interventionId !== 0) {
      dispatch(setLoading(true));
      const data = await request(
        PATIENT +
          "/" +
          payload.patientId +
          "/assessments" +
          "/" +
          payload.assessmentId +
          "/intervention/" +
          payload.interventionId +
          "/treatmentintervention",
        {
          method: "GET",
        }
      );
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setTreatmentListIntervention(data.data.options));
        const signLockPayload = {
          patientId: payload.patientId,
          assessmentId: payload.assessmentId,
          type: "signlockactions",
        };
        dispatch(signLockAsync(signLockPayload));
        dispatch(ne1getWounAssessmentDetailAsync(payload));
        dispatch(ne1configsignwithoutmindatasetAsync(payload));
      }
      if (data && data.status === ERROR_STATUS) {
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment Dressingappearance*/
export const getDressingAppearanceAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/dressingappearance",
      {
        method: "GET",
      }
    );
    const  patientId=payload.patientId
    const  assessmentId=payload.assessmentId
    const  interventionId=payload.interventionId
    await dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'primarydressing' }));
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDressingAppearance(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const patchDressingAppearanceAsync = (payloadData) => async (
  dispatch
) => {
  const payload = {
    dressingAppearance: payloadData.dressingAppearance,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/dressingappearance",
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }

      await dispatch(getTreatmentListByIntervention(payloadData));
      await dispatch(getDressingAppearanceAsync(payloadData)); // call-back DressingAppearance

    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment AdditionalCare*/
export const getAdditionalCareAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/additionalcare",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAdditionalCare(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const patchAdditionalCareAsync = (payloadData) => async (dispatch) => {
  const payload = {
    additionalCare: payloadData.additionalCare,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/additionalcare",
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }
      await dispatch(getAdditionalCareAsync(payloadData)); // call-back DressingAppearance
      await dispatch(getTreatmentListByIntervention(payloadData));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment WounderCleanser*/
export const getWoundcleanserAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/woundcleanser",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setWoundCleanser(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment Modalities*/
export const getModalitiesAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/modalities",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setModalities(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const patchModalitiesAsync = (payloadData) => async (dispatch) => {
  const payload = {
    modalities: payloadData.modalities,
  };
  try {
    dispatch(setLoading(true));
    // const data = await request(PATIENT + '/' + payload.patientId + '/assessments' + '/' + payload.assessmentId +'/intervention/'+payload.interventionId +'/treatmentintervention'
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/modalities",
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }
      await dispatch(getTreatmentListByIntervention(payloadData));
      await dispatch(getModalitiesAsync(payloadData)); // call-back DressingAppearance
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment debridement*/
export const getdebridementAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/debridement",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDebridement(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Treatment PrimaryDressing*/
export const getDressingAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/intervention/" +
        payload.interventionId +
        "/" +
        payload.payType,
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payload.payType === "primarydressing") {
        dispatch(setPrimaryDressingGetData(data.data.productCategory));
      }
      if (payload.payType === "secondarydressing") {

        dispatch(setSecondaryDressingGetData(data.data.productCategory));
        if(data?.data?.productCategory && data?.data?.productCategory.length===0){
          const patientId=payload.patientId
          const assessmentId=payload.assessmentId
          const interventionId=payload.interventionId
          dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'primarydressingmaterialinfo' }));
        }

      }
      if (payload.payType === "compression") {
        dispatch(setCompressionGetData(data.data.productCategory));
      }
      if (payload.payType === "woundcleanser") {
        dispatch(setWoundCleanserGetData(data.data.productCategory));
      }
      if (payload.payType === "skinprotectant") {
        dispatch(setSkinProtectantGetData(data.data.productCategory));
      }
      if (payload.payType === "primarydressingmaterialinfo") {
        dispatch(setIsMessageShowForSecondayDressing(data?.data?.isMessageShowForSecondayDressing));
      }
    }
    if (data && data.status === ERROR_STATUS) {
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/*Get Product categary By formularyId for = Primary ,Secondary Dressingm, debridement, woundcleanser,skinprotectant,compression*/
export const getlistOptionsAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      "/productcategory/formulary/" +
        payload.formularyid +
        "/" +
        payload.option,
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payload.payType === "primarydressing") {
        dispatch(setPrimaryDressingListOptions(data.data.productCategory));
      }
      if (payload.payType === "secondarydressing") {
        dispatch(setSecondaryDressingListOptions(data.data.productCategory));
      }
      if (payload.payType === "compression") {
        dispatch(setCompressionListOptions(data.data.productCategory));
      }
      if (payload.payType === "woundcleanser") {
        dispatch(setWoundCleanserListOptions(data.data.productCategory));
      }
      if (payload.payType === "skinprotectant") {
        dispatch(setSkinProtectantListOptions(data.data.productCategory));
      }
      if (payload.option === "debridement") {
        dispatch(setdebridementListoptions(data.data));
      }
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveDressingAsync = (payloadData) => async (dispatch) => {
  const payload = {
    productCategory: payloadData.productCategory,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/" +
        payloadData.payType,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }
      if(payloadData?.formularyId&&payloadData.payType==='secondarydressing'){
        const patientId=payloadData.patientId
        const assessmentId=payloadData.assessmentId
        const interventionId=payloadData.interventionId
        const payload = {
          'formularyid': payloadData?.formularyId,
          'option': 'secondarydressing?ne1Id=' + patientId + '&assessmentId=' + assessmentId+ '&interventionid=' + interventionId,
          payType: 'secondarydressing'
        }
        await dispatch(getlistOptionsAsync(payload));
      }
      await dispatch(getTreatmentListByIntervention(payloadData));
      await dispatch(getDressingAsync(payloadData)); // call-back DressingAppearance
      
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

/* Get Activity Log Data */

export const getActivityLogAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT + "/" + payload.patientId + "/activitylog",
      {
        method: "GET",
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setActivityLogGetData(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const patchDebridementAsync = (payloadData) => async (dispatch) => {
  const payload = {
    isDebridementPerformed: payloadData.isDebridementPerformed,
    debridementPerformedBy: payloadData.debridementPerformedBy,
    surgicalDebridementProcedure: payloadData.surgicalDebridementProcedure,
    nonExcisionalApproach: payloadData.nonExcisionalApproach,
    necroticSubcutaneousTissue: payloadData.necroticSubcutaneousTissue,
    necroticTissue: payloadData.necroticTissue,
    notApplicable: payloadData.notApplicable,
    depthOfDebridement: payloadData.depthOfDebridement,
    instrumentsUsed: payloadData.instrumentsUsed,
    surfaceAreaOfNecroticSkinTissue:
      payloadData.surfaceAreaOfNecroticSkinTissue,
    surfaceAreaOfNecroticSubqTissue:
      payloadData.surfaceAreaOfNecroticSubqTissue,
    postDebridementBleedingAmount: payloadData.postDebridementBleedingAmount,
    stoppedBleedingContactPressure: payloadData.stoppedBleedingContactPressure,
    techniqueUsed: payloadData.techniqueUsed,
    honeyDressing: payloadData.honeyDressing,
    productCategory: payloadData.productCategory,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/debridement",
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }
      await dispatch(getdebridementAsync(payloadData)); // call-back DressingAppearance
      await dispatch(getTreatmentListByIntervention(payloadData));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
/*Get WoundPhoto*/
export const getPatientSubmittedWoundPhotoAsync = (ne1Id) => async (
  dispatch
) => {
  // let data=[{"woundPhotoId":1,"ne1Id":"NE1-1","picture":"https://devdshpstorage.blob.core.windows.net/patientmedia/0bc7c1e308be4468b3c52e429f8d8214%2Ff6f13d7b273547c1b9f1eabff5899c04%2F8220562305de4cb49bb95fa9e31e503c?sv=2020-04-08&se=2021-08-10T11%3A52%3A52Z&sr=b&sp=r&sig=RiOuZaDmPa74ZPkclMaRG7B2JPMYu0pXz9w4r1i8QCQ%3D","notes":"wound photo notes","isStickerAvailable":1,"stickerArea":"1","stickerLength":"1","createdBy":"MS","createdDate":""},{"woundPhotoId":2,"ne1Id":"NE1-1","picture":"https://devdshpstorage.blob.core.windows.net/patientmedia/0bc7c1e308be4468b3c52e429f8d8214%2Ff6f13d7b273547c1b9f1eabff5899c04%2Fbbaf4ea64b554d8c87c591a4ffc9a5b5?sv=2020-04-08&se=2021-08-10T11%3A52%3A52Z&sr=b&sp=r&sig=aMlAkPZGNnH5V1jTWMJrqpYrO27nV5T%2F8xc0gEnSj4k%3D","notes":"wound photo notes","isStickerAvailable":0,"stickerArea":"1","stickerLength":"1","createdBy":"MS DHOS","createdDate":""}]
  try {
    dispatch(setLoading(true));
    const resp = await request(constructEndpoint(PATIENTVIEWPASTWOUND, ne1Id), {
      method: "GET",
    });
    // dispatch(setWoundPhotoData(data))
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setWoundPhotoData(resp.data.assessments));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
// delete WoundPhoto
export const deleteWoundPhotoAsync = (
  ne1Id,
  woundPhotoId,
  isDiscarded
) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(
      constructEndpoint(PATIENTVIEWDELETE, ne1Id, woundPhotoId),
      {
        method: "DELETE",
        body: JSON.stringify({ isDiscarded: isDiscarded }),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setLoading(true));
      await dispatch(getPatientSubmittedWoundPhotoAsync(ne1Id));
      await dispatch(patientAssessmentAsync(ne1Id));
    }
  } catch (error) {
    // Sales Office list option get call
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
//Re-Admitt
export const reAdmitPatientAsync = (payloadData) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(PATIENT + "/" + payloadData.ne1Id, {
      method: "PATCH",
      body: JSON.stringify(payloadData),
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setLoading(true));
      await dispatch(patientAssessmentAsync(payloadData.ne1Id));
      await dispatch(patientDetailsAsync(payloadData.ne1Id));
      await dispatch(getPatientSubmittedWoundPhotoAsync(payloadData.ne1Id));
      dispatch(setEditState(data.status));
    }
  } catch (error) {
    dispatch(setEditState("error"));
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
/*PreWoundPhotoAssement*/
export const preWoundPhotoEvalAsync = (payload) => async (dispatch) => {
  try {
    const resp = await request("/PreAssessmentWoundImageMeasurement", {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      //dispatch(setWoundMeasurementData(resp.data))
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// Re-eval WoundPhoto Submitted patient assessment
export const reEvalWoundPhotoAssessmentAsync = (payload, history) => async (
  dispatch
) => {
  dispatch(setTouchViewDataByAssessID({}));
  const payloadreEval = {
    picture: payload.picture,
    woundPhotoId: payload?.woundPhotoId,
    assessmentId: payload?.assessmentId,
    uuId: payload?.uuId,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments" +
        "/" +
        payload.assessmentId +
        "/reeval",
      {
        method: "POST",
        body: JSON.stringify(payloadreEval),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setLoading(true));
      await dispatch(patientAssessmentAsync(payload.patientId));
      await dispatch(getPatientSubmittedWoundPhotoAsync(payload.patientId));
      await dispatch(setEditState(data.status));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    dispatch(setEditState("error"));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getClinicianandproductcategory = (payload) => async (dispatch) => {
  dispatch(setClinicianandproductcategory({}));
  try {
    dispatch(setLoading(true));
    const facilities = { facilities: payload };
    const resp = await request(
      "/reports/listoptions/clinicianandproductcategory",
      {
        method: "POST",
        body: JSON.stringify(facilities),
      }
    );

    if (resp && resp.status === SUCCESS_STATUS) {
      let val = { ...resp.data };
      let cliniArr = [];
      val?.clinician?.map((item) => {//NOSONAR
        let test = { ...item };
        test["key"] = item.key;
        test["value"] = `${item.value} - ${item.key}`;
        cliniArr = [...cliniArr, test];
      })//NOSONAR
      val["clinician"] = cliniArr;
      dispatch(setClinicianandproductcategory(val));
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTissueTypeandClassification = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const woundType = { woundType: payload };
    const resp = await request(
      "/reports/listoptions/tissuetypeandclassification",
      {
        method: "POST",
        body: JSON.stringify(woundType),
      }
    );

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setTissuetypeandClassification(resp.data));
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
/*POST dashboard data */

export const postDashboardAsync = (payload, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(
      constructEndpoint("/reports/dashboard", payload.queryParam),
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    if (resp && resp.status === SUCCESS_STATUS) {
      let finalDat = resp.data;
      finalDat.Postdata = payload;
      if (finalDat && Array.isArray(finalDat.reports)) {
        finalDat.reports.map((value) => {
          value["dob"] = value.dob
            ? format(new Date(value.dob), "MMMM dd, yyyy")
            : "";
          value["admissionDate"] = value.admissionDate
            ? format(new Date(value.admissionDate.replace('Z', '')), "MMMM dd, yyyy")
            : "";
          value["dischargeDate"] = value.dischargeDate
            ? format(new Date(value.dischargeDate), "MMMM dd, yyyy")
            : "";
          value["lastAssessed"] = value.lastAssessed ? value.lastAssessed : "";          
          value["lastUpdatedOn"] = value.lastUpdatedOn
            ? format(new Date(value.lastUpdatedOn), "MMMM dd, yyyy")
            : "";
          value["woundPhotoTakenOn"] = value.woundPhotoTakenOn
            ? format(new Date(value.woundPhotoTakenOn), "MMMM dd, yyyy")
            : "";
          value["woundAtRiskOfInfection"] = value?.woundAtRiskOfInfection ? value?.woundAtRiskOfInfection === "true" ? "Yes" : "No" : "No"; 
          value["woundbedIdentified"] = value?.woundbedIdentified ? value?.woundbedIdentified === "true" ? "Yes" : "No" : "No";
          // value["lastAssessed"] = value.lastAssessed
          //   ? format(new Date(value.lastAssessed), "MMMM dd, yyyy")
          //   : "";
        });
      }
      dispatch(setBiReortsList(finalDat));
      dispatch(postSummaryCsvBiReport(payload, history));
      dispatch(postDetailCsvBiReport(payload, history));
      history.push("/biReports/biReportsList");
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// BiReport Detail Cvs api call

export const postSummaryCsvBiReport = (payload, history) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const resp = await request("/reports/getadhoccsv", {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      // dispatch(setBiReortsList(sampleBiReportListData.data))
      const finalDat = resp.data;
      finalDat.Postdata = payload;

if (finalDat && Array.isArray(finalDat.reports)) {
  finalDat.reports.map((value) => {
    value["dob"] = value.dob
      ? format(new Date(value.dob), "MM/dd/yyyy")
      : "";
    value["admissionDate"] = value.admissionDate
      ? format(new Date(value.admissionDate.replace('Z', '')), "MM/dd/yyyy")
      : "";
    value["dischargeDate"] = value.dischargeDate
      ? format(new Date(value.dischargeDate), "MM/dd/yyyy")
      : "";
    value["lastAssessed"] = value.lastAssessed ? value.lastAssessed : "";
    value["lastUpdatedOn"] = value.lastUpdatedOn
      ? format(new Date(value.lastUpdatedOn), "MM/dd/yyyy")
      : "";
    value["woundPhotoTakenOn"] = value.woundPhotoTakenOn
      ? format(new Date(value.woundPhotoTakenOn), "MM/dd/yyyy")
      : "";
    value["woundAtRiskOfInfection"] = value?.woundAtRiskOfInfection ? value?.woundAtRiskOfInfection === "true" ? "Yes" : "No" : "No"; 
    value["woundbedIdentified"] = value?.woundbedIdentified ? value?.woundbedIdentified === "true" ? "Yes" : "No" : "No";
      //value["tissueType"] = value.tissueType?`"${value.tissueType}"`:""
      //value["tissueType"] = "tesst"
  });
}

      dispatch(setBiReportSummaryCsv(finalDat));
      //  history.push('/biReports/biReportsList')
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const postDetailCsvBiReport = (payload, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request("/reports/getadhocdetailcsv", {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      // dispatch(setBiReortsList(sampleBiReportListData.data))
     
  const finalDat = resp.data;
  finalDat.Postdata = payload;

if (finalDat && Array.isArray(finalDat.reports)) {
finalDat.reports.map((value) => {
value["dob"] = value.dob
  ? format(new Date(value.dob), "MMMM dd, yyyy")
  : "";
value["admissionDate"] = value.admissionDate
  ? format(new Date(value.admissionDate.replace('Z', '')), "MMMM dd, yyyy")
  : "";
value["dischargeDate"] = value.dischargeDate
  ? format(new Date(value.dischargeDate), "MMMM dd, yyyy")
  : "";
value["lastAssessed"] = value.lastAssessed
  ? format(new Date(value.lastAssessed), "MMMM dd, yyyy")
  : "";
value["lastUpdatedOn"] = value.lastUpdatedOn
  ? format(new Date(value.lastUpdatedOn), "MMMM dd, yyyy")
  : "";
value["woundPhotoTakenOn"] = value.woundPhotoTakenOn
  ? format(new Date(value.woundPhotoTakenOn), "MMMM dd, yyyy")
  : "";

  value["areThereAnyOpenAreas"] = value?.areThereAnyOpenAreas? "TRUE": "False"
  value["woundAtRiskOfInfection"] = value?.woundAtRiskOfInfection ? value?.woundAtRiskOfInfection === "true" ? "Yes" : "No" : "No"
  value["exudateType"] = value?.exudateType?  value?.exudateType: "--"
  value["exudateAmount"] = value?.exudateAmount?  value?.exudateAmount: "--"
  value["woundbedIdentified"] = value?.woundbedIdentified ? value?.woundbedIdentified === "true" ? "Yes" : "No" : "No"
  value["woundClassification"] = value?.woundClassification?  value?.woundClassification: "--"
  value["woundProgress"] = value?.woundProgress?  value?.woundProgress: "--"
  value["debridement"] = value?.debridement?  value?.debridement: "--"
  value["woundCleanser"] = value?.woundCleanser?  value?.woundCleanser: "--"
  value["skinProtectant"] = value?.skinProtectant?  value?.skinProtectant: "--"
  value["woundProgress"] = value?.woundProgress?  value?.woundProgress: "--"
  value["woundProgress"] = value?.woundProgress?  value?.woundProgress: "--"
  value["woundProgress"] = value?.woundProgress?  value?.woundProgress: "--"
  value["woundProgress"] = value?.woundProgress?  value?.woundProgress: "--"

});
}

      dispatch(setBiReportDetailCsv(finalDat));
      //  history.push('/biReports/biReportsList')
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// PDF downlaod

export const pdfDownloadLog = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request("/patients/assessments/downloadlog", {
      method: "POST",
      body: JSON.stringify(payload),
    });
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const pdfDataAsync = (payload, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const logPayload = {
      assessmentId: payload.assessmentId,
      Type: "pdf",
      Ne1Id: payload.ne1Id,
    };
    dispatch(pdfDownloadLog(logPayload));
    const resp = await request("/patients/assessments/pdfdata", {
      method: "POST",
      body: JSON.stringify(payload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setPdfData(resp.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const getBiReportsListAsync = (payload, history) => async (dispatch) => {
  //dispatch(resetPatients())
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam;
  const method = payload.method;
  const reportData = payload.reportData;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(reportData),
    });
    if (data && data.status === SUCCESS_STATUS) {
      const finalDat = data.data;
      finalDat.Postdata = reportData;
      if (finalDat && Array.isArray(finalDat.reports)) {
        finalDat.reports.map((value) => {
          value["dob"] = value.dob
            ? format(new Date(value.dob), "MMMM dd, yyyy")
            : "";
          value["admissionDate"] = value.admissionDate
            ? format(new Date(value.admissionDate.replace('Z', '')), "MMMM dd, yyyy")
            : "";
          value["dischargeDate"] = value.dischargeDate
            ? format(new Date(value.dischargeDate), "MMMM dd, yyyy")
            : "";
          value["lastAssessed"] = value.lastAssessed
            ? format(new Date(value.lastAssessed), "MMMM dd, yyyy")
            : "";
          value["lastUpdatedOn"] = value.lastUpdatedOn
            ? format(new Date(value.lastUpdatedOn), "MMMM dd, yyyy")
            : "";
          value["woundPhotoTakenOn"] = value.woundPhotoTakenOn
            ? format(new Date(value.woundPhotoTakenOn), "MMMM dd, yyyy")
            : "";
          value["woundAtRiskOfInfection"] = value?.woundAtRiskOfInfection ? value?.woundAtRiskOfInfection === "true" ? "Yes" : "No" : "No";
          value["woundbedIdentified"] = value?.woundbedIdentified ? value?.woundbedIdentified === "true" ? "Yes" : "No" : "No";
        });
      }

      dispatch(setBiReortsList(finalDat));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDressingChangeFrequency = (payload, history) => async (
  dispatch
) => {
  const url = `/patients/${payload.patientId}/assessments/${payload.assessmentId}/intervention/${payload.interventionId}/dressingchangefrequency`;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url), {
      method: "GET",
    });
    if (data && data.status === "success") {
      dispatch(setdressChangeFrequency(data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
    dispatch(setdressChangeFrequency(obj));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateDressingChangeFrequency = (payloadData, history) => async (
  dispatch
) => {
  const url = `/patients/${payloadData.patientId}/assessments/${payloadData.assessmentId}/intervention/${payloadData.interventionId}/dressingchangefrequency`;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url), {
      method: "PATCH",
      body: JSON.stringify(payloadData.updateData.data),
    });
    if (data && data.status === "success") {
      if (payloadData.interventionId === 0) {
        await dispatch(getInterventionList(payloadData));
      }
      await dispatch(getDressingChangeFrequency(payloadData));
      await dispatch(getTreatmentListByIntervention(payloadData));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
    dispatch(setdressChangeFrequency(obj));
  } finally {
    dispatch(setLoading(false));
  }
};
export const patchDressingChangeFrequencyAsync = (payloadData) => async (
  dispatch
) => {
  const payload = {
    dressingchangefrequency: payloadData.dressingchangefrequency,
  };
  try {
    dispatch(setLoading(true));
    const data = await request(
      PATIENT +
        "/" +
        payloadData.patientId +
        "/assessments" +
        "/" +
        payloadData.assessmentId +
        "/intervention/" +
        payloadData.interventionId +
        "/dressingchangefrequency",
      {
        method: "PATCH",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getDressingChangeFrequency(payloadData)); // call-back DressingAppearance
      dispatch(getTreatmentListByIntervention(payloadData));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

// work List Records Count details
export const getWorkListRecordsCount = (payload, workListTab) => async (dispatch) => {
  dispatch(setWorkListRecordsCount({}));
  try {
    dispatch(setLoading(true));
    const data = await request(`${WOUND_WORKLIST_COUNT}?limit=1000&&facilityNumber=${payload}&&workListTab=${workListTab}`, {
      method: "GET",
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setWorkListRecordsCount(data.data));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj?.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404
    ) {
     // window.location = "/unAuthorized";
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//Create Assessment Without Photo
/*export const createAssessMentWithoutPhoto = (patientId,
  history
) => async (dispatch,getState) => {
  try {
   dispatch(setLoading(true));
    const state = getState();
   const createAssessmentData= state.patient.createAssessmentData
    const payload={
      "cause":createAssessmentData?.cause,
      "causeCategory": createAssessmentData?.causeCategory,
      "notes": createAssessmentData?.notes,
      "anatomy": createAssessmentData?.anatomy,
      "isFrontView": createAssessmentData?.isFrontView,
      "uuId":null,
      "picture":null,
      "isWoundWithoutPhoto":1,
      // "signedBy": createAssessmentData?.signedBy,
      // "lockedBy": createAssessmentData?.lockedBy 
    } 
    const resp = await request(PATIENT + "/" + patientId + "/assessments", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (resp && resp.status === SUCCESS_STATUS) {
     let resayload = {
        id: resp.data.id,
      };
    
     dispatch(setCreateAssessmentData(resayload));
     if(history){
      history.push("/patients/" + patientId + "/assessment/" + resp.data.id);
     }else{
      window.location = "/patients/" + patientId + "/assessment/" + resp.data.id;
     }
   
    }
  } catch (error) {
    const obj = { ...error };
  
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};*///
export const updateAnatomyListCheck = (payload,setAnatomyPopUPList, adminPageYes, mergeFunction) => async (dispatch,getState) => {
  try {
    dispatch(setWoundAssessmentsAnatomyList({}));
    dispatch(setLoading(true));
    const state = getState();
  let anatomy=payload?.assessment?.anatomy
  let cause=payload?.assessment?.historyCause?.cause
if(anatomy&&cause){
  let finPayload={
    assessmentId:payload?.assessmentId,
    patientId:payload?.patientId,
    isWoundWithoutPhoto:payload?.isWoundWithoutPhoto,
    anatomy:anatomy,
    cause:cause,
  }
  dispatch(setCreateAssessmentData(finPayload))
    cause=cause.replace(/[\/\s]/g, ''); //removeSlashesEmptystring
    const resp = await request(!mergeFunction ? PATIENT + "/" +payload?.patientId + "/v2/assessmentsv2/"+anatomy+"/"+cause+"/"+payload?.assessmentId : PATIENT + "/" +payload?.patientId + "/v2/assessmentsv2/"+anatomy+"/"+cause+"/"+payload?.assessmentId+'?type=allassessments', {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      if (resp.data!==null&& resp?.data?.assessments?.length > 0) {
        dispatch(setWoundAssessmentsAnatomyList(resp.data));
        if(setAnatomyPopUPList)
          dispatch(setAnatomyPopUPList(true))
        ///console.log('anatomyPopUPList Data',resp.data)
      }else{
        //dispatch(woundAssessmentUpdateAsync(payload))
        if(!mergeFunction){
          dispatch(ne1updateassessmentHistoryCauseAsync(payload))
          dispatch(setAnatomyPopUPList(false))
        }
      }
     /* Set the returned data id in a state */
    }

  }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
export const createAssessMentWithoutPhotoAnatomyCheck = (patientId,
  history,setAnatomyPopUPList
) => async (dispatch,getState) => {
  try {
    dispatch(setWoundAssessmentsAnatomyList({}));
    dispatch(setDetailAssessment({}))
   dispatch(setLoading(true));
    const state = getState();
    const userRole = state.user;
   const createAssessmentData= state.patient.createAssessmentData
    const payload={
      "cause":createAssessmentData?.cause,
      "causeCategory": createAssessmentData?.causeCategory,
      "notes": createAssessmentData?.notes,
      "anatomy": createAssessmentData?.anatomy,
      "isFrontView": createAssessmentData?.isFrontView,
      "uuId":null,
      "picture":null,
      "isWoundWithoutPhoto":1,
      "patientId": createAssessmentData?.patientId,
      "assessmentId": createAssessmentData?.assessmentId,
      "assessment":{
        "historyCause":{"cause":createAssessmentData?.cause,
      "causeCategory": createAssessmentData?.causeCategory,


      "woundAge": createAssessmentData?.woundAge?createAssessmentData?.woundAge:null,
      "conditionPOA": createAssessmentData?.conditionPOA?createAssessmentData?.conditionPOA:'No',
      "photoType":  createAssessmentData?.photoType?createAssessmentData?.photoType:null,
      "documentProvidedBy": createAssessmentData?.documentProvidedBy?createAssessmentData?.documentProvidedBy:null,
      "piHistory":  createAssessmentData?.piHistory?createAssessmentData?.piHistory:null,
      //"newWound": createAssessmentData?.newWound===1?1:0
      "newWound": userRole?.role === 'ExternalStandardUser'?0:1}
      
      }
      // "signedBy": createAssessmentData?.signedBy,
      // "lockedBy": createAssessmentData?.lockedBy 
    } 
    let cause=payload?.cause
    cause=cause.replace(/[\/\s]/g, ''); //removeSlashesEmptystring
    const resp = await request(PATIENT + "/" + patientId + "/v2/assessmentsv2/"+payload?.anatomy+"/"+cause+"/"+createAssessmentData?.assessmentId, {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      if (resp.data!==null&& resp?.data?.assessments?.length > 0) {
        dispatch(setWoundAssessmentsAnatomyList(resp.data));
        if(setAnatomyPopUPList){
          dispatch(
            setCreateAssessmentData(payload)
          );
          dispatch(setAnatomyPopUPList(true))
        }          
        ///console.log('anatomyPopUPList Data',resp.data)
      }
      else{
        dispatch(
          setCreateAssessmentData({
            newWound: 1,
          })
        );

       let redirect=1
        if(createAssessmentData?.assessmentId){

          dispatch(ne1updateassessmentHistoryCauseAsync(payload,redirect));
        }else{
          dispatch(createAssessMentWithoutPhoto(patientId,history,setAnatomyPopUPList));
        }
      }
     /* Set the returned data id in a state */
   
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
export const createAssessMentWithoutPhoto = (patientId,
  history,setAnatomyPopUPList
) => async (dispatch,getState) => {
  try {
   dispatch(setLoading(true));
    const state = getState();
   const createAssessmentData= state.patient.createAssessmentData
   const userRole = state.user;
   const payload= {
    "historyCause":
    {
    "cause":createAssessmentData?.cause?createAssessmentData?.cause:null,
    "causeCategory": createAssessmentData?.causeCategory?createAssessmentData?.causeCategory:null,
    "woundAge": createAssessmentData?.woundAge?createAssessmentData?.woundAge:null,
    "conditionPOA": createAssessmentData?.conditionPOA?createAssessmentData?.conditionPOA:null,
    "photoType":  createAssessmentData?.photoType?createAssessmentData?.photoType:null,
    "documentProvidedBy": createAssessmentData?.documentProvidedBy?createAssessmentData?.documentProvidedBy:null,
    "piHistory":  createAssessmentData?.piHistory?createAssessmentData?.piHistory:null,
    //"newWound": createAssessmentData?.newWound===1?1:0
    "newWound": userRole?.role === 'ExternalStandardUser'?0:1
  },
  "notes": createAssessmentData?.notes?createAssessmentData?.notes:null,
  "anatomy": createAssessmentData?.anatomy,
  "isFrontView": createAssessmentData?.isFrontView,
    "uuId": null,
    "picture": null,
    "isWoundWithoutPhoto": 1
  }
    // const payload={
    //   "cause":createAssessmentData?.cause,
    //   "causeCategory": createAssessmentData?.causeCategory,
    //   "notes": createAssessmentData?.notes,
    //   "anatomy": createAssessmentData?.anatomy,
    //   "isFrontView": createAssessmentData?.isFrontView,
    //   "uuId":null,
    //   "picture":null,
    //   "isWoundWithoutPhoto":1,
    //   // "signedBy": createAssessmentData?.signedBy,
    //   // "lockedBy": createAssessmentData?.lockedBy 
    // } 
    const resp = await request(PATIENT + "/" + patientId + "/assessmentsv1", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (resp && resp.status === SUCCESS_STATUS) {
     let resayload = {
        id: resp.data.id,
      };
     /* Set the returned data id in a state */
     dispatch(setDetailAssessment({}));
     dispatch(setCreateAssessmentData(resayload));
     if(userRole?.role === 'ExternalStandardUser'){
      dispatch(setLoading(true));
      await dispatch(patientAssessmentAsync(patientId));
      await dispatch(getPatientSubmittedWoundPhotoAsync(patientId));
      await dispatch(setEditState(resp.status));
      window.location = "/patients/" + patientId;
     }
     else if(history){
      history.push("/patients/" + patientId + "/assessment/" + resp.data.id);
     }else{
      window.location = "/patients/" + patientId + "/assessment/" + resp.data.id;
     }
   
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
export const createAssessMentWithoutPhotoNewFlow = (patientId,
  history,setAnatomyPopUPList
) => async (dispatch,getState) => {
  try {
   dispatch(setLoading(true));
    const state = getState();
   const createAssessmentData= state.patient.createAssessmentData
   const userRole = state.user;
   const payload= {
    "patientId": patientId,
    "anatomy": createAssessmentData?.anatomy,
    "uuid":null,
    "isWoundWithoutPhoto": 1, // 0 if UUID is there, 1 if not there
    "isFrontView":createAssessmentData?.isFrontView,
  }
    const resp = await request("/patient/assessment/ne1createnewwound", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (resp && resp.status === SUCCESS_STATUS) {
     let resayload = {
        id: resp.data.id,
        assessmentId:resp.data.id,
        ...payload
      };
     /* Set the returned data id in a state */
     dispatch(setCreateAssessmentData(resayload));
     await dispatch(patientAssessmentAsync(patientId));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};
export const detailSnakeAlgorithmAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setIsSnakeAlgorithms(''));
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessmentsv1/" +  // Updated from assessments
        payload.assessmentId,
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setIsSnakeAlgorithms(resp.data?.measurement?.isSnakeAlgorithmsuccessful));
      if(resp.data?.measurement?.isSnakeAlgorithmsuccessful!==2){
        dispatch(setDetailAssessment(resp.data));
      }

    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
    } else {
      dispatch(setEditState("error"));
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};
//get istreatmentcompleted 
export const validatecharacteristicsAsync = (payload,e,modalOpen,handleSign) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(
      PATIENT +
        "/" +
        payload.patientId +
        "/assessments/" + 
        payload.assessmentId+"/validatecharacteristics",
      {
        method: "GET",
      }
    );
    if (resp && resp.status === SUCCESS_STATUS) {
      if(resp.data?.isSignPopupEnabled===1){
        modalOpen(e,'minDataSignComplete')
      }else if(resp.data?.isSignPopupEnabled===0){
        handleSign(e)
      }
     
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
    } else {
      dispatch(setEditState("error"));
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPdfDataAsyn = (payload) => async (dispatch) => {
  try {
    dispatch(setPdfFile(''));
    dispatch(setIsPdfGeneration(true));
    const resp = await request('/patients/getpdfbyassessment',
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    if (resp && resp.status === SUCCESS_STATUS&&resp?.data) {
      dispatch(setPdfFile(resp?.data));
      dispatch(setIsPdfGeneration(false));

    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setIsPdfGeneration(true));
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage));
    } else {
      dispatch(setEditState("error"));
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setIsPdfGeneration(false));
  }
};

export const getTreatMentBiList = (payload) => async (dispatch) => {
  dispatch(setTreatMentBiListOption({}));
  try {
    dispatch(setLoading(true));
    const facilities = { facilities: payload };
    const resp = await request(
      "/reports/listoptions/treatment",
      {
        method: "POST",
        body: JSON.stringify(facilities),
      }
    );

    if (resp && resp.status === SUCCESS_STATUS) {
      let val = { ...resp.data };
      let cliniArr = [];
      val?.clinician?.map((item) => {//NOSONAR
        let test = { ...item };
        test["key"] = item.key;
        test["value"] = `${item.value} - ${item.key}`;
        cliniArr = [...cliniArr, test];
      })//NOSONAR
      val["clinician"] = cliniArr;
      dispatch(setTreatMentBiListOption(val));
    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};


// new api's for new assessment work flow changes


export const ne1updateassessmentanatomyAsync = (payload) => async (dispatch) => {

  try {
    //   dispatch(setAccessError('Test'))
    dispatch(setLoading(true));

    let anatomyPayload={
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
      "anatomy":  payload?.assessment?.anatomy,
      "isFrontView":  payload?.assessment?.isFrontView
  }
    const data = await request(
        "/native/patient/assessment/ne1updateassessmentanatomy",
      {
        method: "PUT",
        body: JSON.stringify(anatomyPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getanatomyAsync(payload));
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      //getanatomyAsync
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};


//detaile getanatomy get call by AssessID
export const getanatomyAsync = (payload, history) => async (dispatch) => {
  dispatch(setAnatomyDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const anatomyPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/getanatomy", {
      method: "POST",
      body: JSON.stringify(anatomyPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setAnatomyDataByAssessID(resp?.data?.woundAssessmentAnatomyDetails));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// new api's for new assessment work flow changes


export const ne1updateassessmentHistoryCauseAsync = (payload,redirect) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
    let causePayload={
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
        "cause": payload?.assessment?.historyCause?.cause,
         "causeCategory": payload?.assessment?.historyCause?.causeCategory,
        // "notes": payload?.notes?payload?.notes:''
  }
  let historyPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "woundAge":payload?.assessment?.historyCause?.woundAge,
    "conditionPOA":payload?.assessment?.historyCause?.conditionPOA==='Yes'||payload?.assessment?.historyCause?.conditionPOA===1?1:0,
    "photoType": payload?.assessment?.historyCause?.photoType,
    "documentProvidedBy": payload?.assessment?.historyCause?.documentProvidedBy,
    "piHistory": payload?.assessment?.historyCause?.piHistory,
    "newWound": payload?.assessment?.historyCause?.newWound

}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmentcause",
      {
        method: "PUT",
        body: JSON.stringify(causePayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      if(payload?.notes){
        await dispatch(notesPostAsync(payload));
      }
      dispatch(getcauseAsync(payload));
    //  dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
    const dataHist = await request(
      "/native/patient/assessment/ne1updateassessmenthistory",
    {
      method: "PUT",
      body: JSON.stringify(historyPayload),
    }
  );
  if (dataHist && dataHist.status === SUCCESS_STATUS) {
    dispatch(gethistoryAsync(payload));
    dispatch(detailAssessmentAsync(payload));
   // dispatch(ne1getWounAssessmentDetailAsync(payload));
    dispatch(setCreateAssessmentData("reset"))

    if(redirect===1){
      dispatch(setDetailAssessment({}));
     window.location = "/patients/" + payload?.patientId + "/assessment/" + payload?.assessmentId;
    }
  }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(ne1getWounAssessmentDetailAsync(payload));
    dispatch(setLoading(false));
  }
};

//detaile getcause get call by AssessID
export const getcauseAsync = (payload, history) => async (dispatch) => {
  dispatch(setCauseDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/getcause", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setCauseDataByAssessID(resp?.data?.woundAssessmentCause));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};
//detaile gethistory get call by AssessID
export const gethistoryAsync = (payload, history) => async (dispatch) => {
  dispatch(setHistoryDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/gethistory", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setHistoryDataByAssessID(resp?.data?.woundAssessmentHistory));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};


//detaile gettissueTypes get call by AssessID
export const gettissuetypeAsync = (payload, history) => async (dispatch) => {
  dispatch(setTissueTypeDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/gettissuetype", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setTissueTypeDataByAssessID(resp?.data?.woundAssessmentTissuesTypes));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1updateassessmenttissuetype get call by AssessID
export const ne1updateassessmenttissuetypeAsync = (payload) => async (dispatch) => {

  try {
    dispatch(setLoading(true));

  let tissuePayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "isOverRideEnable": payload?.overrideenable===true?1:0, // 0 if Override option is disable , 1 if Override option is enable 
    "tissueTypes": payload?.assessment?.tissueTypes,
    "other": payload?.assessment?.other,
  //"other": "free text"
}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmenttissuetype",
      {
        method: "PUT",
        body: JSON.stringify(tissuePayload),
      }
    );

    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCharacteristicTabs([]));
       dispatch(setClassificationDataByAssessID({}));
      dispatch(setCharStatus([]))
      dispatch(gettissuetypeAsync(payload));
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile gettouchview get call by AssessID
export const gettouchviewAsync = (payload, history) => async (dispatch) => {
  dispatch(setTouchViewDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/gettouchview", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setTouchViewDataByAssessID(resp?.data?.woundAssessmentTouchViewDetails));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1updateassessmenttouchviewAsync get call by AssessID
export const ne1updateassessmenttouchviewAsync = (payload) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
   // payload?.assessment?.tissueTypes,
  let touchPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "touchView": payload?.assessment?.touchView
}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmenttouchview",
      {
        method: "PUT",
        body: JSON.stringify(touchPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(gettouchviewAsync(payload));
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};
//setWoundClosureOptions
export const getwoundClosureOptions = (payload, history) => async (
  dispatch
) => {
  const url = `/listoptions/woundclosure`;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url), {
      method: "GET",
    });
    if (data && data.status === "success") {
      dispatch(setWoundClosureOptions(data?.data?.options));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};


//detaile getclassification get call by AssessID
export const getclassificationAsync = (payload, history) => async (dispatch) => {
  dispatch(setClassificationDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/getclassification", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setClassificationDataByAssessID(resp?.data?.woundAssessmentClassificationsDetails));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1updateassessmentclassificationAsync get call by AssessID
export const ne1updateassessmentclassificationAsync = (payload) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
   // payload?.assessment?.tissueTypes,
  let touchPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "isOverRideEnable": payload?.overrideenable,
    "classification": payload?.assessment?.classification
}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmentclassification",
      {
        method: "PUT",
        body: JSON.stringify(touchPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getclassificationAsync(payload));
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile getwoundprogress get call by AssessID
export const getwoundprogressAsync = (payload, history) => async (dispatch) => {
  dispatch(setWoundprogressDataByAssessID({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/getwoundprogress", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setWoundprogressDataByAssessID(resp?.data?.woundAssessmentWoundProgressDetails));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1updateassessmentprogressAsync get call by AssessID
export const ne1updateassessmentprogressAsync = (payload) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
   // payload?.assessment?.tissueTypes,
  let touchPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "WoundStage": payload?.assessment?.woundStage,
    "isMeasurementResolved":payload?.isMeasurementResolved
}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmentprogress",
      {
        method: "PUT",
        body: JSON.stringify(touchPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getwoundprogressAsync(payload));
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};



//detaile ne1updateassessmentmeasurement put call by AssessID
export const ne1updateassessmentmeasurementAsync = (patientId,payload) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
   // payload?.assessment?.tissueTypes,
  let touchPayload={
    "patientId": patientId,
    "assessmentid":  payload.id,
    "measurement": payload?.measurement,
}
    const data = await request(
        "/native/patient/assessment/ne1updateassessmentmeasurement",
      {
        method: "PUT",
        body: JSON.stringify(touchPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(detailAssessmentAsync({"patientId": patientId,
    "assessmentId":  payload.id}));
    //dispatch(ne1getWounAssessmentDetailAsync(payload));
    //dispatch(ne1configsignwithoutmindatasetAsync(payload));
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//signed locked
export const ne1updateassessmentsignlockAsync = (payload, parentLockUnlock) => async (dispatch) => { 
  try {
    dispatch(setLoading(true));
   // payload?.assessment?.tissueTypes,
  let parentLevelAction = parentLockUnlock ? `/native/patient/assessment/ne1updateassessmentsignlock?type=allassessments`: `/native/patient/assessment/ne1updateassessmentsignlock`;
  let finalPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "IsLocked": payload?.assessment?.locked,
    "IsSigned": payload?.assessment?.signed
}
    const data = await request(parentLevelAction,
      {
        method: "PUT",
        body: JSON.stringify(finalPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(detailAssessmentAsync(payload));
      dispatch(ne1getWounAssessmentDetailAsync(payload));
      dispatch(ne1configsignwithoutmindatasetAsync(payload));
     // window.location = "/patients/" + payload?.patientId + "/assessment/" + payload?.assessmentId;
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
      if (payload.reset) {
        payload.reset({});
        setHistoryCauseFormData({});
      }
      if (payload.setSigned) {
        payload.setSigned(true);
      }
      if (payload.setLock) {
        payload.setLock(true);
      }
      if (payload.getCharDetails) {
        payload.getCharDetails();
      }
      if (payload.navigate) {
       
        payload.navigate(); 
      }
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

// Create a New Parent Record from Child making its Own
export const createNewParentFromChild = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/native/patient/assessment/child-as-own-parent`,
      {
        method: "PUT",
        body: JSON.stringify(payload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setChildAsItsOwnParent(true))      
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1getWounAssessmentDetail get call by AssessID
export const ne1getWounAssessmentDetailAsync = (payload, history) => async (dispatch) => {
  let patientId= payload.patientId 
  let assessmentId= payload.assessmentId 
  await dispatch(ne1getwoundassessmentcharacteristicsAsync({patientId,assessmentId}))
  dispatch(setNe1getWounAssessmentDetail({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/ne1getwoundassessmentdetail", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setNe1getWounAssessmentDetail(resp?.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//detaile ne1configsignwithoutmindataset get call by AssessID
export const ne1configsignwithoutmindatasetAsync = (payload, history) => async (dispatch) => {
  dispatch(setNe1configsignwithoutmindataset({}));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
    };
    const resp = await request("/native/patient/assessment/ne1configsignwithoutmindataset", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setNe1configsignwithoutmindataset(resp?.data));
   
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      if(history){
        history.push("/unAuthorized");
      }
     
    }
  } finally {
    dispatch(setLoading(false));
  }
};


//updatereeval
export const updatereevalAsync = (payload) => async (dispatch) => {

  try {
    dispatch(setTouchViewDataByAssessID({}));
    dispatch(setLoading(true));
  let finalPayload={
    "patientId": payload?.patientId,
    "assessmentid": payload?.assessmentId,
    "parentAssessmentid": payload?.parentAssessmentid,
    "channel": "ios",
  }
    const data = await request(
        "/native/patient/assessment/updatereeval",
      {
        method: "PUT",
        body: JSON.stringify(finalPayload),
      }
    );
    if (data && data.status === SUCCESS_STATUS) {
      // dispatch(detailAssessmentAsync(payload));
      // dispatch(ne1getWounAssessmentDetailAsync(payload));
      // dispatch(ne1configsignwithoutmindatasetAsync(payload));
      const assessmentId= data?.data?.id
      dispatch(setDetailAssessment({}));
    window.location = "/patients/" + payload?.patientId + "/assessment/" +assessmentId;
      /* The below if condition is for history/cause,
         we are locally storing form data in history/cause, we are
         clearing them here...
       */
    }
  } catch (error) {
    const obj = { ...error };
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj?.errorMessage));
    } else {
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

//characteristicTabs get call by AssessID
export const ne1getwoundassessmentcharacteristicsAsync = (payload, history) => async (dispatch) => {
  //dispatch(setCharTabsLoad(false));
 
  dispatch(setCharacteristicTabs([]));
  try {
    dispatch(setLoading(true));
    const finalPayload = {
      "patientId": payload?.patientId,
      "assessmentid": payload?.assessmentId,
       "feature": "Characteristics",
       "subfeature":"NE1WMS"
    };
    const resp = await request("/native/patient/assessment/ne1getwoundassessmentcharacteristics", {
      method: "POST",
      body: JSON.stringify(finalPayload),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
     // dispatch(setCharTabsLoad(true));
      dispatch(setCharacteristicTabs(resp?.data));
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
    if (
      obj?.response?.status === 405 ||
      obj?.response?.status === 404 
    ) {
      history.push("/unAuthorized");
    }
  } finally {
    dispatch(setLoading(false));
   // dispatch(setCharTabsLoad(false)); 
  }
};

//Merge Wound Success API
export const mergeWoundSuccessReeval = (payload, history, adminWoundAssement) => async (dispatch) => {
  dispatch(setMergeWoundSuccess(null)); 
  let adminWoundAssementPage = adminWoundAssement === true ? '/native/patient/assessment/updatemergingreevalrecord?type=allassessments' : '/native/patient/assessment/updatemergingreevalrecord';
  try {
    dispatch(setLoading(true));    
    const resp = await request(adminWoundAssementPage, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    if (resp && resp.status === SUCCESS_STATUS) {   
      dispatch(setMergeWoundSuccess(resp.message));
      history.push(`/admin/patients/${payload.patientId}`);  
      dispatch(setMergeNavigateSuccess(true));    
    }
  } 
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
};

export default patientSlice.reducer;
